import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveLegalBasisActions = {
  SAVE_LEGAL_BASIS: "SAVE_LEGAL_BASIS",
  SAVE_LEGAL_BASIS_STATUS: "SAVE_LEGAL_BASIS_STATUS",
  SAVE_LEGAL_BASIS_SUCCESS: "SAVE_LEGAL_BASIS_SUCCESS",
  SAVE_LEGAL_BASIS_FAILURE: "SAVE_LEGAL_BASIS_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveLegalBasisActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveLegalBasisRequest() {
  return {
    type: SaveLegalBasisActions.SAVE_LEGAL_BASIS,
    isPosting: true,
  };
}

export function SaveLegalBasisSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveLegalBasisActions.SAVE_LEGAL_BASIS_SUCCESS,
      payload: props,
    });
  };
}

export function SaveLegalBasisFailure(error) {
  return {
    type: SaveLegalBasisActions.SAVE_LEGAL_BASIS_FAILURE,
    payload: error,
  };
}

export function SaveLegalBasisError(error) {
  return {
    type: SaveLegalBasisActions.SAVE_LEGAL_BASIS_FAILURE,
    payload: error,
  };
}

export function saveLegalBasis(props) {
  
  var value = {
    id: props.legalBasisId || null,
    name: props.legalBasisName,
    description: props.description,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_23/saveLegalBasis`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveLegalBasisRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveLegalBasisSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          // dispatch(SaveLegalBasisError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          // case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveLegalBasisError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveLegalBasisError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetLegalBasisDetailsActions = {
  FETCH_LEGAL_BASIS_DETAILS: "FETCH_LEGAL_BASIS_DETAILS",
  FETCH_LEGAL_BASIS_DETAILS_STATUS: "FETCH_LEGAL_BASIS_DETAILS_STATUS",
  FETCH_LEGAL_BASIS_DETAILS_FULFILLED: "FETCH_LEGAL_BASIS_DETAILS_FULFILLED",
  FETCH_LEGAL_BASIS_DETAILS_REJECTED: "FETCH_LEGAL_BASIS_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getLegalBasisDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_23/getLegalBasisById?id=` +
        params.legalBasisId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getLegalBasisDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getLegalBasisDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          // dispatch(getLegalBasisDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getLegalBasisDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getLegalBasisDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getLegalBasisDetailsRequest() {
  return {
    type: GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS,
    isPosting: true,
  };
}
export function getLegalBasisDetailsError(error) {
  return {
    type: GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_REJECTED,
    payload: error,
  };
}

export function getLegalBasisDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getLegalBasisDetailsStatus() {
  return (dispatch) => dispatch(getLegalBasisDetailsStatusRequest());
}
export function getLegalBasisDetailsStatusRequest() {
  return {
    type: GetLegalBasisDetailsActions.FETCH_LEGAL_BASIS_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getLegalBasisDetailsErrorRequest());
}
export function getLegalBasisDetailsErrorRequest() {
  return {
    type: GetLegalBasisDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveLegalBasisActions = {
  FETCH_ACTIVE_LEGAL_BASIS: "FETCH_ACTIVE_LEGAL_BASIS",
  FETCH_ACTIVE_LEGAL_BASIS_STATUS: "FETCH_ACTIVE_LEGAL_BASIS_STATUS",
  FETCH_ACTIVE_LEGAL_BASIS_FULFILLED: "FETCH_ACTIVE_LEGAL_BASIS_FULFILLED",
  FETCH_ACTIVE_LEGAL_BASIS_REJECTED: "FETCH_ACTIVE_LEGAL_BASIS_REJECTED",
};
export function getActiveLegalBasisList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_23/getAllLegalBasisPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveLegalBasisListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveLegalBasisListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveLegalBasisListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveLegalBasisListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveLegalBasisListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveLegalBasisListError(err));
              break;
            case 500:
              dispatch(getActiveLegalBasisListError("Server Error"));
              break;
            default:
              dispatch(getActiveLegalBasisListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveLegalBasisListRequest() {
  return {
    type: GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS,
    isPosting: true,
  };
}
export function getActiveLegalBasisListError(error) {
  return {
    type: GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_REJECTED,
    payload: error,
  };
}

export function getActiveLegalBasisListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveLegalBasisListStatus() {
  return (dispatch) => dispatch(getActiveLegalBasisListStatusRequest());
}
export function getActiveLegalBasisListStatusRequest() {
  return {
    type: GetActiveLegalBasisActions.FETCH_ACTIVE_LEGAL_BASIS_STATUS,
  };
}
