import axios from "axios";
import { IncidentManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveFormBuilderActions = {
  SAVE_FORMBUILDER: "SAVE_FORMBUILDER",
  SAVE_FORMBUILDER_STATUS: "SAVE_FORMBUILDER_STATUS",
  SAVE_FORMBUILDER_SUCCESS: "SAVE_FORMBUILDER_SUCCESS",
  SAVE_FORMBUILDER_FAILURE: "SAVE_FORMBUILDER_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveFormBuilderActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveFormBuilderRequest() {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER,
    isPosting: true,
  };
}

export function SaveFormBuilderSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveFormBuilderActions.SAVE_FORMBUILDER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveFormBuilderFailure(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function SaveFormBuilderError(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function saveFormPages(props, access_token) {
  
  

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_36/saveBreachPages`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveFormBuilderRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveFormBuilderSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveFormBuilderError(error));
          
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

////// COPY DETAILS/////////////
export const CopyFormDetailsActions = {
  COPY_FORM_DETAILS: "COPY_FORM_DETAILS",
};

export function copyBreachDetails(params) {
  
  return (dispatch) => dispatch(copyFormDetails(params.isDraft));
}

export function copyFormDetails(isDraft) {
  return {
    type: CopyFormDetailsActions.COPY_FORM_DETAILS,
    payload: isDraft,
  };
}

////////COPY DETAILS END////////////

////// TEMPLATE  DETAILS/////////////
export const TemplateDetailsActions = {
  TEMPLATE_FORM_DETAILS: "TEMPLATE_FORM_DETAILS",
};

export function TemplateSelected() {
  return (dispatch) => dispatch(templateFormDetails());
}

export function templateFormDetails() {
  return {
    type: TemplateDetailsActions.TEMPLATE_FORM_DETAILS,
  };
}

////////TEMPLATE DETAILS END////////////

/////////////SAVE DATA IN PROPS/////////////

export const SaveFormDetailsProps = {
  SAVE_FORM_DETAILS: "SAVE_FORM_DETAILS",
};

export function SaveFormProps(Data) {
  return (dispatch) => dispatch(SaveFormDetails(Data));
}

export function SaveFormDetails(Data) {
  return (dispatch) => {
    dispatch({
      type: SaveFormDetailsProps.SAVE_FORM_DETAILS,
      payload: Data,
    });
  };
}

////////////SAVE DATA IN PROPS END//////////////

// get  details by id

export const GetBreachIncidentDetailsActions = {
  FETCH_BREACH_INCIDENT_DETAILS: "FETCH_BREACH_INCIDENT_DETAILS",
  FETCH_BREACH_INCIDENT_DETAILS_STATUS: "FETCH_BREACH_INCIDENT_DETAILS_STATUS",
  FETCH_BREACH_INCIDENT_DETAILS_FULFILLED:
    "FETCH_BREACH_INCIDENT_DETAILS_FULFILLED",
  FETCH_BREACH_INCIDENT_DETAILS_REJECTED:
    "FETCH_BREACH_INCIDENT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachIncidentDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",

      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/getBreachIncidentByBreachId/` +
        params.breachId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachIncidentDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachIncidentDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachIncidentDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachIncidentDetailsRequest() {
  return {
    type: GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS,
    isPosting: true,
  };
}
export function getBreachIncidentDetailsError(error) {
  return {
    type:
      GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachIncidentDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachIncidentDetailsStatus() {
  return (dispatch) => dispatch(getBreachIncidentDetailsStatusRequest());
}
export function getBreachIncidentDetailsStatusRequest() {
  return {
    type: GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_STATUS,
  };
}

export function setBreachDetailsError() {
  return (dispatch) => dispatch(getBreachIncidentDetailsErrorRequest());
}
export function getBreachIncidentDetailsErrorRequest() {
  return {
    type: GetBreachIncidentDetailsActions.SET_EDIT_ERROR,
  };
}

///////GET LOCATION BY ID

export const GetBreachDetailsActions = {
  FETCH_FORMBUILDER_DETAILS: "FETCH_FORMBUILDER_DETAILS",
  FETCH_FORMBUILDER_DETAILS_STATUS: "FETCH_FORMBUILDER_DETAILS_STATUS",
  FETCH_FORMBUILDER_DETAILS_FULFILLED: "FETCH_FORMBUILDER_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_DETAILS_REJECTED: "FETCH_FORMBUILDER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/getBreach/` +
        params.breachId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachDetailsRequest() {
  return {
    type: GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS,
    isPosting: true,
  };
}
export function getBreachDetailsError(error) {
  return {
    type: GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachDetailsStatus() {
  return (dispatch) => dispatch(getBreachDetailsStatusRequest());
}
export function getBreachDetailsStatusRequest() {
  return {
    type: GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getBreachDetailsErrorRequest());
}
export function getBreachDetailsErrorRequest() {
  return {
    type: GetBreachDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${IncidentManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveFormBuilderActions = {
  FETCH_ACTIVE_FORMBUILDER: "FETCH_ACTIVE_FORMBUILDER",
  FETCH_ACTIVE_FORMBUILDER_STATUS: "FETCH_ACTIVE_FORMBUILDER_STATUS",
  FETCH_ACTIVE_FORMBUILDER_FULFILLED: "FETCH_ACTIVE_FORMBUILDER_FULFILLED",
  FETCH_ACTIVE_FORMBUILDER_REJECTED: "FETCH_ACTIVE_FORMBUILDER_REJECTED",
};
export function getActiveFormBuilderList(params) {
  return (dispatch) =>
    axios({
      method: "post",
     
      url: `${IncidentManagementService_URL}/getAllFormBuilderPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveFormBuilderListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveFormBuilderListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveFormBuilderListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveFormBuilderListRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER,
    isPosting: true,
  };
}
export function getActiveFormBuilderListError(error) {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_REJECTED,
    payload: error,
  };
}

export function getActiveFormBuilderListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveFormBuilderListStatus() {
  return (dispatch) => dispatch(getActiveFormBuilderListStatusRequest());
}
export function getActiveFormBuilderListStatusRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_STATUS,
  };
}

//Save Breach///
export const SaveBreachActions = {
  SAVE_SURVEY: "SAVE_SURVEY",
  SAVE_SURVEY_STATUS: "SAVE_SURVEY_STATUS",
  SAVE_SURVEY_SUCCESS: "SAVE_SURVEY_SUCCESS",
  SAVE_SURVEY_FAILURE: "SAVE_SURVEY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveBreachRequest() {
  return {
    type: SaveBreachActions.SAVE_SURVEY,
    isPosting: true,
  };
}

export function SaveBreachSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveBreachActions.SAVE_SURVEY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveBreachFailure(error) {
  return {
    type: SaveBreachActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function SaveBreachError(error) {
  return {
    type: SaveBreachActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function saveBreach(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_36/saveBreach`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveBreachRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveBreachSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveBreachError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save Breach IncidentManagementService_URL

export const SaveBreachIncidentActions = {
  SAVE_BREACH_INCIDENT: "SAVE_BREACH_INCIDENT",
  SAVE_BREACH_INCIDENT_STATUS: "SAVE_BREACH_INCIDENT_STATUS",
  SAVE_BREACH_INCIDENT_SUCCESS: "SAVE_BREACH_INCIDENT_SUCCESS",
  SAVE_BREACH_INCIDENT_FAILURE: "SAVE_BREACH_INCIDENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveBreachIncidentRequest() {
  return {
    type: SaveBreachIncidentActions.SAVE_BREACH_INCIDENT,
    isPosting: true,
  };
}

export function SaveBreachIncidentSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveBreachIncidentFailure(error) {
  return {
    type: SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_FAILURE,
    payload: error,
  };
}

export function SaveBreachIncidentError(error) {
  return {
    type: SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_FAILURE,
    payload: error,
  };
}

export function saveBreachIncident(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_36/saveBreachIncident`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveBreachIncidentRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveBreachIncidentSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveBreachIncidentError(error));
          
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const SavePageElementActions = {
  SAVE_PAGE_ELEMENT: "SAVE_PAGE_ELEMENT",
  SAVE_PAGE_ELEMENT_STATUS: "SAVE_PAGE_ELEMENT_STATUS",
  SAVE_PAGE_ELEMENT_SUCCESS: "SAVE_PAGE_ELEMENT_SUCCESS",
  SAVE_PAGE_ELEMENT_FAILURE: "SAVE_PAGE_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SavePageElementRequest() {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT,
    isPosting: true,
  };
}

export function SavePageElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SavePageElementActions.SAVE_PAGE_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SavePageElementFailure(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SavePageElementError(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function savePageElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/saveBreachElements`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SavePageElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SavePageElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SavePageElementError(error));
          
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save AssessmentElement///
export const SaveAssessmentElementActions = {
  SAVE_ASSESSMENT_ELEMENT: "SAVE_ASSESSMENT_ELEMENT",
  SAVE_ASSESSMENT_ELEMENT_STATUS: "SAVE_ASSESSMENT_ELEMENT_STATUS",
  SAVE_ASSESSMENT_ELEMENT_SUCCESS: "SAVE_ASSESSMENT_ELEMENT_SUCCESS",
  SAVE_ASSESSMENT_ELEMENT_FAILURE: "SAVE_ASSESSMENT_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveAssessmentElementRequest() {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT,
    isPosting: true,
  };
}

export function SaveAssessmentElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveAssessmentElementFailure(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SaveAssessmentElementError(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function saveAssessmentElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_36/saveBreachAssessment`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveAssessmentElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveAssessmentElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveAssessmentElementError(error));
          
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const DeleteBreachPageActions = {
  DEL_SURVEY_PAGE: "DEL_SURVEY_PAGE",
  DEL_SURVEY_PAGE_STATUS: "DEL_SURVEY_PAGE_STATUS",
  DEL_SURVEY_PAGE_SUCCESS: "DEL_SURVEY_PAGE_SUCCESS",
  DEL_SURVEY_PAGE_FAILURE: "DEL_SURVEY_PAGE_FAILURE",
  LOGOUT: "LOGOUT",
};

export function DeleteBreachPageRequest() {
  return {
    type: DeleteBreachPageActions.DEL_SURVEY_PAGE,
    isPosting: true,
  };
}

export function DeleteBreachPageSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteBreachPageActions.DEL_SURVEY_PAGE_SUCCESS,
      payload: props,
    });
  };
}

export function DeleteBreachPageFailure(error) {
  return {
    type: DeleteBreachPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function DeleteBreachPageError(error) {
  return {
    type: DeleteBreachPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function delBreachPage(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url:
        `${IncidentManagementService_URL}/Apc_State/Amc_36/deleteBreachPages/` +
        props,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(DeleteBreachPageRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(DeleteBreachPageSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(DeleteBreachPageError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveTemplateActions = {
  FETCH_ACTIVE_TEMPLATE: "FETCH_ACTIVE_TEMPLATE",
  FETCH_ACTIVE_TEMPLATE_STATUS: "FETCH_ACTIVE_TEMPLATE_STATUS",
  FETCH_ACTIVE_TEMPLATE_FULFILLED: "FETCH_ACTIVE_TEMPLATE_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_REJECTED: "FETCH_ACTIVE_TEMPLATE_REJECTED",
};
export function getActiveTemplateList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/findAllBreachPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=3&breachStatus=` +
        params.breachStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveTemplateListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTemplateListRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE,
    isPosting: true,
  };
}
export function getActiveTemplateListError(error) {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getActiveTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveTemplateListStatus() {
  return (dispatch) => dispatch(getActiveTemplateListStatusRequest());
}
export function getActiveTemplateListStatusRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS,
  };
}

////Get review//
export const GetBreachReviewActions = {
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachReview(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/getBreachReview/` +
        params.breachId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachReviewRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachReviewSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachReviewError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachReviewRequest() {
  return {
    type: GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS,
    isPosting: true,
  };
}
export function getBreachReviewError(error) {
  return {
    type:
      GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachReviewSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachReviewStatus() {
  return (dispatch) => dispatch(getBreachReviewStatusRequest());
}
export function getBreachReviewStatusRequest() {
  return {
    type: GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS,
  };
}

export function setReviewError() {
  return (dispatch) => dispatch(getBreachReviewErrorRequest());
}
export function getBreachReviewErrorRequest() {
  return {
    type: GetBreachReviewActions.SET_EDIT_ERROR,
  };
}

///Get breach by user//
export const GetBreachByUserActions = {
  FETCH_SURVEY_BY_USER_DETAILS: "FETCH_SURVEY_BY_USER_DETAILS",
  FETCH_SURVEY_BY_USER_DETAILS_STATUS: "FETCH_SURVEY_BY_USER_DETAILS_STATUS",
  FETCH_SURVEY_BY_USER_DETAILS_FULFILLED:
    "FETCH_SURVEY_BY_USER_DETAILS_FULFILLED",
  FETCH_SURVEY_BY_USER_DETAILS_REJECTED:
    "FETCH_SURVEY_BY_USER_DETAILS_REJECTED",
 
};

export function getBreachByUser(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/getBreachByUserAndBreachId/` +
        params.userId +
        `/` +
        params.breachId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getBreachByUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachByUserSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachByUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachByUserRequest() {
  return {
    type: GetBreachByUserActions.FETCH_SURVEY_BY_USER_DETAILS,
    isPosting: true,
  };
}
export function getBreachByUserError(error) {
  return {
    type: GetBreachByUserActions.FETCH_SURVEY_BY_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachByUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetBreachByUserActions.FETCH_SURVEY_BY_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachByUserStatus() {
  return (dispatch) => dispatch(getBreachByUserStatusRequest());
}
export function getBreachByUserStatusRequest() {
  return {
    type: GetBreachByUserActions.FETCH_SURVEY_BY_USER_DETAILS_STATUS,
  };
}

export function setUserBreachError() {
  return (dispatch) => dispatch(getBreachByUserErrorRequest());
}
export function getBreachByUserErrorRequest() {
  return {
    type: GetBreachByUserActions.SET_EDIT_ERROR,
  };
}

//////////VIEW BREACH///////////////////////////////////

///////GET LOCATION BY ID

export const ViewBreachDetailsActions = {
  VIEW_FORMBUILDER_DETAILS: "VIEW_FORMBUILDER_DETAILS",
  VIEW_FORMBUILDER_DETAILS_STATUS: "VIEW_FORMBUILDER_DETAILS_STATUS",
  VIEW_FORMBUILDER_DETAILS_FULFILLED: "VIEW_FORMBUILDER_DETAILS_FULFILLED",
  VIEW_FORMBUILDER_DETAILS_REJECTED: "VIEW_FORMBUILDER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function viewBreachDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/viewBreachIncidentByBreachId/` +
        params.breachId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(viewBreachDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(viewBreachDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(viewBreachDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function viewBreachDetailsRequest() {
  return {
    type: ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS,
    isPosting: true,
  };
}
export function viewBreachDetailsError(error) {
  return {
    type: ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_REJECTED,
    payload: error,
  };
}

export function viewBreachDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function viewBreachDetailsStatus() {
  return (dispatch) => dispatch(viewBreachDetailsStatusRequest());
}
export function viewBreachDetailsStatusRequest() {
  return {
    type: ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_STATUS,
  };
}

export function viewDetailsError() {
  return (dispatch) => dispatch(viewBreachDetailsErrorRequest());
}
export function viewBreachDetailsErrorRequest() {
  return {
    type: ViewBreachDetailsActions.SET_EDIT_ERROR,
  };
}

//SAVE BREACH SETUP

export const SaveBreachSetupActions = {
  SAVE_SETUP: "SAVE_SETUP",
  SAVE_SETUP_STATUS: "SAVE_SETUP_STATUS",
  SAVE_SETUP_SUCCESS: "SAVE_SETUP_SUCCESS",
  SAVE_SETUP_FAILURE: "SAVE_SETUP_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveBreachSetupRequest() {
  return {
    type: SaveBreachSetupActions.SAVE_SETUP,
    isPosting: true,
  };
}

export function SaveBreachSetupSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveBreachSetupActions.SAVE_SETUP_SUCCESS,
      payload: props,
    });
  };
}

export function SaveBreachSetupFailure(error) {
  return {
    type: SaveBreachSetupActions.SAVE_SETUP_FAILURE,
    payload: error,
  };
}

export function SaveBreachSetupError(error) {
  return {
    type: SaveBreachSetupActions.SAVE_SETUP_FAILURE,
    payload: error,
  };
}

export function saveBreachSetup(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${IncidentManagementService_URL}/Apc_Persist/Amc_95/saveBreachConfigure`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveBreachSetupRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveBreachSetupSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveBreachSetupError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//GET BRAECH SETUP

export const GetBreachSetupDetailsActions = {
  FETCH_BREACH_SETUP_DETAILS: "FETCH_BREACH_SETUP_DETAILS",
  FETCH_BREACH_SETUP_DETAILS_STATUS: "FETCH_BREACH_SETUP_DETAILS_STATUS",
  FETCH_BREACH_SETUP_DETAILS_FULFILLED: "FETCH_BREACH_SETUP_DETAILS_FULFILLED",
  FETCH_BREACH_SETUP_DETAILS_REJECTED: "FETCH_BREACH_SETUP_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getBreachSetup(params) {
  return (dispatch) =>
    axios({
      method: "post",

      url: `${IncidentManagementService_URL}/Apc_View/Amc_95/getBreachConfigureById`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params,
      },
    })
      .then((response) => {
        dispatch(getBreachSetupDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getBreachSetupDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getBreachSetupDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getBreachSetupDetailsRequest() {
  return {
    type: GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS,
    isPosting: true,
  };
}
export function getBreachSetupDetailsError(error) {
  return {
    type: GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_REJECTED,
    payload: error,
  };
}

export function getBreachSetupDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getBreachSetupDetailsStatus() {
  return (dispatch) => dispatch(getBreachSetupDetailsStatusRequest());
}
export function getBreachSetupDetailsStatusRequest() {
  return {
    type: GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_STATUS,
  };
}

export function setBreachSetupDetailsError() {
  return (dispatch) => dispatch(getBreachSetupDetailsErrorRequest());
}
export function getBreachSetupDetailsErrorRequest() {
  return {
    type: GetBreachSetupDetailsActions.SET_EDIT_ERROR,
  };
}



//BulkUploadExcel
export function UploadBulkExcel(props) {
  debugger;
  let data = new FormData();
  data.append("breachIncident", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${IncidentManagementService_URL}/Apc_Persist/Amc_36/bulkUploadExcel/breach`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetAllBreachDTOActions = {
  FETCH_ALL_BREACH: "FETCH_ALL_BREACH",
  FETCH_ALL_BREACH_STATUS: "FETCH_ALL_BREACH_STATUS",
  FETCH_ALL_BREACH_FULFILLED: "FETCH_ALL_BREACH_FULFILLED",
  FETCH_ALL_BREACH_REJECTED: "FETCH_ALL_BREACH_REJECTED",
};
export function getAllBreachDTO(params) {
  return (dispatch) =>
    axios({
      method: "post",
      
      url:
        `${IncidentManagementService_URL}/Apc_View/Amc_36/findAllBreachIncidentPaginationByStatus?start=0&length=10&sortOrder=asc&sortField=name&status=true&search=&searchCol=`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAllBreachDTORequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllBreachDTOSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getAllBreachDTOError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAllBreachDTORequest() {
  return {
    type: GetAllBreachDTOActions.FETCH_ALL_BREACH,
    isPosting: true,
  };
}
export function getAllBreachDTOError(error) {
  return {
    type: GetAllBreachDTOActions.FETCH_ALL_BREACH_REJECTED,
    payload: error,
  };
}

export function getAllBreachDTOSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllBreachDTOActions.FETCH_ALL_BREACH_FULFILLED,
      payload: props,
    });
  };
}