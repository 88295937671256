import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveVulnerabilityActions = {
  SAVE_VULNERABILITY: "SAVE_VULNERABILITY",
  SAVE_VULNERABILITY_STATUS: "SAVE_VULNERABILITY_STATUS",
  SAVE_VULNERABILITY_SUCCESS: "SAVE_VULNERABILITY_SUCCESS",
  SAVE_VULNERABILITY_FAILURE: "SAVE_VULNERABILITY_FAILURE",
};

export function SaveVulnerabilityRequest() {
  return {
    type: SaveVulnerabilityActions.SAVE_VULNERABILITY,
    isPosting: true,
  };
}

export function SaveVulnerabilitySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveVulnerabilityActions.SAVE_VULNERABILITY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveVulnerabilityFailure(error) {
  return {
    type: SaveVulnerabilityActions.SAVE_VULNERABILITY_FAILURE,
    payload: error,
  };
}

export function SaveVulnerabilityError(error) {
  return {
    type: SaveVulnerabilityActions.SAVE_VULNERABILITY_FAILURE,
    payload: error,
  };
}

export function saveVulnerability(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveVulnerabilityMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveVulnerabilityRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveVulnerabilitySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveVulnerabilityError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveVulnerabilityError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveVulnerabilityError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveVulnerabilityError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveVulnerabilityError(err));
          //   break;
          // case 500:
          //   dispatch(SaveVulnerabilityError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveVulnerabilityError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetVulnerabilityDetailsActions = {
  FETCH_VULNERABILITY: "FETCH_VULNERABILITY_DETAILS",
  FETCH_VULNERABILITY_STATUS: "FETCH_VULNERABILITY_DETAILS_STATUS",
  FETCH_VULNERABILITY_FULFILLED: "FETCH_VULNERABILITY_DETAILS_FULFILLED",
  FETCH_VULNERABILITY_BY_RISK_ID_FULFILLED: "FETCH_VULNERABILITY_BY_RISK_ID_FULFILLED",
  FETCH_VULNERABILITY_REJECTED: "FETCH_VULNERABILITY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getVulnerabilityDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findVulnerabilityMasterById?vulnerabilityId=` +
        params.vulnerabilityId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVulnerabilityDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVulnerabilityDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getVulnerabilityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 500:
              dispatch(getVulnerabilityDetailsError("Server Error"));
              break;
            default:
              dispatch(getVulnerabilityDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getVulnerabilitiesByRiskId(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findVulnerabilityByRiskId?riskId=` +
        params.riskId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getVulnerabilityDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVulnerabilitiesByRiskIdSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getVulnerabilityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityDetailsError(err));
              break;
            case 500:
              dispatch(getVulnerabilityDetailsError("Server Error"));
              break;
            default:
              dispatch(getVulnerabilityDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getVulnerabilityDetailsRequest() {
  return {
    type: GetVulnerabilityDetailsActions.FETCH_VULNERABILITY,
    isPosting: true,
  };
}
export function getVulnerabilityDetailsError(error) {
  return {
    type: GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_REJECTED,
    payload: error,
  };
}

export function getVulnerabilityDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_FULFILLED,
      payload: props,
    });
  };
}

export function getVulnerabilitiesByRiskIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_BY_RISK_ID_FULFILLED,
      payload: props,
    });
  };
}

export function getVulnerabilityDetailsStatus() {
  return (dispatch) => dispatch(getVulnerabilityDetailsStatusRequest());
}
export function getVulnerabilityDetailsStatusRequest() {
  return {
    type: GetVulnerabilityDetailsActions.FETCH_VULNERABILITY_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getVulnerabilityDetailsErrorRequest());
}
export function getVulnerabilityDetailsErrorRequest() {
  return {
    type: GetVulnerabilityDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("vulnerability", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/Apc_Persist/Amc_9/bulkUploadExcel/Vulnerability`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveVulnerabilityActions = {
  FETCH_ACTIVE_VULNERABILITY: "FETCH_ACTIVE_VULNERABILITY",
  FETCH_ACTIVE_VULNERABILITY_STATUS: "FETCH_ACTIVE_VULNERABILITY_STATUS",
  FETCH_ACTIVE_VULNERABILITY_FULFILLED: "FETCH_ACTIVE_VULNERABILITY_FULFILLED",
  FETCH_ACTIVE_VULNERABILITY_REJECTED: "FETCH_ACTIVE_VULNERABILITY_REJECTED",
};
export function getActiveVulnerabilityList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/Vulnerability`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveVulnerabilityListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveVulnerabilityListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveVulnerabilityListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVulnerabilityListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVulnerabilityListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveVulnerabilityListError(err));
              break;
            case 500:
              dispatch(getActiveVulnerabilityListError("Server Error"));
              break;
            default:
              dispatch(getActiveVulnerabilityListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveVulnerabilityListRequest() {
  return {
    type: GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY,
    isPosting: true,
  };
}
export function getActiveVulnerabilityListError(error) {
  return {
    type: GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_REJECTED,
    payload: error,
  };
}

export function getActiveVulnerabilityListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveVulnerabilityListStatus() {
  return (dispatch) => dispatch(getActiveVulnerabilityListStatusRequest());
}
export function getActiveVulnerabilityListStatusRequest() {
  return {
    type: GetActiveVulnerabilityActions.FETCH_ACTIVE_VULNERABILITY_STATUS,
  };
}

// Get all Vulnerability Pagination

export const GetVulnerabilityActions = {
  FETCH_VULNERABILITY: "FETCH_VULNERABILITY",
  FETCH_VULNERABILITY_STATUS: "FETCH_VULNERABILITY_STATUS",
  FETCH_VULNERABILITY_FULFILLED:
    "FETCH_VULNERABILITY_FULFILLED",
  FETCH_VULNERABILITY_REJECTED:
    "FETCH_VULNERABILITY_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getVulnerability(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/getAllVulnerabilityPagination?length=0&sortOrder=desc&sortField=vulnerabilityId&status=true`, 
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getVulnerabilitySuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getVulnerabilityError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getVulnerabilityError(err));
              break;
            case 500:
              dispatch(getVulnerabilityError("Server Error"));
              break;
            default:
              dispatch(getVulnerabilityError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getVulnerabilityRequest() {
  return {
    type: GetVulnerabilityActions.FETCH_VULNERABILITY,
    isPosting: true,
  };
}
export function getVulnerabilityError(error) {
  return {
    type: GetVulnerabilityActions.FETCH_VULNERABILITY_REJECTED,
    payload: error,
  };
}

export function getVulnerabilitySuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetVulnerabilityActions.FETCH_VULNERABILITY_FULFILLED,
      payload: props,
    });
  };
}

export function getVulnerabilityStatus() {
  return (dispatch) => dispatch(getVulnerabilityStatusRequest());
}
export function getVulnerabilityStatusRequest() {
  return {
    type: GetVulnerabilityActions.FETCH_VULNERABILITY_STATUS,
  };
}