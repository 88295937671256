import { language } from "../../settings";

import englishLang from "../../image/flag/uk.svg";
import chineseLang from "../../image/flag/china.svg";
import spanishLang from "../../image/flag/spain.svg";
import frenchLang from "../../image/flag/france.svg";
import italianLang from "../../image/flag/italy.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "English",
      locale: "en",
      text: "English",
      icon: englishLang,
    },
    {
      languageId: "Chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang,
    },
    {
      languageId: "Spanish",
      locale: "es",
      text: "Spanish",
      icon: spanishLang,
    },
    {
      languageId: "Demo",
      locale: "fr",
      text: "Demo",
      icon: frenchLang,
    },
    {
      languageId: "Italian",
      locale: "it",
      text: "Italian",
      icon: italianLang,
    },
    {
      languageId: "Arabic",
      // languageId: "Arab",
      locale: "sa",
      text: "Arabic",
      icon: italianLang,
    },
    {
      languageId: "Hindi",
      locale: "hi",
      text: "Hindi",
      icon: italianLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
