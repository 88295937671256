import {
  SaveThreatActions,
  GetThreatDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveThreatActions,
  GetThreatActions
} from "./actions";

const initState = {
  threatSuccessMessage: null,
  threatErrorMessage: null,
  ThreatStatus: null,
  ThreatMaster: [],
  ThreatData: null,
  ActiveThreatList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveThreatActions.SAVE_THREAT:
      return {
        ...state,
        isLoading: true,
        threatErrorMessage: null,
        modalActive: false,
      };
    case SaveThreatActions.SAVE_THREAT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ThreatStatus: payload.status,
        threatErrorMessage: null,
        threatSuccessMessage: "Threat added successfully!",
      };

    case SaveThreatActions.SAVE_THREAT_FAILURE:
      return {
        ...state,
        isLoading: false,
        ThreatStatus: null,
        threatErrorMessage: payload,
      };
    case SaveThreatActions.SAVE_THREAT_STATUS:
      return {
        ...state,
        ThreatStatus: 0,
        threatErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetThreatDetailsActions.FETCH_THREAT:
      return {
        ...state,
        isLoading: true,
        threatErrorMessage: null,
        modalActive: false,
        ThreatData: null,
      };
    case GetThreatDetailsActions.FETCH_THREAT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ThreatData: payload.data,
        ThreatDataStatus: payload.status,
        threatErrorMessage: null,
      };
    case GetThreatDetailsActions.FETCH_THREAT_REJECTED:
      return {
        ...state,
        isLoading: false,
        threatErrorMessage: payload,
        ThreatData: null,
      };
    case GetThreatDetailsActions.FETCH_THREAT_STATUS:
      return {
        ...state,
        ThreatData: payload.data,
        ThreatDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveThreatActions.FETCH_ACTIVE_THREAT:
      return {
        ...state,
        isLoading: true,
        threatErrorMessage: null,
        modalActive: false,
        ActiveThreatList: null,
      };
    case GetActiveThreatActions.FETCH_ACTIVE_THREAT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveThreatList: payload.data,
        threatErrorMessage: null,
      };
    case GetActiveThreatActions.FETCH_ACTIVE_THREAT_REJECTED:
      return {
        ...state,
        isLoading: false,
        threatErrorMessage: payload,
        ActiveThreatList: null,
      };
    case GetActiveThreatActions.FETCH_ACTIVE_THREAT_STATUS:
      return {
        ...state,
        ActiveThreatList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetThreat: null,
        ThreatStatus: null,
        threatSuccessMessage: null,
        GetThreatStatus: null,
        ThreatData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

//Get Pagination
case GetThreatActions.FETCH_THREAT:
  return {
    ...state,
    isLoading: true,
    threatErrorMessage: null,
    modalActive: false,
    ThreatData: null
  };
case GetThreatActions.FETCH_THREAT_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveThreatList: payload.data,
    ThreatStatus: payload.status,  
    threatErrorMessage: null
  };
case GetThreatActions.FETCH_THREAT_REJECTED:
  return {
    ...state,
    isLoading: false,
    threatErrorMessage: payload,
    ThreatData: null
  };
case GetThreatActions.FETCH_THREAT_STATUS:
  return {
    ...state,
    ThreatData: null
  };
    
case GetThreatActions.FETCH_THREAT:
  return {
    ...state,
    isLoading: true,
    threatErrorMessage: null,
    modalActive: false,
    ThreatData: null
  };
case GetThreatActions.FETCH_THREAT_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveThreatList: payload.data,
    ThreatStatus: payload.status,  
    threatErrorMessage: null
  };
case GetThreatActions.FETCH_THREAT_REJECTED:
  return {
    ...state,
    isLoading: false,
    threatErrorMessage: payload,
    ThreatData: null
  };
case GetThreatActions.FETCH_THREAT_STATUS:
  return {
    ...state,
    ThreatData: null
  };
  /* */

  default:
    return state;


}
}

