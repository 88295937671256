import {
  SaveDataRecipientActions,
  GetDataRecipientDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataRecipientActions
} from "./actions";

const initState = {
  dataRecipientSuccessMessage: null,
  dataRecipientErrorMessage: null,
  dataRecipientStatus: null,
  dataRecipientMaster: [],
  dataRecipientData: null,
  ActiveDataRecipientList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataRecipientActions.SAVE_DATA_RECIPIENT:
      return {
        ...state,
        isLoading: true,
        dataRecipeintErrorMessage: null,
        modalActive: false
      };
    case SaveDataRecipientActions.SAVE_DATA_RECIPIENT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataRecipientStatus: payload.status,
        dataRecipientErrorMessage: null,
        dataRecipientSuccessMessage: "Data Recipient added successfully!"
      };

    case SaveDataRecipientActions.SAVE_DATA_RECIPIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataRecipientStatus: null,
        dataRecipientErrorMessage: payload
      };
    case SaveDataRecipientActions.SAVE_DATA_RECIPIENT_STATUS:
      return {
        ...state,
        dataRecipientStatus: 0,
        dataRecipientErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataRecipientErrorMessage: null,
        modalActive: false,
        dataRecipientData: null
      };
    case GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataRecipientData: payload.data,
        dataRecipientDataStatus: payload.status,
        dataRecipientErrorMessage: null
      };
    case GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataRecipientErrorMessage: payload,
        dataRecipientData: null
      };
    case GetDataRecipientDetailsActions.FETCH_DATA_RECIPIENT_DETAILS_STATUS:
      return {
        ...state,
        dataRecipientData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT:
      return {
        ...state,
        isLoading: true,
        dataRecipientErrorMessage: null,
        modalActive: false,
        ActiveDataRecipientList: null
      };
    case GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataRecipientList: payload.data,
        dataRecipientErrorMessage: null
      };
    case GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataRecipientErrorMessage: payload,
        ActivedataRecipientList: null
      };
    case GetActiveDataRecipientActions.FETCH_ACTIVE_DATA_RECIPIENT_STATUS:
      return {
        ...state,
        ActiveDataRecipientList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataRecipient: null,
        dataRecipientStatus: null,
        dataRecipientSuccessMessage: null,
        GetdataRecipientStatus: null,
        dataRecipientData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
