import axios from "axios";
import { clearLogoutData } from "../Logout/actions";
import { DashboardServiceURL } from "../Url";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDashboardLayoutActions = {
  SAVE_DASHBOARD_LAYOUT: "SAVE_DASHBOARD_LAYOUT",
  SAVE_DASHBOARD_LAYOUT_STATUS: "SAVE_DASHBOARD_LAYOUT_STATUS",
  SAVE_DASHBOARD_LAYOUT_SUCCESS: "SAVE_DASHBOARD_LAYOUT_SUCCESS",
  SAVE_DASHBOARD_LAYOUT_FAILURE: "SAVE_DASHBOARD_LAYOUT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveDashboardLayoutRequest() {
  return {
    type: SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT,
    isPosting: true,
  };
}

export function SaveDashboardLayoutSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDashboardLayoutFailure(error) {
  return {
    type: SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function SaveDashboardLayoutError(error) {
  return {
    type: SaveDashboardLayoutActions.SAVE_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function saveDashboardLayout(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${DashboardServiceURL}/saveDashboardLayout`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDashboardLayoutRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDashboardLayoutSuccess(response));
          return response;
          // } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDashboardLayoutFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDashboardLayoutFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDashboardLayoutFailure(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveDashboardLayoutFailure(err));
              break;
            case 500:
              dispatch(SaveDashboardLayoutFailure("Server Error"));
              break;
            default:
              dispatch(SaveDashboardLayoutFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get All Dashboard layout

export const GetAllDashboardLayoutActions = {
  FETCH_ALL_DASHBOARD_LAYOUT_DETAILS: "FETCH_ALL_DASHBOARD_LAYOUT_DETAILS",
  FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_STATUS:
    "FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_STATUS",
  FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_FULFILLED:
    "FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_FULFILLED",
  FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_REJECTED:
    "FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_REJECTED",
};

export function getAllDashboardLayoutDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DashboardServiceURL}/getDashboardLayout`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //  dispatch(getAllDashboardLayoutRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllDashboardLayoutSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllDashboardLayoutError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardLayoutError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardLayoutError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardLayoutError(err));
              break;
            case 500:
              dispatch(getAllDashboardLayoutError("Server Error"));
              break;
            default:
              dispatch(getAllDashboardLayoutError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllDashboardLayoutRequest() {
  return {
    type: GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS,
    isPosting: true,
  };
}
export function getAllDashboardLayoutError(error) {
  return {
    type:
      GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAllDashboardLayoutSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetAllDashboardLayoutActions.FETCH_ALL_DASHBOARD_LAYOUT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

////////////////////////// GET DASHBOARD DATA///////////////////////////

export const GetAllDashboardDataActions = {
  FETCH_ALL_DASHBOARD_DATA_DETAILS: "FETCH_ALL_DASHBOARD_DATA_DETAILS",
  FETCH_ALL_DASHBOARD_DATA_DETAILS_STATUS:
    "FETCH_ALL_DASHBOARD_DATA_DETAILS_STATUS",
  FETCH_ALL_DASHBOARD_DATA_DETAILS_FULFILLED:
    "FETCH_ALL_DASHBOARD_DATA_DETAILS_FULFILLED",
  FETCH_ALL_DASHBOARD_DATA_DETAILS_REJECTED:
    "FETCH_ALL_DASHBOARD_DATA_DETAILS_REJECTED",
};

export function getAllDashboardDataDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DashboardServiceURL}/getDashboardData`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAllDashboardDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllDashboardDataSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllDashboardDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllDashboardDataError(err));
              break;
            case 500:
              dispatch(getAllDashboardDataError("Server Error"));
              break;
            default:
              dispatch(getAllDashboardDataError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllDashboardDataRequest() {
  return {
    type: GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS,
    isPosting: true,
  };
}
export function getAllDashboardDataError(error) {
  return {
    type: GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAllDashboardDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetAllDashboardDataActions.FETCH_ALL_DASHBOARD_DATA_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

//Add Panel data/////

// Save AssetType

export const AddPanelDashboardLayoutActions = {
  ADD_PANEL_DASHBOARD_LAYOUT: "ADD_PANEL_DASHBOARD_LAYOUT",
  ADD_PANEL_DASHBOARD_LAYOUT_STATUS: "ADD_PANEL_DASHBOARD_LAYOUT_STATUS",
  ADD_PANEL_DASHBOARD_LAYOUT_SUCCESS: "ADD_PANEL_DASHBOARD_LAYOUT_SUCCESS",
  ADD_PANEL_DASHBOARD_LAYOUT_FAILURE: "ADD_PANEL_DASHBOARD_LAYOUT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function AddPanelDashboardLayoutRequest() {
  return {
    type: AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT,
    isPosting: true,
  };
}

export function AddPanelDashboardLayoutSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_SUCCESS,
      payload: props,
    });
  };
}

export function AddPanelDashboardLayoutFailure(error) {
  return {
    type: AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function AddPanelDashboardLayoutError(error) {
  return {
    type: AddPanelDashboardLayoutActions.ADD_PANEL_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function addPanelData(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${DashboardServiceURL}/addPanel`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(AddPanelDashboardLayoutRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(AddPanelDashboardLayoutSuccess(response));
          return response;
          // } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(AddPanelDashboardLayoutFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(AddPanelDashboardLayoutFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(AddPanelDashboardLayoutFailure(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(AddPanelDashboardLayoutFailure(err));
              break;
            case 500:
              dispatch(AddPanelDashboardLayoutFailure("Server Error"));
              break;
            default:
              dispatch(AddPanelDashboardLayoutFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Add Panel data/////

// Save AssetType

export const CustomizeDashboardLayoutActions = {
  CUSTOMIZE_DASHBOARD_LAYOUT: "CUSTOMIZE_DASHBOARD_LAYOUT",
  CUSTOMIZE_DASHBOARD_LAYOUT_STATUS: "CUSTOMIZE_DASHBOARD_LAYOUT_STATUS",
  CUSTOMIZE_DASHBOARD_LAYOUT_SUCCESS: "CUSTOMIZE_DASHBOARD_LAYOUT_SUCCESS",
  CUSTOMIZE_DASHBOARD_LAYOUT_FAILURE: "CUSTOMIZE_DASHBOARD_LAYOUT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function CustomizeDashboardLayoutRequest() {
  return {
    type: CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT,
    isPosting: true,
  };
}

export function CustomizeDashboardLayoutSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_SUCCESS,
      payload: props,
    });
  };
}

export function CustomizeDashboardLayoutFailure(error) {
  return {
    type: CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function CustomizeDashboardLayoutError(error) {
  return {
    type: CustomizeDashboardLayoutActions.CUSTOMIZE_DASHBOARD_LAYOUT_FAILURE,
    payload: error,
  };
}

export function getCustomizeView(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url: `${DashboardServiceURL}/getCustomizePanelView`,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(CustomizeDashboardLayoutRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(CustomizeDashboardLayoutSuccess(response));
          return response;
          // } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(CustomizeDashboardLayoutFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CustomizeDashboardLayoutFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CustomizeDashboardLayoutFailure(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CustomizeDashboardLayoutFailure(err));
              break;
            case 500:
              dispatch(CustomizeDashboardLayoutFailure("Server Error"));
              break;
            default:
              dispatch(CustomizeDashboardLayoutFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}
