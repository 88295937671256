import {
  saveCookieCategoryActions,
  getCokieCategoryDetailsActions,
  ClearFormDetailsActions,
  GetActiveConsentHeadActions,
  UploadexcelActions,
  getAllUnassignedCookiesDetailsActions,
  saveCatIdCookieDetailsActions
} from "./actions";

const initState = {
  cookieSuccessMessage: null,
  cookieCategoryErrorMessage: null,
  cookieCategorStatus: null,
  cookieCategoryMaster: [],
  cookieCategoryData: null,
  ActiveCookieCategoryList: [],
  unAssignedcookieCategoryData:[],
  cookieCatIdData:[]
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveCookieCategoryActions.SAVE_COOKIE_CATEGORY:
      return {
        ...state,
        isLoading: true,
        cookieCategoryErrorMessage: null,
        modalActive: false
      };
    case saveCookieCategoryActions. SAVE_COOKIE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookieCategorStatus: payload.status,
        cookieCategoryErrorMessage: null,
        cookieCategorySuccessMessage: "Cookie category Added Successfully!"
      };

    case saveCookieCategoryActions.SAVE_COOKIE_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        cookieCategorStatus: null,
        cookieCategoryErrorMessage: payload
      };
    case saveCookieCategoryActions.SAVE_COOKIE_CATEGORY_STATUS:
      return {
        ...state,
        cookieCategorStatus: 0,
        cookieCategoryErrorMessage: null
      };
    ///////////////////////////////////////////////////////
    
    // GET  BY ID
    case getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS:
      return {
        ...state,
        isLoading: true,
        cookieCategoryErrorMessage: null,
        modalActive: false,
        cookieCategoryData: null
      };
    case getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookieCategoryData: payload.data,
        cookieCategoryDataStatus: payload.status,
        cookieCategoryErrorMessage: null
      };
    case getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        cookieCategoryErrorMessage: payload,
        cookieCategoryData: null
      };
    case getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_STATUS:
      return {
        ...state,
        cookieCategoryData: null
      };

      // GET  BY ID
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD:
      return {
        ...state,
        isLoading: true,
        cookieCategoryErrorMessage: null,
        modalActive: false,
        ActiveCookieCategoryList: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveCookieCategoryList: payload.data,
        cookieCategoryDataStatus: payload.status,
        cookieCategoryErrorMessage: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentHeadErrorMessage: payload,
        ActiveCookieCategoryList: null
      };
    case GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_STATUS:
      return {
        ...state,
        cookieCategoryData: null
      };
    // clear ConsentPurpose details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetConsentHead: null,
        cookieCategorStatus: null,
        HeadSuccessMessage: null,
        GetcookieCategorStatus: null,
        cookieCategoryData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */
 ///////////////////////////////////////////////////////
    
    // GET  BY ID
    case getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS:
      return {
        ...state,
        isLoading: true,
        cookieCategoryErrorMessage: null,
        unAssignedcookieCategoryData: null
      };
    case getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        unAssignedcookieCategoryData: payload.data,
      };
    case getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        unAssignedcookieCategoryData: null
      };
    case getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_STATUS:
      return {
        ...state,
        unAssignedcookieCategoryData: null
      };
////////////////  save ////
case saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY:
      return {
        ...state,
        isLoading: true,
        cookieCategoryErrorMessage: null,
        modalActive: false
      };
    case saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookieCatIdData: payload.status,
        cookieCategoryErrorMessage: null,
        cookieCategorySuccessMessage: "Cookie category Added Successfully!"
      };

    case saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        cookieCatIdData: null,
        cookieCategoryErrorMessage: payload
      };
    case saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_STATUS:
      return {
        ...state,
        cookieCatIdData: 0,
        cookieCategoryErrorMessage: null
      };

    default:
      return state;
  }
}
