import axios from "axios";
import {
  SuperAdmin_Service_URL,
  AssetManagementService_URL,
  RequestManagement_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save RequestSources

export const saveRequestSourcesActions = {
  SAVE_REQUEST_SOURCES: "SAVE_REQUEST_SOURCES",
  SAVE_REQUEST_SOURCES_STATUS: "SAVE_REQUEST_SOURCES_STATUS",
  SAVE_REQUEST_SOURCES_SUCCESS: "SAVE_REQUEST_SOURCES_SUCCESS",
  SAVE_REQUEST_SOURCES_FAILURE: "SAVE_REQUEST_SOURCES_FAILURE",
};

export function saveRequestSourcesRequest() {
  return {
    type: saveRequestSourcesActions.SAVE_REQUEST_SOURCES,
    isPosting: true,
  };
}

export function saveRequestSourcesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRequestSourcesActions.SAVE_REQUEST_SOURCES_SUCCESS,
      payload: props,
    });
  };
}

export function saveRequestSourcesFailure(error) {
  return {
    type: saveRequestSourcesActions.SAVE_REQUEST_SOURCES_FAILURE,
    payload: error,
  };
}

export function saveRequestSourcesError(error) {
  return {
    type: saveRequestSourcesActions.SAVE_REQUEST_SOURCES_FAILURE,
    payload: error,
  };
}

export function saveRequestSources(props) {
  var value = {
    id: props.id || 0,
    name: props.name,
    status: props.status,
    description: props.description,
    code: props.code,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${RequestManagement_URL}/saveRequestSources`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveRequestSourcesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRequestSourcesSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRequestSourcesError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveRequestSourcesError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveRequestSourcesError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveRequestSourcesError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveRequestSourcesError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveRequestSourcesError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete RequestSources

export const deleteRequestSourcesActions = {
  DELETE_REQUEST_SOURCES: "DELETE_REQUEST_SOURCES",
  DELETE_REQUEST_SOURCES_STATUS: "DELETE_REQUEST_SOURCES_STATUS",
  DELETE_REQUEST_SOURCES_SUCCESS: "DELETE_REQUEST_SOURCES_SUCCESS",
  DELETE_REQUEST_SOURCES_FAILURE: "DELETE_REQUEST_SOURCES_FAILURE",
};

export function deleteRequestSourcesRequest() {
  return {
    type: deleteRequestSourcesActions.DELETE_REQUEST_SOURCES,
    isPosting: true,
  };
}

export function deleteRequestSourcesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_SUCCESS,
      payload: props,
    });
  };
}

export function deleteRequestSourcesFailure(error) {
  return {
    type: deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_FAILURE,
    payload: error,
  };
}

export function deleteRequestSourcesError(error) {
  return {
    type: deleteRequestSourcesActions.DELETE_REQUEST_SOURCES_FAILURE,
    payload: error,
  };
}

export function deleteRequestSources(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${AssetManagementService_URL}/deleteRequestSources?requestSourcesId=` +
        params.requestSourcesID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteRequestSourcesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteRequestSourcesSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteRequestSourcesError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestSourcesError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestSourcesError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRequestSourcesError(err));
              break;
            case 500:
              dispatch(deleteRequestSourcesError("Server Error"));
              break;
            default:
              dispatch(deleteRequestSourcesError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRequestSourcesDetailsActions = {
  FETCH_REQUEST_SOURCES_DETAILS: "FETCH_REQUEST_SOURCES_DETAILS",
  FETCH_REQUEST_SOURCES_DETAILS_STATUS: "FETCH_REQUEST_SOURCES_DETAILS_STATUS",
  FETCH_REQUEST_SOURCES_DETAILS_FULFILLED:
    "FETCH_REQUEST_SOURCES_DETAILS_FULFILLED",
  FETCH_REQUEST_SOURCES_DETAILS_REJECTED:
    "FETCH_REQUEST_SOURCES_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRequestSourcesDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RequestManagement_URL}/findRequestSourcesById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRequestSourcesDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRequestSourcesDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRequestSourcesDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestSourcesDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestSourcesDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRequestSourcesDetailsError(err));
              break;
            case 500:
              dispatch(getRequestSourcesDetailsError("Server Error"));
              break;
            default:
              dispatch(getRequestSourcesDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRequestSourcesDetailsRequest() {
  return {
    type: GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS,
    isPosting: true,
  };
}
export function getRequestSourcesDetailsError(error) {
  return {
    type:
      GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRequestSourcesDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRequestSourcesDetailsStatus() {
  return (dispatch) => dispatch(getRequestSourcesDetailsStatusRequest());
}
export function getRequestSourcesDetailsStatusRequest() {
  return {
    type: GetRequestSourcesDetailsActions.FETCH_REQUEST_SOURCES_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRequestSourcesDetailsErrorRequest());
}
export function getRequestSourcesDetailsErrorRequest() {
  return {
    type: GetRequestSourcesDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("requestSourcess", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveRequestSourcesActions = {
  FETCH_ACTIVE_REQUEST_SOURCES: "FETCH_ACTIVE_REQUEST_SOURCES",
  FETCH_ACTIVE_REQUEST_SOURCES_STATUS: "FETCH_ACTIVE_REQUEST_SOURCES_STATUS",
  FETCH_ACTIVE_REQUEST_SOURCES_FULFILLED:
    "FETCH_ACTIVE_REQUEST_SOURCES_FULFILLED",
  FETCH_ACTIVE_REQUEST_SOURCES_REJECTED:
    "FETCH_ACTIVE_REQUEST_SOURCES_REJECTED",
};

export function getActiveRequestSourcesList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RequestManagement_URL}/getAllRequestSourcesPagination?start=0&sortOrder=desc&booleanfield=true&sortField=id&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRequestSourcesListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRequestSourcesListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveRequestSourcesListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestSourcesListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestSourcesListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRequestSourcesListError(err));
              break;
            case 500:
              dispatch(getActiveRequestSourcesListError("Server Error"));
              break;
            default:
              dispatch(getActiveRequestSourcesListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRequestSourcesListRequest() {
  return {
    type: GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES,
    isPosting: true,
  };
}
export function getActiveRequestSourcesListError(error) {
  return {
    type: GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_REJECTED,
    payload: error,
  };
}

export function getActiveRequestSourcesListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRequestSourcesListStatus() {
  return (dispatch) => dispatch(getActiveRequestSourcesListStatusRequest());
}
export function getActiveRequestSourcesListStatusRequest() {
  return {
    type: GetActiveRequestSourcesActions.FETCH_ACTIVE_REQUEST_SOURCES_STATUS,
  };
}
