import axios from "axios";
import { RopaManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Ropa

export const saveRopaActions = {
  SAVE_ROPA: "SAVE_ROPA",
  SAVE_ROPA_STATUS: "SAVE_ROPA_STATUS",
  SAVE_ROPA_SUCCESS: "SAVE_ROPA_SUCCESS",
  SAVE_ROPA_FAILURE: "SAVE_ROPA_FAILURE",
};

export function saveRopaRequest() {
  return {
    type: saveRopaActions.SAVE_ROPA,
    isPosting: true,
  };
}

export function saveRopaSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRopaActions.SAVE_ROPA_SUCCESS,
      payload: props,
    });
  };
}

export function saveRopaFailure(error) {
  return {
    type: saveRopaActions.SAVE_ROPA_FAILURE,
    payload: error,
  };
}

export function saveRopaError(error) {
  return {
    type: saveRopaActions.SAVE_ROPA_FAILURE,
    payload: error,
  };
}

export function saveRopa(props, access_token) {
  // var value = {
  //   ropaTypeId: props.ropaTypeId || null,
  //   ropaTypeName: props.ropaTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_19/saveRopaDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveRopaRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRopaSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRopaError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRopaError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRopaError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRopaError(err));
              break;
            case 500:
              dispatch(saveRopaError("Server Error"));
              break;
            default:
              dispatch(saveRopaError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete Ropa

export const deleteRopaActions = {
  DELETE_ROPA: "DELETE_ROPA",
  DELETE_ROPA_STATUS: "DELETE_ROPA_STATUS",
  DELETE_ROPA_SUCCESS: "DELETE_ROPA_SUCCESS",
  DELETE_ROPA_FAILURE: "DELETE_ROPA_FAILURE",
};

export function deleteRopaRequest() {
  return {
    type: deleteRopaActions.DELETE_ROPA,
    isPosting: true,
  };
}

export function deleteRopaSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteRopaActions.DELETE_ROPA_SUCCESS,
      payload: props,
    });
  };
}

export function deleteRopaFailure(error) {
  return {
    type: deleteRopaActions.DELETE_ROPA_FAILURE,
    payload: error,
  };
}

export function deleteRopaError(error) {
  return {
    type: deleteRopaActions.DELETE_ROPA_FAILURE,
    payload: error,
  };
}

export function deleteRopa(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RopaManagementService_URL}/Apc_State/Amc_19/deleteRopa?templateId=` +
        params.ropaId,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteRopaRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteRopaSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteRopaError(error));
          throw error;
        }
      })
      .catch((err) => {
        //

        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 500:
          //   dispatch(deleteRopaError("Internal Server Error"));
          //   break;
          // default:
          //   dispatch(deleteRopaError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRopaDetailsActions = {
  FETCH_ROPA_DETAILS: "FETCH_ROPA_DETAILS",
  FETCH_ROPA_DETAILS_STATUS: "FETCH_ROPA_DETAILS_STATUS",
  FETCH_ROPA_DETAILS_FULFILLED: "FETCH_ROPA_DETAILS_FULFILLED",
  FETCH_ROPA_DETAILS_REJECTED: "FETCH_ROPA_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRopaDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/findRopaMasterById
?id=` + params.ropaId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getRopaDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRopaDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRopaDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRopaDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRopaDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRopaDetailsError(err));
              break;
            case 500:
              dispatch(getRopaDetailsError("Server Error"));
              break;
            default:
              dispatch(getRopaDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRopaDetailsRequest() {
  return {
    type: GetRopaDetailsActions.FETCH_ROPA_DETAILS,
    isPosting: true,
  };
}
export function getRopaDetailsError(error) {
  return {
    type: GetRopaDetailsActions.FETCH_ROPA_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRopaDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRopaDetailsActions.FETCH_ROPA_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRopaDetailsStatus() {
  return (dispatch) => dispatch(getRopaDetailsStatusRequest());
}
export function getRopaDetailsStatusRequest() {
  return {
    type: GetRopaDetailsActions.FETCH_ROPA_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRopaDetailsErrorRequest());
}
export function getRopaDetailsErrorRequest() {
  return {
    type: GetRopaDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ropaTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RopaManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ROPAS :LIST

export const GetActiveRopaActions = {
  FETCH_ACTIVE_ROPAS: "FETCH_ACTIVE_ROPAS",
  FETCH_ACTIVE_ROPAS_STATUS: "FETCH_ACTIVE_ROPAS_STATUS",
  FETCH_ACTIVE_ROPAS_FULFILLED: "FETCH_ACTIVE_ROPAS_FULFILLED",
  FETCH_ACTIVE_ROPAS_REJECTED: "FETCH_ACTIVE_ROPAS_REJECTED",
};

export function getActiveRopaList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RopaManagementService_URL}/Apc_View/Amc_19/getAllRopaMasterPagination?start=0&sortOrder=desc&booleanfield=true&sortField=id&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRopaListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRopaListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveRopaListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRopaListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRopaListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRopaListError(err));
              break;
            case 500:
              dispatch(getActiveRopaListError("Server Error"));
              break;
            default:
              dispatch(getActiveRopaListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRopaListRequest() {
  return {
    type: GetActiveRopaActions.FETCH_ACTIVE_ROPAS,
    isPosting: true,
  };
}
export function getActiveRopaListError(error) {
  return {
    type: GetActiveRopaActions.FETCH_ACTIVE_ROPAS_REJECTED,
    payload: error,
  };
}

export function getActiveRopaListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRopaActions.FETCH_ACTIVE_ROPAS_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRopaListStatus() {
  return (dispatch) => dispatch(getActiveRopaListStatusRequest());
}
export function getActiveRopaListStatusRequest() {
  return {
    type: GetActiveRopaActions.FETCH_ACTIVE_ROPAS_STATUS,
  };
}
// Save Doc

export const saveDocActions = {
  SAVE_DOC: "SAVE_DOC",
  SAVE_DOC_STATUS: "SAVE_DOC_STATUS",
  SAVE_DOC_SUCCESS: "SAVE_DOC_SUCCESS",
  SAVE_DOC_FAILURE: "SAVE_DOC_FAILURE",
};

export function saveDocRequest() {
  return {
    type: saveDocActions.SAVE_DOC,
    isPosting: true,
  };
}

export function saveDocSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveDocActions.SAVE_DOC_SUCCESS,
      payload: props,
    });
  };
}

export function saveDocFailure(error) {
  return {
    type: saveDocActions.SAVE_DOC_FAILURE,
    payload: error,
  };
}

export function saveDocError(error) {
  return {
    type: saveDocActions.SAVE_DOC_FAILURE,
    payload: error,
  };
}

export function saveDoc(base64, access_token) {
  // var value = {
  //   docTypeId: props.docTypeId || null,
  //   docTypeName: props.docTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: base64,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_19/saveDocumentDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveDocRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveDocSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveDocError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

/////////////EDIT DOC///////////////////////////////////////

export const EditDocActions = {
  EDIT_DOC: "EDIT_DOC",
  EDIT_DOC_STATUS: "EDIT_DOC_STATUS",
  EDIT_DOC_SUCCESS: "EDIT_DOC_SUCCESS",
  EDIT_DOC_FAILURE: "EDIT_DOC_FAILURE",
};

export function EditDocRequest() {
  return {
    type: EditDocActions.EDIT_DOC,
    isPosting: true,
  };
}

export function EditDocSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: EditDocActions.EDIT_DOC_SUCCESS,
      payload: props,
    });
  };
}

export function EditDocFailure(error) {
  return {
    type: EditDocActions.EDIT_DOC_FAILURE,
    payload: error,
  };
}

export function EditDocError(error) {
  return {
    type: EditDocActions.EDIT_DOC_FAILURE,
    payload: error,
  };
}

export function EditDoc(parms, access_token) {
  // var value = {
  //   docTypeId: props.docTypeId || null,
  //   docTypeName: props.docTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      //data: base64,
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/getRopaDocumentDetails?ropaId=` +
        parms,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(EditDocRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(EditDocSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(EditDocError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete ActivityDetails

export const deleteActivityDetailsActions = {
  DELETE_ACTIVITY_DETAILS: "DELETE_ACTIVITY_DETAILS",
  DELETE_ACTIVITY_DETAILS_STATUS: "DELETE_ACTIVITY_DETAILS_STATUS",
  DELETE_ACTIVITY_DETAILS_SUCCESS: "DELETE_ACTIVITY_DETAILS_SUCCESS",
  DELETE_ACTIVITY_DETAILS_FAILURE: "DELETE_ACTIVITY_DETAILS_FAILURE",
};

export function deleteActivityDetailsRequest() {
  return {
    type: deleteActivityDetailsActions.DELETE_ACTIVITY_DETAILS,
    isPosting: true,
  };
}

export function deleteActivityDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteActivityDetailsActions.DELETE_ACTIVITY_DETAILS_SUCCESS,
      payload: props,
    });
  };
}

export function deleteActivityDetailsFailure(error) {
  return {
    type: deleteActivityDetailsActions.DELETE_ACTIVITY_DETAILS_FAILURE,
    payload: error,
  };
}

export function deleteActivityDetailsError(error) {
  return {
    type: deleteActivityDetailsActions.DELETE_ROPA_FAILURE,
    payload: error,
  };
}

export function deleteActivityDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RopaManagementService_URL}/Apc_State/Amc_24/deleteActivityById?id=` +
        params.id +
        `&status=false`,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteActivityDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteActivityDetailsSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteActivityDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        //

        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRopaError(err));
          //   break;
          // case 500:
          //   dispatch(deleteRopaError("Internal Server Error"));
          //   break;
          // default:
          //   dispatch(deleteRopaError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// ROPA ACTIVITY CITY
export const GetRopaActivityCityActions = {
  GET_ROPA_ACTIVITY_CITY: "GET_ROPA_ACTIVITY_CITY",
  GET_ROPA_ACTIVITY_CITY_STATUS: "GET_ROPA_ACTIVITY_CITY_STATUS",
  GET_ROPA_ACTIVITY_CITY_SUCCESS: "GET_ROPA_ACTIVITY_CITY_SUCCESS",
  GET_ROPA_ACTIVITY_CITY_FAILURE: "GET_ROPA_ACTIVITY_CITY_FAILURE",
};

export function GetRopaActivityCityRequest() {
  return {
    type: GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY,
    isPosting: true,
  };
}

export function GetRopaActivityCitySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY_SUCCESS,
      payload: props,
    });
  };
}

export function GetRopaActivityCityError(error) {
  return {
    type: GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY_FAILURE,
    payload: error,
  };
}

export function GetRopaActivityCity(parms) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RopaManagementService_URL}/getActivityCityByRopaId?id=` +
        parms.ropaId,
      headers: {
        authorization: "Bearer " + parms.access_token,
      },
    })
      .then((response) => {
        dispatch(GetRopaActivityCityRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(GetRopaActivityCitySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(GetRopaActivityCityError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(GetRopaActivityCityError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(GetRopaActivityCityError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(GetRopaActivityCityError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(GetRopaActivityCityError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(GetRopaActivityCityError(err));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function validateAddProcessRecord(access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url: `${RopaManagementService_URL}/Apc_View/Amc_19/companyProcessingActivitiesLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}
