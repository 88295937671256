import axios from "axios";
import { CompanyService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveRiskMasterActions = {
  SAVE_RISK_MASTER: "SAVE_RISK_MASTER",
  SAVE_RISK_MASTER_STATUS: "SAVE_RISK_MASTER_STATUS",
  SAVE_RISK_MASTER_SUCCESS: "SAVE_RISK_MASTER_SUCCESS",
  SAVE_RISK_MASTER_FAILURE: "SAVE_RISK_MASTER_FAILURE",
};

export function SaveRiskMasterRequest() {
  return {
    type: SaveRiskMasterActions.SAVE_RISK_MASTER,
    isPosting: true,
  };
}

export function SaveRiskMasterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveRiskMasterActions.SAVE_RISK_MASTER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveRiskMasterFailure(error) {
  return {
    type: SaveRiskMasterActions.SAVE_RISK_MASTER_FAILURE,
    payload: error,
  };
}

export function SaveRiskMasterError(error) {
  return {
    type: SaveRiskMasterActions.SAVE_RISK_MASTER_FAILURE,
    payload: error,
  };
}

export function saveRiskMaster(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CompanyService_URL}/Apc_Persist/Amc_32/saveRiskLevel`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveRiskMasterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveRiskMasterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveRiskMasterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveRiskMasterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveRiskMasterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveRiskMasterError(err));
              break;
            case 500:
              dispatch(SaveRiskMasterError("Server Error"));
              break;
            default:
              dispatch(SaveRiskMasterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRiskMasterDetailsActions = {
  FETCH_HOSTING_DETAILS: "FETCH_HOSTING_DETAILS",
  FETCH_HOSTING_DETAILS_STATUS: "FETCH_HOSTING_DETAILS_STATUS",
  FETCH_HOSTING_DETAILS_FULFILLED: "FETCH_HOSTING_DETAILS_FULFILLED",
  FETCH_HOSTING_DETAILS_REJECTED: "FETCH_HOSTING_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskMasterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_10/findHostProviderTypeMasterById?hostProviderTypeId=` +
        params.hostingTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRiskMasterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskMasterDetailsSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskMasterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskMasterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskMasterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskMasterDetailsError(err));
              break;
            case 500:
              dispatch(getRiskMasterDetailsError("Server Error"));
              break;
            default:
              dispatch(getRiskMasterDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskMasterDetailsRequest() {
  return {
    type: GetRiskMasterDetailsActions.FETCH_HOSTING_DETAILS,
    isPosting: true,
  };
}
export function getRiskMasterDetailsError(error) {
  return {
    type: GetRiskMasterDetailsActions.FETCH_HOSTING_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRiskMasterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRiskMasterDetailsActions.FETCH_HOSTING_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskMasterDetailsStatus() {
  return (dispatch) => dispatch(getRiskMasterDetailsStatusRequest());
}
export function getRiskMasterDetailsStatusRequest() {
  return {
    type: GetRiskMasterDetailsActions.FETCH_HOSTING_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRiskMasterDetailsErrorRequest());
}
export function getRiskMasterDetailsErrorRequest() {
  return {
    type: GetRiskMasterDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${CompanyService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveRiskMasterActions = {
  FETCH_ACTIVE_RISK_MASTER: "FETCH_ACTIVE_RISK_MASTER",
  FETCH_ACTIVE_RISK_MASTER_STATUS: "FETCH_ACTIVE_RISK_MASTER_STATUS",
  FETCH_ACTIVE_RISK_MASTER_FULFILLED: "FETCH_ACTIVE_RISK_MASTER_FULFILLED",
  FETCH_ACTIVE_RISK_MASTER_REJECTED: "FETCH_ACTIVE_RISK_MASTER_REJECTED",
};
export function getActiveRiskMasterList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_32/findCompanyRiskLevels`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRiskMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskMasterListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveRiskMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMasterListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMasterListError(err));
              break;
            case 500:
              dispatch(getActiveRiskMasterListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskMasterListRequest() {
  return {
    type: GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER,
    isPosting: true,
  };
}
export function getActiveRiskMasterListError(error) {
  return {
    type: GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER_REJECTED,
    payload: error,
  };
}

export function getActiveRiskMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskMasterListStatus() {
  return (dispatch) => dispatch(getActiveRiskMasterListStatusRequest());
}
export function getActiveRiskMasterListStatusRequest() {
  return {
    type: GetActiveRiskMasterActions.FETCH_ACTIVE_RISK_MASTER_STATUS,
  };
}

export const SaveRiskMatrixActions = {
  SAVE_RISK_MATRIX: "SAVE_RISK_MATRIX",
  SAVE_RISK_MATRIX_STATUS: "SAVE_RISK_MATRIX_STATUS",
  SAVE_RISK_MATRIX_SUCCESS: "SAVE_RISK_MATRIX_SUCCESS",
  SAVE_RISK_MATRIX_FAILURE: "SAVE_RISK_MATRIX_FAILURE",
};

export function SaveRiskMatrixRequest() {
  return {
    type: SaveRiskMatrixActions.SAVE_RISK_MATRIX,
    isPosting: true,
  };
}

export function SaveRiskMatrixSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveRiskMatrixActions.SAVE_RISK_MATRIX_SUCCESS,
      payload: props,
    });
  };
}

export function SaveRiskMatrixFailure(error) {
  return {
    type: SaveRiskMatrixActions.SAVE_RISK_MATRIX_FAILURE,
    payload: error,
  };
}

export function SaveRiskMatrixError(error) {
  return {
    type: SaveRiskMatrixActions.SAVE_RISK_MATRIX_FAILURE,
    payload: error,
  };
}

export function SaveRiskMatrix(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CompanyService_URL}/Apc_Persist/Amc_32/saveRiskMatrixColor`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveRiskMatrixRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveRiskMatrixSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveRiskMatrixError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveRiskMatrixError(err));
              return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(SaveRiskMatrixError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(SaveRiskMatrixError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(SaveRiskMatrixError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveRiskMatrixError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveRiskMatrixError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveRiskMatrixActions = {
  FETCH_ACTIVE_RISK_MATRIX: "FETCH_ACTIVE_RISK_MATRIX",
  FETCH_ACTIVE_RISK_MATRIX_STATUS: "FETCH_ACTIVE_RISK_MATRIX_STATUS",
  FETCH_ACTIVE_RISK_MATRIX_FULFILLED: "FETCH_ACTIVE_RISK_MATRIX_FULFILLED",
  FETCH_ACTIVE_RISK_MATRIX_REJECTED: "FETCH_ACTIVE_RISK_MATRIX_REJECTED",
};
export function getActiveRiskMatrixList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_32/findRiskMatrixColor`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRiskMatrixListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskMatrixListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveRiskMatrixListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMatrixListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMatrixListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskMatrixListError(err));
              break;
            case 500:
              dispatch(getActiveRiskMatrixListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskMatrixListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskMatrixListRequest() {
  return {
    type: GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX,
    isPosting: true,
  };
}
export function getActiveRiskMatrixListError(error) {
  return {
    type: GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX_REJECTED,
    payload: error,
  };
}

export function getActiveRiskMatrixListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskMatrixListStatus() {
  return (dispatch) => dispatch(getActiveRiskMatrixListStatusRequest());
}
export function getActiveRiskMatrixListStatusRequest() {
  return {
    type: GetActiveRiskMatrixActions.FETCH_ACTIVE_RISK_MATRIX_STATUS,
  };
}

export const GetRiskByTypeActions = {
  FETCH_ACTIVE_RISK_BY_TYPE: "FETCH_ACTIVE_RISK_BY_TYPE",
  FETCH_ACTIVE_RISK_BY_TYPE_STATUS: "FETCH_ACTIVE_RISK_BY_TYPE_STATUS",
  FETCH_ACTIVE_RISK_BY_TYPE_FULFILLED: "FETCH_ACTIVE_RISK_BY_TYPE_FULFILLED",
  FETCH_ACTIVE_RISK_BY_TYPE_REJECTED: "FETCH_ACTIVE_RISK_BY_TYPE_REJECTED",
};
export function getRiskByTypeList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CompanyService_URL}/Apc_View/Amc_32/getAllRiskLevelPaginationsortOrder=desc&sortField=id&status=true&type=` +
        params.type,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRiskByTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskByTypeListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskByTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //   switch (err.response.status) {
          //     case 400:
          //       map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //       map.forEach((ele) => (err = ele));

          //       dispatch(getRiskByTypeListError(err));
          //       break;
          //     case 404:
          //       map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //       map.forEach((ele) => (err = ele));

          //       dispatch(getRiskByTypeListError(err));
          //       break;
          //     case 406:
          //       map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //       map.forEach((ele) => (err = ele));

          //       dispatch(getRiskByTypeListError(err));
          //       break;
          //     case 500:
          //       dispatch(getRiskByTypeListError("Server Error"));
          //       break;
          //     default:
          //       dispatch(getRiskByTypeListError("Unknown Error"));
          //       break;
          //   }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskByTypeListRequest() {
  return {
    type: GetRiskByTypeActions.FETCH_ACTIVE_RISK_BY_TYPE,
    isPosting: true,
  };
}
export function getRiskByTypeListError(error) {
  return {
    type: GetRiskByTypeActions.FETCH_ACTIVE_RISK_BY_TYPE_REJECTED,
    payload: error,
  };
}

export function getRiskByTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRiskByTypeActions.FETCH_ACTIVE_RISK_BY_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskByTypeListStatus() {
  return (dispatch) => dispatch(getRiskByTypeListStatusRequest());
}
export function getRiskByTypeListStatusRequest() {
  return {
    type: GetRiskByTypeActions.FETCH_ACTIVE_RISK_BY_TYPE_STATUS,
  };
}
