import {
  saveGroupActions,
  deleteGroupActions,
  GetGroupDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveGroupActions
} from "./actions";

const initState = {
  groupSuccessMessage: null,
  groupErrorMessage: null,
  GroupStatus: null,
  GroupMaster: [],
  GroupData: null,
  ActiveGroupList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveGroupActions.SAVE_GROUP:
      return {
        ...state,
        isLoading: true,
        groupErrorMessage: null,
        modalActive: false
      };
    case saveGroupActions.SAVE_GROUP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        GroupStatus: payload.status,
        groupErrorMessage: null,
        groupSuccessMessage: "Group Type added successfully!"
      };

    case saveGroupActions.SAVE_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        GroupStatus: null,
        groupErrorMessage: payload
      };
    case saveGroupActions.SAVE_GROUP_STATUS:
      return {
        ...state,
        GroupStatus: 0,
        groupErrorMessage: null
      };

    // Delete Group Type

    case deleteGroupActions.DELETE_GROUP:
      return {
        ...state,
        isLoading: true,
        groupErrorMessage: null,
        modalActive: false
      };
    case deleteGroupActions.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        GroupStatus: payload.status,
        groupErrorMessage: null,
        groupSuccessMessage: "Group Type Deleted successfully!"
      };

    case deleteGroupActions.DELETE_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        GroupStatus: null,
        groupErrorMessage: payload
      };
    case deleteGroupActions.DELETE_GROUP_STATUS:
      return {
        ...state,
        GroupStatus: 0,
        groupErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetGroupDetailsActions.FETCH_GROUP_DETAILS:
      return {
        ...state,
        isLoading: true,
        groupErrorMessage: null,
        modalActive: false,
        GroupData: null
      };
    case GetGroupDetailsActions.FETCH_GROUP_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        GroupData: payload.data,
        GroupDataStatus: payload.status,
        groupErrorMessage: null
      };
    case GetGroupDetailsActions.FETCH_GROUP_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        groupErrorMessage: payload,
        GroupData: null
      };
    case GetGroupDetailsActions.FETCH_GROUP_DETAILS_STATUS:
      return {
        ...state,
        GroupData: null
      };
    ///Active groups
    case GetActiveGroupActions.FETCH_ACTIVE_GROUPS:
      return {
        ...state,
        isLoading: true,
        groupErrorMessage: null,
        modalActive: false,
        ActiveGroupList: null
      };
    case GetActiveGroupActions.FETCH_ACTIVE_GROUPS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveGroupList: payload.data,
        groupErrorMessage: null
      };
    case GetActiveGroupActions.FETCH_ACTIVE_GROUPS_REJECTED:
      return {
        ...state,
        isLoading: false,
        groupErrorMessage: payload,
        ActiveGroupList: null
      };
    case GetActiveGroupActions.FETCH_ACTIVE_GROUPS_STATUS:
      return {
        ...state,
        ActiveGroupList: null
      };
    // clear groupType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetGroup: null,
        GroupStatus: null,
        groupSuccessMessage: null,
        GetGroupStatus: null,
        GroupData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
