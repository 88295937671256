import {
  SaveFormBuilderActions,
  GetBreachDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveFormBuilderActions,
  SaveBreachActions,
  GetActiveTemplateActions,
  CopyFormDetailsActions,
  SaveFormDetailsProps,
  TemplateDetailsActions,
  GetBreachReviewActions,
  GetBreachIncidentDetailsActions,
  SaveBreachIncidentActions,
  ViewBreachDetailsActions,
  GetBreachSetupDetailsActions,
  SaveBreachSetupActions,
} from "./actions";

const initState = {
  formBuilderSuccessMessage: null,
  formBuilderErrorMessage: null,
  formBuilderStatus: null,
  formBuilderMaster: [],
  formBuilderData: null,
  ActiveFormBuilderList: null,
  breachStatus: null,
  breachErrorMessage: null,
  breachSuccessMessage: null,
  SaveBreachData: null,
  CopyDetails: false,
  TemplateSelected: false,
  TemplateFormData: null,
  formbuilderResponse: null,
  BreachIncidentDataErrorMessage: null,
  BreachIncidentData: null,
  BreachIncidentDataStatus: null,
  breachIncidentErrorMessage: null,
  SaveBreachIncidentData: null,
  breachIncidentStatus: null,
  breachIncidentSuccessMessage: null,
  ViewBreachErrorMessage: null,
  ViewBreachData: null,
  ViewBreachStatus: null,
  breachSetupErrorMessage: null,
  SaveBreachSetupData: null,
  breachSetupStatus: null,
  breachSetupSuccessMessage: null,
  FetchBreachStatusErrorMessage: null,
  SetupBreachData: null,
  SetupBreachStatus: null,
  ActiveInvestigationTempList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveFormBuilderActions.SAVE_FORMBUILDER:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
      };
    case SaveFormBuilderActions.SAVE_FORMBUILDER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formBuilderStatus: payload.status,
        formBuilderErrorMessage: null,
        formBuilderSuccessMessage: "Breach added successfully!",
      };

    case SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        formBuilderStatus: null,
        formBuilderErrorMessage: payload,
      };
    case SaveFormBuilderActions.SAVE_FORMBUILDER_STATUS:
      return {
        ...state,
        formBuilderStatus: 0,
        formBuilderErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        formBuilderData: null,
      };
    case GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formBuilderData: payload.data,
        formBuilderDataStatus: payload.status,
        formBuilderErrorMessage: null,
      };
    case GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        formBuilderData: null,
      };
    case GetBreachDetailsActions.FETCH_FORMBUILDER_DETAILS_STATUS:
      return {
        ...state,
        formBuilderData: null,
      };

    //GET BY ID////////
    case GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveInvestigationTempList: payload.data,
      };
    case GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_STATUS:
      return {
        ...state,
        isLoading: true,
        BreachIncidentDataErrorMessage: null,
        modalActive: false,
        BreachIncidentData: null,
      };
    case GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachIncidentData: payload.data,
        BreachIncidentDataStatus: payload.status,
        BreachIncidentDataErrorMessage: null,
      };
    case GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        BreachIncidentDataErrorMessage: payload,
        BreachIncidentData: null,
      };
    case GetBreachIncidentDetailsActions.FETCH_BREACH_INCIDENT_DETAILS_STATUS:
      return {
        ...state,
        BreachIncidentData: null,
      };
    //GET BY ID////////

    ///Active DATA DISPOSAL
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        ActiveFormBuilderList: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveFormBuilderList: payload.data,
        formBuilderErrorMessage: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        ActiveFormBuilderList: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_STATUS:
      return {
        ...state,
        ActiveFormBuilderList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetformBuilder: null,
        formBuilderStatus: null,
        formBuilderSuccessMessage: null,
        GetformBuilderStatus: null,
        formBuilderData: null,
        SaveBreachData: null,
        CopyDetails: false,
        TemplateSelected: false,
        TemplateFormData: null,
        BreachIncidentData: null,
        ViewBreachData: null,
        formbuilderResponse: null,
      };

    case CopyFormDetailsActions.COPY_FORM_DETAILS:
      // debugger;
      return {
        ...state,
        CopyDetails: true,
        isDraft: payload,
      };

    case TemplateDetailsActions.TEMPLATE_FORM_DETAILS:
      return {
        ...state,
        TemplateSelected: true,
      };

    case SaveFormDetailsProps.SAVE_FORM_DETAILS:
      return {
        ...state,
        TemplateFormData: payload,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */
    case SaveBreachActions.SAVE_SURVEY:
      return {
        ...state,
        isLoading: true,
        breachErrorMessage: null,
        modalActive: false,
      };
    case SaveBreachActions.SAVE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveBreachData: payload.data,
        breachStatus: payload.status,
        breachErrorMessage: null,
        breachSuccessMessage: "Breach added successfully!",
      };

    case SaveBreachActions.SAVE_SURVEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        breachStatus: null,
        breachErrorMessage: payload,
        SaveBreachData: null,
      };
    case SaveBreachActions.SAVE_SURVEY_STATUS:
      return {
        ...state,
        breachStatus: 0,
        breachErrorMessage: null,
      };

    //Save Breach Incident

    case SaveBreachIncidentActions.SAVE_BREACH_INCIDENT:
      return {
        ...state,
        isLoading: true,
        breachIncidentErrorMessage: null,
        modalActive: false,
      };
    case SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveBreachIncidentData: payload.data,
        breachIncidentStatus: payload.status,
        breachIncidentErrorMessage: null,
        breachIncidentSuccessMessage: "Breach added successfully!",
      };

    case SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        breachIncidentStatus: null,
        breachIncidentErrorMessage: payload,
        SaveBreachIncidentData: null,
      };
    case SaveBreachIncidentActions.SAVE_BREACH_INCIDENT_STATUS:
      return {
        ...state,
        breachStatus: 0,
        breachErrorMessage: null,
      };

    case GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
      };
    case GetBreachReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formbuilderResponse: payload,
        formBuilderErrorMessage: null,
        
      };

    //VIEW BY ID////////////

    // GET  BY ID
    case ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS:
      return {
        ...state,
        isLoading: true,
        ViewBreachErrorMessage: null,
        modalActive: false,
        ViewBreachData: null,
      };
    case ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ViewBreachData: payload.data,
        ViewBreachStatus: payload.status,
        ViewBreachErrorMessage: null,
      };
    case ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        ViewBreachErrorMessage: payload,
        ViewBreachData: null,
      };
    case ViewBreachDetailsActions.VIEW_FORMBUILDER_DETAILS_STATUS:
      return {
        ...state,
        ViewBreachData: null,
      };

    //Save Breach Setup

    case SaveBreachSetupActions.SAVE_SETUP:
      return {
        ...state,
        isLoading: true,
        breachSetupErrorMessage: null,
        modalActive: false,
      };
    case SaveBreachSetupActions.SAVE_SETUP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveBreachSetupData: payload.data,
        breachSetupStatus: payload.status,
        breachSetupErrorMessage: null,
        breachSetupSuccessMessage: "Breach Setup added successfully!",
      };

    case SaveBreachSetupActions.SAVE_SETUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        breachSetupStatus: null,
        breachSetupErrorMessage: payload,
        SaveBreachSetupData: null,
      };
    case SaveBreachSetupActions.SAVE_SETUP_STATUS:
      return {
        ...state,
        breachSetupStatus: 0,
        breachSetupErrorMessage: null,
      };

    //Get Breach Setup

    case GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS:
      return {
        ...state,
        isLoading: true,
        FetchBreachStatusErrorMessage: null,
        modalActive: false,
        SetupBreachData: null,
      };
    case GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SetupBreachData: payload.data,
        SetupBreachStatus: payload.status,
        FetchBreachStatusErrorMessage: null,
      };
    case GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        FetchBreachStatusErrorMessage: payload,
        SetupBreachData: null,
      };
    case GetBreachSetupDetailsActions.FETCH_BREACH_SETUP_DETAILS_STATUS:
      return {
        ...state,
        SetupBreachData: null,
      };

    default:
      return state;
  }
}
