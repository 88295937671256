import {
  GetScanDomainDetailsActions,
  saveScanDomainActions,
} from "./actions";

const initState = {
  cookieSuccessMessage: null,
  cookietHeadErrorMessage: null,
  cookieHeadStatus: null,
  cookieHeadMaster: [],
  cookieHeadData: null,
  ActivecookieHeadList: [],
  cookiesDetails:null,
  domainStatus:null,
  error:null
};

export default function CookiesList(state = initState, { type, payload }) {
  switch (type) {
    case saveScanDomainActions.SCAN_DOMAIN:
      return {
        ...state,
        isLoading: true,
        cookietHeadErrorMessage: null,
        modalActive: false
      };
    case saveScanDomainActions.SCAN_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookieHeadStatus: payload.status,
        cookietHeadErrorMessage: null,
        cookieSuccessMessage: "Cookies added successfully!"
      };

    case saveScanDomainActions.SCAN_DOMAIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        cookieHeadStatus: null,
        cookietHeadErrorMessage: payload
      };
    case saveScanDomainActions.SCAN_DOMAIN_STATUS:
      return {
        ...state,
        cookieHeadStatus: 0,
        cookietHeadErrorMessage: null
      };
    case GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: null,
        modalActive: false
      };
    case GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        cookiesDetails: payload.data,
        error: null,
        // cooe: "Asset Type added successfully!"
      };

    case GetScanDomainDetailsActions.FETCH_SCAN_DOMAIN_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        cookiesDetails: null,
        error: payload
      };
    

    default:
      return state;
  }
}
