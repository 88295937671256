import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Modal, message } from "antd";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Nav from "react-bootstrap/Nav";
import appActions from "../../redux/app/actions";
import CustomLogout from "../CustomLogout";
import IntlMessages from "../../components/utility/intlMessages";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./customTopbar.css";
import { getCompanyDetails } from "../../redux/CompanyMaster/actions";
import { Server_IP, BUCKET_PATH } from "../../redux/Url";
const { Header } = Layout;
const { toggleCollapsed, setActiveLink } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyDetails: null,
    };
  }

  componentDidMount = () => {
    this.getCompanyDetails();
    // thi.setState({
    //   activeNav: this.props.App.linkActive
    // });
  };

  getCompanyDetails = async () => {
    await this.props
      .getCompanyDetails({
        access_token: this.props.access_token,
        id:
          this.props.dashboardView.credentials.data &&
          this.props.dashboardView.credentials.data.data.user &&
          this.props.dashboardView.credentials.data.data.user.companyId,
      })
      .then((res) => {
        if (res && res.status === 200) {
          this.setState({
            CompanyDetails:
              this.props.CompanyMaster.companyData != null
                ? this.props.CompanyMaster.companyData.companyDetails
                : null,
          });
        } else {
          // this.setState({
          //   modalVisibile: true,
          // });
        }
      });
  };

  setActiveLink = (linkName) => {
    this.props.setActiveLink(linkName);
  };

  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 60,
      top: 0,
    };

    if (this.state.redirectToSignin === true) {
      return <Redirect to={"/signin"} />;
    }

    let dashboardView = this.props.dashboardView.dashboardStatus
      ? "sidebar-logos dashboard-view"
      : "sidebar-logos";
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          {this.props.App.view === "MobileView" ? (
            <div className="isoLeft">
              <button
                className={
                  collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                }
                style={{ color: customizedTheme.textColor }}
                onClick={toggleCollapsed}
              />
            </div>
          ) : (
            <div
              className={
                dashboardView != null && dashboardView != undefined
                  ? dashboardView
                  : ""
              }
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: 55,
                alignItems: "start",
              }}
            >
              {this.state.CompanyDetails &&
              this.state.CompanyDetails.subscriptionPlansDto.type === 1 ? (
                <div
                  className={dashboardView}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    height: 55,
                    alignItems: "start",
                  }}
                >
                  <Link to="/menu">
                    <div
                      className="dpo-brand-logo"
                      style={{
                        background: "#fff",
                        textAlign: "center",
                        boxShadow: "-11px 17px 11px 0px #b5b5b526",
                        width: 200,
                      }}
                    >
                      {this.state.CompanyDetails.logo != null &&
                        this.state.CompanyDetails.logo.path !== null
                       ? (
                        <img
                          src={this.state.CompanyDetails.logo.path}
                          alt=""
                          className={
                            this.props.dashboardView.dashboardStatus == true
                              ? "prof-image2"
                              : "prof-image1"
                          }
                        />
                      ) : (
                        <img
                          src={require("../../image/dpo-images/Privezi-Logo.png")}
                          style={{
                            maxHeight: "100%",
                            width: "90%",
                          }}
                        />
                      )}
                    </div>
                  </Link>
                  {/* <div className="demo-logo" style={{ width: 200 }}>
                    <img
                      src={
                        this.state.CompanyDetails.logo != null
                          ? this.state.CompanyDetails.logo.path
                          : require("../../image/dpo-images/demo-img.png")
                      }
                      alt=""
                      className={
                        this.props.dashboardView.dashboardStatus == true
                          ? "prof-image2"
                          : "prof-image1"
                      }
                    />
                  </div> */}
                </div>
              ) : this.state.CompanyDetails &&
                this.state.CompanyDetails.subscriptionPlansDto.type === 0 ? (
                <Link to="/menu">
                  <div
                    className="dpo-brand-logo"
                    style={{
                      background: "#fff",
                      textAlign: "center",
                      width: 200,
                      borderRight: "0px solid #fff",
                      boxShadow: "#07182100 -18px -20px 12px 0px",
                    }}
                  >
                    <img
                      src={
                        this.state.CompanyDetails.logo != null
                          ? this.state.CompanyDetails.logo.path
                          : require("../../image/dpo-images/demo-img.png")
                      }
                      style={{
                        maxHeight: "100%",
                        width: "100%",
                        position: "relative",
                        bottom: 2,
                      }}
                    />
                  </div>
                </Link>
              ) : (
                <div
                  className={dashboardView}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    height: 55,
                    alignItems: "start",
                  }}
                >
                  <Link to="/menu">
                    <div
                      className="dpo-brand-logo"
                      style={{
                        background: "#fff",
                        textAlign: "center",
                        width: 200,
                        boxShadow: "#07182100 -18px -20px 12px 0px",
                      }}
                    >
                      <img
                        src={require("../../image/dpo-images/Privezi-Logo.png")}
                        style={{
                          maxHeight: "100%",
                          width: "90%",
                        }}
                      />
                    </div>
                  </Link>
                  {/* <div className="demo-logo">
                    <img
                      src={require("../../image/dpo-images/demo-img.png")}
                      style={{ maxHeight: "100%", width: "75%" }}
                    />
                  </div> */}
                </div>
              )}
            </div>
          )}
          {this.props.dashboardView.dashboardStatus === true ? (
            <div className="dpo-navbar">
              {this.props.App.view != "MobileView" ? (
                <Nav variant="pills" activeKey={this.props.App.linkActive}>
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap.ASSET_MASTER &&
                    this.props.PermissionData.permissionsMap.ASSET_MASTER
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          href=""
                          eventKey="ASSET"
                          className="ant-btn btn-curv ant-btn-button"
                          onClick={() => this.setActiveLink("ASSET")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .ASSET_MASTER &&
                              this.props.PermissionData.permissionsMap
                                .ASSET_MASTER.viewModule
                                ? "/dashboard/assetMaster"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.Asset"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}

                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap.PROCESS_REGISTER &&
                    this.props.PermissionData.permissionsMap.PROCESS_REGISTER
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          href=""
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="ROPA"
                          onClick={() => this.setActiveLink("ROPA")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .ROPA_MASTER &&
                              this.props.PermissionData.permissionsMap
                                .ROPA_MASTER.viewModule
                                ? "/dashboard/ropaMasterListing"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.Ropa"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap.ASSESSMENT &&
                    this.props.PermissionData.permissionsMap.ASSESSMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="ASSESSMENT"
                          onClick={() => this.setActiveLink("ASSESSMENT")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .ASSESSMENTS &&
                              this.props.PermissionData.permissionsMap
                                .ASSESSMENTS.viewModule
                                ? "/dashboard/SurveyListing"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.Assessment"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap
                      .BREACH_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.BREACH_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="INCIDENT"
                          onClick={() => this.setActiveLink("INCIDENT")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .BREACH_INCIDENT_MANAGEMENT &&
                              this.props.PermissionData.permissionsMap
                                .BREACH_INCIDENT_MANAGEMENT.viewModule
                                ? "/dashboard/breachIncidentRegister"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.Incident"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap
                      .REQUEST_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.REQUEST_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className={`ant-btn btn-curv ant-btn-button ${
                            this.props.App.linkActive === "DATARIGHTS"
                              ? "active"
                              : ""
                          }`}
                          eventKey="DATARIGHTS"
                          onClick={() => this.setActiveLink("DATARIGHTS")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .REQUEST_MANAGEMENT &&
                              this.props.PermissionData.permissionsMap
                                .REQUEST_MANAGEMENT.viewModule
                                ? "/dashboard/requestMaster"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.DataSubjectRights"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap
                      .CONSENT_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.CONSENT_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="CONSENT"
                          onClick={() => this.setActiveLink("CONSENT")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .CONSENT_CAPTURE_POINTS &&
                              this.props.PermissionData.permissionsMap
                                .CONSENT_CAPTURE_POINTS.viewModule
                                ? "/dashboard/consentPointsListing"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.ConsentManagement"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap
                      .COOKIE_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.COOKIE_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="COOKIE"
                          onClick={() => this.setActiveLink("COOKIE")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .DOMAINS &&
                              this.props.PermissionData.permissionsMap.DOMAINS
                                .viewModule
                                ? "/dashboard/DomainListing"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.CookieManagement"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap.RISK_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.RISK_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="RISKMANAGEMENT"
                          onClick={() => this.setActiveLink("RISKMANAGEMENT")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .RISK_REGISTER &&
                              this.props.PermissionData.permissionsMap
                                .RISK_REGISTER.viewModule
                                ? "/dashboard/RiskRegisterListing"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.risk"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {this.props.PermissionData.permissionsMap &&
                    this.props.PermissionData.permissionsMap
                      .VENDOR_MANAGEMENT &&
                    this.props.PermissionData.permissionsMap.VENDOR_MANAGEMENT
                      .viewModule && (
                      <Nav.Item>
                        <Nav.Link
                          className="ant-btn btn-curv ant-btn-button"
                          eventKey="VENDORMANAGEMENT"
                          onClick={() => this.setActiveLink("VENDORMANAGEMENT")}
                        >
                          <Link
                            to={
                              this.props.PermissionData.permissionsMap &&
                              this.props.PermissionData.permissionsMap
                                .VENDOR_MASTER &&
                              this.props.PermissionData.permissionsMap
                                .VENDOR_MASTER.viewModule
                                ? "/dashboard/VendorDashboard"
                                : "/dashboard/accessControl"
                            }
                          >
                            <IntlMessages id={"sidebar.vendor"} />
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  {/* <Nav.Item>
                    <Nav.Link
                      className="ant-btn btn-curv ant-btn-button"
                      eventKey="ADMINISTRATION"
                      onClick={() => this.setActiveLink("ADMINISTRATION")}
                    >
                      <Link to="/dashboard/userMaster">
                        <IntlMessages id={"sidebar.Administration"} />
                      </Link>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle>Menu</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.props.PermissionData.permissionsMap &&
                      `this.props.PermissionData.permissionsMap.ASSETS_&_DATA_INVENTORY` &&
                      `this.props.PermissionData.permissionsMap.ASSETS_&_DATA_INVENTORY.viewModule` && (
                        <Dropdown.Item
                          href="/dashboard/assetMaster"
                          onClick={() => this.setActiveLink("ASSET")}
                        >
                          ASSET
                        </Dropdown.Item>
                      )}

                    {this.props.PermissionData.permissionsMap &&
                      this.props.PermissionData.permissionsMap
                        .PROCESS_REGISTER &&
                      this.props.PermissionData.permissionsMap.PROCESS_REGISTER
                        .viewModule && (
                        <Dropdown.Item
                          href="/dashboard/ropaMasterListing"
                          onClick={() => this.setActiveLink("ROPA")}
                        >
                          ROPA
                        </Dropdown.Item>
                      )}

                    {this.props.PermissionData.permissionsMap &&
                      this.props.PermissionData.permissionsMap.ASSESSMENT &&
                      this.props.PermissionData.permissionsMap.ASSESSMENT
                        .viewModule && (
                        <Dropdown.Item
                          href="/dashboard/assesment"
                          onClick={() => this.setActiveLink("ASSESSMENT")}
                        >
                          ASSESSMENT
                        </Dropdown.Item>
                      )}

                    {this.props.PermissionData.permissionsMap &&
                      this.props.PermissionData.permissionsMap
                        .BREACH_MANAGEMENT &&
                      this.props.PermissionData.permissionsMap.BREACH_MANAGEMENT
                        .viewModule && (
                        <Dropdown.Item href="/menu">INCIDENT</Dropdown.Item>
                      )}

                    {this.props.PermissionData.permissionsMap &&
                      this.props.PermissionData.permissionsMap
                        .REQUEST_MANAGEMENT &&
                      this.props.PermissionData.permissionsMap
                        .REQUEST_MANAGEMENT.viewModule && (
                        <Dropdown.Item
                          href="/dashboard/requestMaster"
                          onClick={() => this.setActiveLink("DATARIGHTS")}
                        >
                          DATA SUBJECT RIGHTS
                        </Dropdown.Item>
                      )}

                    {this.props.PermissionData.permissionsMap &&
                      this.props.PermissionData.permissionsMap
                        .CONSENT_CAPTURE_POINTS &&
                      this.props.PermissionData.permissionsMap
                        .CONSENT_CAPTURE_POINTS.viewModule && (
                        <Dropdown.Item href="/menu">
                          CONSENT MANAGEMENT
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item
                      href="/dashboard/userMaster"
                      onClick={() => this.setActiveLink("ADMINISTRATION")}
                    >
                      ADMINISTRATION
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          ) : (
            ""
          )}

          <ul className="isoRight">
            {/* <li style={{ width: "max-content", cursor: "default" }}>
              <small>
                {" "}
                <IntlMessages id={"sidebar.Welcome"} />,
              </small>
              <br />
              <p className="Top-username">
                {(this.props.dashboardView.profileData &&
                  this.props.dashboardView.profileData.userFirstName) +
                  " " +
                  (this.props.dashboardView.profileData &&
                    this.props.dashboardView.profileData.userLastName)}
              </p>
            </li> */}
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    access_token: state.Auth.idToken,
    dashboardView: state.Auth,
    App: state.App,
    PermissionData: state.Auth.PermissionData,
    LogoutFlag: state.logoutFlag,
    CompanyMaster: state.CompanyMaster,
  }),
  { toggleCollapsed, getCompanyDetails, setActiveLink }
)(Topbar);
