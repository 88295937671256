import {
  saveRopaActions,
  deleteRopaActions,
  GetRopaDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRopaActions,
  saveDocActions,
  GetRopaActivityCityActions
} from "./actions";

const initState = {
  ropaSuccessMessage: null,
  ropaErrorMessage: null,
  RopaStatus: null,
  RopaMaster: [],
  RopaData: null,
  ActiveRopaList: null,
  DocStatus: null,
  docErrorMessage: null,
  docSuccessMessage: null,
  activityCityStatus: null,
  activityCityErrorMessage: null,
  activityCitySuccessMessage: null,
  ActivityCity: []
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRopaActions.SAVE_ROPA:
      return {
        ...state,
        isLoading: true,
        ropaErrorMessage: null,
        modalActive: false,
      };
    case saveRopaActions.SAVE_ROPA_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RopaStatus: payload.status,
        ropaErrorMessage: null,
        ropaSuccessMessage: "Ropa added successfully!",
      };

    case saveRopaActions.SAVE_ROPA_FAILURE:
      return {
        ...state,
        isLoading: false,
        RopaStatus: null,
        ropaErrorMessage: payload,
      };
    case saveRopaActions.SAVE_ROPA_STATUS:
      return {
        ...state,
        RopaStatus: 0,
        ropaErrorMessage: null,
      };

    // Delete Ropa Type

    case deleteRopaActions.DELETE_ROPA:
      return {
        ...state,
        isLoading: true,
        ropaErrorMessage: null,
        modalActive: false,
      };
    case deleteRopaActions.DELETE_ROPA_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RopaStatus: payload.status,
        ropaErrorMessage: null,
        ropaSuccessMessage: "Ropa Type Deleted successfully!",
      };

    case deleteRopaActions.DELETE_ROPA_FAILURE:
      return {
        ...state,
        isLoading: false,
        RopaStatus: null,
        ropaErrorMessage: payload,
      };
    case deleteRopaActions.DELETE_ROPA_STATUS:
      return {
        ...state,
        RopaStatus: 0,
        ropaErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRopaDetailsActions.FETCH_ROPA_DETAILS:
      return {
        ...state,
        isLoading: true,
        ropaErrorMessage: null,
        modalActive: false,
        RopaData: null,
      };
    case GetRopaDetailsActions.FETCH_ROPA_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RopaData: payload.data,
        RopaDataStatus: payload.status,
        ropaErrorMessage: null,
      };
    case GetRopaDetailsActions.FETCH_ROPA_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        ropaErrorMessage: payload,
        RopaData: null,
      };
    case GetRopaDetailsActions.FETCH_ROPA_DETAILS_STATUS:
      return {
        ...state,
        RopaData: null,
      };
    ///Active ropas
    case GetActiveRopaActions.FETCH_ACTIVE_ROPAS:
      return {
        ...state,
        isLoading: true,
        ropaErrorMessage: null,
        modalActive: false,
        ActiveRopaList: null,
      };
    case GetActiveRopaActions.FETCH_ACTIVE_ROPAS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRopaList: payload.data,
        ropaErrorMessage: null,
      };
    case GetActiveRopaActions.FETCH_ACTIVE_ROPAS_REJECTED:
      return {
        ...state,
        isLoading: false,
        ropaErrorMessage: payload,
        ActiveRopaList: null,
      };
    case GetActiveRopaActions.FETCH_ACTIVE_ROPAS_STATUS:
      return {
        ...state,
        ActiveRopaList: null,
      };
    // clear ropaType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRopa: null,
        RopaStatus: null,
        ropaSuccessMessage: null,
        GetRopaStatus: null,
        RopaData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */
    case saveDocActions.SAVE_DOC:
      return {
        ...state,
        isLoading: true,
        docErrorMessage: null,
        modalActive: false,
      };
    case saveDocActions.SAVE_DOC_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DocStatus: payload.status,
        docErrorMessage: null,
        docSuccessMessage: "Doc added successfully!",
      };

    case saveDocActions.SAVE_DOC_FAILURE:
      return {
        ...state,
        isLoading: false,
        DocStatus: null,
        docErrorMessage: payload,
      };
    case saveDocActions.SAVE_DOC_STATUS:
      return {
        ...state,
        DocStatus: 0,
        docErrorMessage: null,
      };
    /* */
    case GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY:
      return {
        ...state,
        isLoading: true,
        activityCityErrorMessage: null,
        modalActive: false,
      };
    case GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        activityCityStatus: payload.status,
        ActivityCity: payload.data,
        activityCityErrorMessage: null,
        activityCitySuccessMessage: "Activity City fetched successfully!",
      };

    case GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        activityCityStatus: null,
        activityCityErrorMessage: payload,
      };
    case GetRopaActivityCityActions.GET_ROPA_ACTIVITY_CITY_STATUS:
      return {
        ...state,
        activityCityStatus: 0,
        activityCityErrorMessage: null,
      };
    default:
      return state;
  }
}
