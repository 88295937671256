
import {
  SaveCompanyActions,
  GetCompanyDetailsActions,
  GetCompanyUsersDetailsActions,
  ClearFormDetailsActions,
  GetActiveCompanyActions
} from "./actions";

const initState = {
  companySuccessMessage: null,
  errorMessage: null,
  companyStatus: null,
  companyMaster: [],
  companyData: null,
  ActivecompanyList: null,
  ActiveCompanyUsersList: null
};

export default function Deaprtment(state = initState, { type, payload }) {
  switch (type) {
    case SaveCompanyActions.SAVE_company:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false
      };
    case SaveCompanyActions.SAVE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        companyStatus: payload.status,
        errorMessage: null
        // companySuccessMessage: "company added successfully!"
      };

    case SaveCompanyActions.SAVE_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        companyStatus: null,
        errorMessage: payload
      };
    case SaveCompanyActions.SAVE_COMPANY_STATUS:
      return {
        ...state,
        companyStatus: 0,
        errorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetCompanyDetailsActions.FETCH_COMPANY_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        companyData: null
      };
    case GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        companyData: payload.data,
        companyDataStatus: payload.status,
        errorMessage: null
      };
    case GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        companyData: null
      };
    case GetCompanyDetailsActions.FETCH_COMPANY_DETAILS_STATUS:
      return {
        ...state,
        companyData: null
      };

      

      case GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        ActiveCompanyUsersList: null
      };
    case GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveCompanyUsersList: payload.data,
        companyUserStatus: payload.status,
        errorMessage: null
      };
    case GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        ActiveCompanyUsersList: null,
      };
    case GetCompanyUsersDetailsActions.FETCH_COMPANY_USER_DETAILS_STATUS:
      return {
        ...state,
        ActiveCompanyUsersList: null
      };
    default:
      return state;
  }
}
