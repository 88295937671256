import axios from "axios";
import { Location_Master_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();
var err;

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

export function logout() {
  return {
    type: cityMasterListActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

//City Master Start
/////////////////////////////////////////////////////////////////////////////////////////

export const cityMasterListActions = {
  FETCH_CITY_MASTER_LIST: "FETCH_CITY_MASTER_LIST",
  FETCH_CITY_FULFILLED: "FETCH_CITY_FULFILLED",
  FETCH_CITY_REJECTED: "FETCH_CITY_REJECTED",
  SET_CITY_ERROR: "SET_CITY_ERROR",
  LOGOUT: "LOGOUT",
};

export function getAllCityMaster(params) {
  //
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify(data),
      url: `${Location_Master_URL}/Apc_View/Amc_46/getAllCity`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(cityMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(cityMasterListSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(cityMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(cityMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(cityMasterListError(err));
              break;
            case 500:
              dispatch(cityMasterListError("Server Error"));
              break;
            default:
              dispatch(cityMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function cityMasterListRequest() {
  return {
    type: cityMasterListActions.FETCH_CITY_MASTER_LIST,
    isPosting: true,
  };
}
export function cityMasterListError(error) {
  return {
    type: cityMasterListActions.FETCH_CITY_REJECTED,
    payload: error,
  };
}

export function cityMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: cityMasterListActions.FETCH_CITY_FULFILLED,
      payload: props,
    });
  };
}

export function cityMasterListStatus() {
  return (dispatch) => dispatch(setcityMasterListStatus());
}
export function setcityMasterListStatus() {
  return {
    type: cityMasterListActions.SET_CITY_ERROR,
  };
}

//City Master End
/////////////////////////////////////////////////////////////////////////////////////////

//////////////////Get city by city ID////////////
export const CityByCityIdActions = {
  FETCH_CITY_NAME: "FETCH_CITY_NAME",
  FETCH_CITY_NAME_FULFILLED: "FETCH_CITY_NAME_FULFILLED",
  FETCH_CITY_NAME_REJECTED: "FETCH_CITY_NAME_REJECTED",
  SET_CITY_NAME_ERROR: "SET_CITY_NAME_ERROR",
  LOGOUT: "LOGOUT",
};

export function getCityByCityId(params) {
  //
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify(data),
      url:
        `${Location_Master_URL}/Apc_View/Amc_46/findByCityId/` + params.cityID,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(CityByCityIdRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(CityByCityIdSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(CityByCityIdError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CityByCityIdError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CityByCityIdError(err));
              break;
            case 500:
              dispatch(CityByCityIdError("Server Error"));
              break;
            default:
              dispatch(CityByCityIdError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function CityByCityIdRequest() {
  return {
    type: CityByCityIdActions.FETCH_CITY_NAME,
    isPosting: true,
  };
}
export function CityByCityIdError(error) {
  return {
    type: CityByCityIdActions.FETCH_CITY_NAME_REJECTED,
    payload: error,
  };
}

export function CityByCityIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: CityByCityIdActions.FETCH_CITY_NAME_FULFILLED,
      payload: props,
    });
  };
}

export function CityByCityIdStatus() {
  return (dispatch) => dispatch(setCityByCityIdStatus());
}
export function setCityByCityIdStatus() {
  return {
    type: cityMasterListActions.SET_CITY_NAME_ERROR,
  };
}

// /Continent Master Start
/////////////////////////////////////////////////////////////////////////////////////////

export const stateMasterListActions = {
  FETCH_STATE_MASTER_LIST: "FETCH_STATE_MASTER_LIST",
  FETCH_STATE_FULFILLED: "FETCH_STATE_FULFILLED",
  FETCH_STATE_REJECTED: "FETCH_STATE_REJECTED",
  SET_STATE_ERROR: "SET_STATE_ERROR",
  LOGOUT: "LOGOUT",
};

export function getAllState(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${Location_Master_URL}/Apc_View/Amc_46/getAllState`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(stateMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(stateMasterListSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(stateMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(stateMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(stateMasterListError(err));
              break;
            case 500:
              dispatch(stateMasterListError("Server Error"));
              break;
            default:
              dispatch(stateMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function stateMasterListRequest() {
  return {
    type: stateMasterListActions.FETCH_STATE_MASTER_LIST,
    isPosting: true,
  };
}
export function stateMasterListError(error) {
  return {
    type: stateMasterListActions.FETCH_STATE_REJECTED,
    payload: error,
  };
}

export function stateMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: stateMasterListActions.FETCH_STATE_FULFILLED,
      payload: props,
    });
  };
}

export function stateMasterListStatus() {
  return (dispatch) => dispatch(setStateMasterListStatus());
}
export function setStateMasterListStatus() {
  return {
    type: stateMasterListActions.SET_STATE_ERROR,
  };
}

//State Master End

//Country Master Start
/////////////////////////////////////////////////////////////////////////////////////////

export const countryMasterListActions = {
  FETCH_COUNTRY_MASTER_LIST: "FETCH_COUNTRY_MASTER_LIST",
  FETCH_COUNTRY_FULFILLED: "FETCH_COUNTRY_FULFILLED",
  FETCH_COUNTRY_REJECTED: "FETCH_COUNTRY_REJECTED",
  SET_COUNTRY_ERROR: "SET_COUNTRY_ERROR",
  LOGOUT: "LOGOUT",
};

export function getAllCountryMaster(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify(data),
      url: `${Location_Master_URL}/getAllCountry`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(countryMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(countryMasterListSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(countryMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(countryMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(countryMasterListError(err));
              break;
            case 500:
              dispatch(countryMasterListError("Server Error"));
              break;
            default:
              dispatch(countryMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function countryMasterListRequest() {
  return {
    type: countryMasterListActions.FETCH_COUNTRY_MASTER_LIST,
    isPosting: true,
  };
}
export function countryMasterListError(error) {
  return {
    type: countryMasterListActions.FETCH_COUNTRY_REJECTED,
    payload: error,
  };
}

export function countryMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: countryMasterListActions.FETCH_COUNTRY_FULFILLED,
      payload: props,
    });
  };
}

export function countryMasterListStatus() {
  return (dispatch) => dispatch(setcountryMasterListStatus());
}
export function setcountryMasterListStatus() {
  return {
    type: countryMasterListActions.SET_COUNTRY_ERROR,
  };
}

//Country Master End
/////////////////////////////////////////////////////////////////////////////////////////

////////////////////Country BY ID/////////////////////////

export const CountryByIDActions = {
  FETCH_COUNTRY_BY_ID: "FETCH_COUNTRY_BY_ID",
  FETCH_COUNTRY_BY_ID_FULFILLED: "FETCH_COUNTRY_BY_ID_FULFILLED",
  FETCH_COUNTRY_BY_ID_REJECTED: "FETCH_COUNTRY_BY_ID_REJECTED",
  SET_COUNTRY_BY_ID_ERROR: "SET_COUNTRY_BY_ID_ERROR",
  LOGOUT: "LOGOUT",
};

export function getCountryById(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify(data),
      url: `${Location_Master_URL}/getCityByCountryId/` + params.countryId,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(CountryByIdRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(CountryByIdSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(CountryByIdError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CountryByIdError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(CountryByIdError(err));
              break;
            case 500:
              dispatch(CountryByIdError("Server Error"));
              break;
            default:
              dispatch(CountryByIdError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function CountryByIdRequest() {
  return {
    type: CountryByIDActions.FETCH_COUNTRY_BY_ID,
    isPosting: true,
  };
}
export function CountryByIdError(error) {
  return {
    type: CountryByIDActions.FETCH_COUNTRY_BY_ID_REJECTED,
    payload: error,
  };
}

export function CountryByIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: CountryByIDActions.FETCH_COUNTRY_BY_ID_FULFILLED,
      payload: props,
    });
  };
}

export function CountryByIdStatus() {
  return (dispatch) => dispatch(setCountryByIdStatus());
}
export function setCountryByIdStatus() {
  return {
    type: CountryByIDActions.SET_COUNTRY_BY_ID_ERROR,
  };
}

// PinCode master start

////////////////////PinCode BY ID/////////////////////////

export const PinCodeByCityIDActions = {
  FETCH_PINCODE_BY_CITYID: "FETCH_PINCODE_BY_CITYID",
  FETCH_PINCODE_BY_CITYID_FULFILLED: "FETCH_PINCODE_BY_CITYID_FULFILLED",
  FETCH_PINCODE_BY_CITYID_REJECTED: "FETCH_PINCODE_BY_CITYID_REJECTED",
  SET_PINCODE_BY_CITYID_ERROR: "SET_PINCODE_BY_CITYID_ERROR",
  LOGOUT: "LOGOUT",
};

export function getPincodeByCityId(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${Location_Master_URL}/getPincodeByCityId?cityId=` + params.cityId,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(PincodeByCityIdRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(PincodeByCityIdSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(PincodeByCityIdError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(PincodeByCityIdError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(PincodeByCityIdError(err));
              break;
            case 500:
              dispatch(PincodeByCityIdError("Server Error"));
              break;
            default:
              dispatch(PincodeByCityIdError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function PincodeByCityIdRequest() {
  return {
    type: PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID,
    isPosting: true,
  };
}
export function PincodeByCityIdError(error) {
  return {
    type: PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID_REJECTED,
    payload: error,
  };
}

export function PincodeByCityIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: PinCodeByCityIDActions.FETCH_PINCODE_BY_CITYID_FULFILLED,
      payload: props,
    });
  };
}

export function PincodeByCityIdStatus() {
  return (dispatch) => dispatch(setPincodeByCityIdStatus());
}
export function setPincodeByCityIdStatus() {
  return {
    type: PinCodeByCityIDActions.SET_PINCODE_BY_CITYID_ERROR,
  };
}

// pincode master end
//Continent Master Start
/////////////////////////////////////////////////////////////////////////////////////////

export const continentMasterListActions = {
  FETCH_CONTINENT_MASTER_LIST: "FETCH_CONTINENT_MASTER_LIST",
  FETCH_CONTINENT_FULFILLED: "FETCH_CONTINENT_FULFILLED",
  FETCH_CONTINENT_REJECTED: "FETCH_CONTINENT_REJECTED",
  SET_CONTINENT_ERROR: "SET_CONTINENT_ERROR",
  LOGOUT: "LOGOUT",
};

export function getAllContinent(params) {
  return (dispatch) =>
    axios({
      method: "get",
      // data: JSON.stringify(data),
      url: `${Location_Master_URL}/getAllContinent`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(continentMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(continentMasterListSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(continentMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(continentMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(continentMasterListError(err));
              break;
            case 500:
              dispatch(continentMasterListError("Server Error"));
              break;
            default:
              dispatch(continentMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function continentMasterListRequest() {
  return {
    type: continentMasterListActions.FETCH_CONTINENT_MASTER_LIST,
    isPosting: true,
  };
}
export function continentMasterListError(error) {
  return {
    type: continentMasterListActions.FETCH_CONTINENT_REJECTED,
    payload: error,
  };
}

export function continentMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: continentMasterListActions.FETCH_CONTINENT_FULFILLED,
      payload: props,
    });
  };
}

export function continentMasterListStatus() {
  return (dispatch) => dispatch(setcontinentMasterListStatus());
}
export function setcontinentMasterListStatus() {
  return {
    type: continentMasterListActions.SET_CONTINENT_ERROR,
  };
}

//Continent Master End

//SubContinent Master Start
/////////////////////////////////////////////////////////////////////////////////////////

export const SubContinentMasterListActions = {
  FETCH_SUBCONTINENT_MASTER_LIST: "FETCH_SUBCONTINENT_MASTER_LIST",
  FETCH_SUBCONTINENT_FULFILLED: "FETCH_SUBCONTINENT_FULFILLED",
  FETCH_SUBCONTINENT_REJECTED: "FETCH_SUBCONTINENT_REJECTED",
  SET_CONTINENT_ERROR: "SET_CONTINENT_ERROR",
  LOGOUT: "LOGOUT",
};

export function getAllSubContinent(params) {
  return (dispatch) =>
    axios({
      method: "get",
      // data: JSON.stringify(data),
      url: `${Location_Master_URL}/getAllSubContinent`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(SubContinentMasterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SubContinentMasterListSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SubContinentMasterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SubContinentMasterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SubContinentMasterListError(err));
              break;
            case 500:
              dispatch(SubContinentMasterListError("Server Error"));
              break;
            default:
              dispatch(SubContinentMasterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function SubContinentMasterListRequest() {
  return {
    type: SubContinentMasterListActions.FETCH_SUBCONTINENT_MASTER_LIST,
    isPosting: true,
  };
}
export function SubContinentMasterListError(error) {
  return {
    type: SubContinentMasterListActions.FETCH_SUBCONTINENT_REJECTED,
    payload: error,
  };
}

export function SubContinentMasterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SubContinentMasterListActions.FETCH_SUBCONTINENT_FULFILLED,
      payload: props,
    });
  };
}

export function SubContinentMasterListStatus() {
  return (dispatch) => dispatch(setSubContinentMasterListStatus());
}
export function setSubContinentMasterListStatus() {
  return {
    type: SubContinentMasterListActions.SET_CONTINENT_ERROR,
  };
}

//SubContinent Master End
