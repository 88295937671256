import {
  SaveBreachTypeActions,
  GetBreachTypeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveBreachTypeActions,
} from "./actions";

const initState = {
  breachSuccessMessage: null,
  breachErrorMessage: null,
  BreachTypeStatus: null,
  BreachTypeMaster: [],
  BreachTypeData: null,
  ActiveBreachTypeList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveBreachTypeActions.SAVE_BREACH_TYPE:
      return {
        ...state,
        isLoading: true,
        breachErrorMessage: null,
        modalActive: false,
      };
    case SaveBreachTypeActions.SAVE_BREACH_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachTypeStatus: payload.status,
        breachErrorMessage: null,
        breachSuccessMessage: "Breach Type added successfully!",
      };

    case SaveBreachTypeActions.SAVE_BREACH_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        BreachTypeStatus: null,
        breachErrorMessage: payload,
      };
    case SaveBreachTypeActions.SAVE_BREACH_TYPE_STATUS:
      return {
        ...state,
        BreachTypeStatus: 0,
        breachErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS:
      return {
        ...state,
        isLoading: true,
        breachErrorMessage: null,
        modalActive: false,
        BreachTypeData: null,
      };
    case GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachTypeData: payload.data,
        BreachTypeDataStatus: payload.status,
        breachErrorMessage: null,
      };
    case GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        breachErrorMessage: payload,
        BreachTypeData: null,
      };
    case GetBreachTypeDetailsActions.FETCH_BREACH_DETAILS_STATUS:
      return {
        ...state,
        BreachTypeData: null,
      };
    ///Active DATA DISPOSAL
    case GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE:
      return {
        ...state,
        isLoading: true,
        breachErrorMessage: null,
        modalActive: false,
        ActiveBreachTypeList: null,
      };
    case GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveBreachTypeList: payload.data,
        breachErrorMessage: null,
      };
    case GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        breachErrorMessage: payload,
        ActiveBreachTypeList: null,
      };
    case GetActiveBreachTypeActions.FETCH_ACTIVE_BREACH_TYPE_STATUS:
      return {
        ...state,
        ActiveBreachTypeList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetBreachType: null,
        BreachTypeStatus: null,
        breachSuccessMessage: null,
        GetBreachTypeStatus: null,
        BreachTypeData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
