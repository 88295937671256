import axios from "axios";
import { SuperAdmin_Service_URL,ConsentManagementService_URL, AssetManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Consent Head

export const saveConsentHeadActions = {
  SAVE_CONSENT_HEAD: "SAVE_CONSENT_HEAD",
  SAVE_CONSENT_HEAD_STATUS: "SAVE_CONSENT_HEAD_STATUS",
  SAVE_CONSENT_HEAD_SUCCESS: "SAVE_CONSENT_HEAD_SUCCESS",
  SAVE_CONSENT_HEAD_FAILURE: "SAVE_CONSENT_HEAD_FAILURE",
};

export function saveConsentHeadRequest() {
  return {
    type: saveConsentHeadActions.SAVE_CONSENT_HEAD,
    isPosting: true,
  };
}

export function saveConsentHeadSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveConsentHeadActions.SAVE_CONSENT_HEAD_SUCCESS,
      payload: props,
    });
  };
}

export function saveConsentHeadFailure(error) {
  return {
    type: saveConsentHeadActions.SAVE_CONSENT_HEAD_FAILURE,
    payload: error,
  };
}

export function saveConsentHeadError(error) {
  return {
    type: saveConsentHeadActions.SAVE_CONSENT_HEAD_FAILURE,
    payload: error,
  };
}

export function saveConsentHead(props) {
  // var value = {
  //   id: props.id || 0,
  //   ConsentHeadName: props.ConsentHeadName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${ConsentManagementService_URL}/Apc_Persist/Amc_56/saveConsentHead`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveConsentHeadRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveConsentHeadSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveConsentHeadError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveConsentPurposeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveConsentPurposeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveConsentPurposeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveConsentPurposeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveConsentPurposeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}
//Delete by id


export const deleteConsentHeadActions = {
  DELETE_CONSENT_HEAD: "DELETE_CONSENT_HEAD",
  DELETE_CONSENT_HEAD_STATUS: "DELETE_CONSENT_HEAD_STATUS",
  DELETE_CONSENT_HEAD_SUCCESS: "DELETE_CONSENT_HEAD_SUCCESS",
  DELETE_CONSENT_HEAD_FAILURE: "DELETE_CONSENT_HEAD_FAILURE",
};

export function deleteConsentHeadRequest() {
  return {
    type: deleteConsentHeadActions.DELETE_CONSENT_HEAD,
    isPosting: true,
  };
}

export function deleteConsentHeadSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteConsentHeadActions.DELETE_CONSENT_HEAD_SUCCESS,
      payload: props,
    });
  };
}

export function deleteConsentHeadFailure(error) {
  return {
    type: deleteConsentHeadActions.DELETE_CONSENT_HEAD_FAILURE,
    payload: error,
  };
}

export function deleteConsentHeadError(error) {
  return {
    type: deleteConsentHeadActions.DELETE_CONSENT_HEAD_FAILURE,
    payload: error,
  };
}

export function deleteConsentHead(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${ConsentManagementService_URL}/Apc_State/Amc_56/deleteConsentHead?id=` +
        params.id,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteConsentHeadRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteConsentHeadSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteConsentHeadError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentHeadError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentHeadError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentHeadError(err));
              break;
            case 500:
              dispatch(deleteConsentHeadError("Server Error"));
              break;
            default:
              dispatch(deleteConsentHeadError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// get  details by id



export const getConsentHeadDetailsActions = {
  FETCH_CONSENT_HEAD_DETAILS: "FETCH_CONSENT_HEAD_DETAILS",
  FETCH_CONSENT_HEAD_DETAILS_STATUS: "FETCH_CONSENT_HEAD_DETAILS_STATUS",
  FETCH_CONSENT_HEAD_DETAILS_FULFILLED: "FETCH_CONSENT_HEAD_DETAILS_FULFILLED",
  FETCH_CONSENT_HEAD_DETAILS_REJECTED: "FETCH_CONSENT_HEAD_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getConsentHeadDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_56/findConsentHeadById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentHeadDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentHeadDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentHeadDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentHeadDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentHeadDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentHeadDetailsError(err));
              break;
            case 500:
              dispatch(getConsentHeadDetailsError("Server Error"));
              break;
            default:
              dispatch(getConsentHeadDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentHeadDetailsRequest() {
  return {
    type: getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS,
    isPosting: true,
  };
}
export function getConsentHeadDetailsError(error) {
  return {
    type: getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_REJECTED,
    payload: error,
  };
}

export function getConsentHeadDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getConsentHeadDetailsStatus() {
  return (dispatch) => dispatch(getConsentHeadDetailsStatusRequest());
}
export function getConsentHeadDetailsStatusRequest() {
  return {
    type: getConsentHeadDetailsActions.FETCH_CONSENT_HEAD_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getConsentHeadDetailsErrorRequest());
}
export function getConsentHeadDetailsErrorRequest() {
  return {
    type: getConsentHeadDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ConsentHeads", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveConsentHeadActions = {
  FETCH_ACTIVE_CONSENT_HEAD: "FETCH_ACTIVE_CONSENT_HEAD",
  FETCH_ACTIVE_CONSENT_HEAD_STATUS: "FETCH_ACTIVE_CONSENT_HEAD_STATUS",
  FETCH_ACTIVE_CONSENT_HEAD_FULFILLED: "FETCH_ACTIVE_CONSENT_HEAD_FULFILLED",
  FETCH_ACTIVE_CONSENT_HEAD_REJECTED: "FETCH_ACTIVE_CONSENT_HEAD_REJECTED",
};

export function getActiveConsentHeadList(params,access_token) {
  return (dispatch) =>
    axios({
      method: "POST",
      data:params,
      url: `${ConsentManagementService_URL}/Apc_View/Amc_56/listing/ConsentHead`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveConsentHeadListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveConsentHeadListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveConsentHeadListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 500:
              dispatch(getActiveConsentHeadListError("Server Error"));
              break;
            default:
              dispatch(getActiveConsentHeadListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveConsentHeadListRequest() {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD,
    isPosting: true,
  };
}
export function getActiveConsentHeadListError(error) {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_REJECTED,
    payload: error,
  };
}

export function getActiveConsentHeadListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveConsentHeadListStatus() {
  return (dispatch) => dispatch(getActiveConsentHeadListStatusRequest());
}
export function getActiveConsentHeadListStatusRequest() {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_CONSENT_HEAD_STATUS,
  };
}
