import axios from "axios";
import { RequestManagement_URL, OTPService_URL, Server_IP, SystemService_URL, SurveyService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveFormBuilderActions = {
  SAVE_FORMBUILDER: "SAVE_FORMBUILDER",
  SAVE_FORMBUILDER_STATUS: "SAVE_FORMBUILDER_STATUS",
  SAVE_FORMBUILDER_SUCCESS: "SAVE_FORMBUILDER_SUCCESS",
  SAVE_FORMBUILDER_FAILURE: "SAVE_FORMBUILDER_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveFormBuilderActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveFormBuilderRequest() {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER,
    isPosting: true,
  };
}

export function SaveFormBuilderSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveFormBuilderActions.SAVE_FORMBUILDER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveFormBuilderFailure(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function SaveFormBuilderError(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function saveFormPages(props, access_token) {
  
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      // url: `${RequestManagement_URL}/Apc_Persist/Amc_30/saveSurveyPages`,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveAssessmentSurveyPages`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveFormBuilderRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveFormBuilderSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveFormBuilderError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

////// COPY DETAILS/////////////
export const CopyFormDetailsActions = {
  COPY_FORM_DETAILS: "COPY_FORM_DETAILS",
};

export function copySurveyDetails(params) {
  // debugger;
  return (dispatch) => dispatch(copyFormDetails(params.isDraft));
}

export function copyFormDetails(isDraft) {
  return {
    type: CopyFormDetailsActions.COPY_FORM_DETAILS,
    payload: isDraft,
  };
}

////////COPY DETAILS END////////////

////// TEMPLATE  DETAILS/////////////
export const TemplateDetailsActions = {
  TEMPLATE_FORM_DETAILS: "TEMPLATE_FORM_DETAILS",
};

export function TemplateSelected() {
  return (dispatch) => dispatch(templateFormDetails());
}

export function templateFormDetails() {
  return {
    type: TemplateDetailsActions.TEMPLATE_FORM_DETAILS,
  };
}

////////TEMPLATE DETAILS END////////////

/////////////SAVE DATA IN PROPS/////////////

export const SaveFormDetailsProps = {
  SAVE_FORM_DETAILS: "SAVE_FORM_DETAILS",
};

export function SaveFormProps(Data) {
  return (dispatch) => dispatch(SaveFormDetails(Data));
}

export function SaveFormDetails(Data) {
  return (dispatch) => {
    dispatch({
      type: SaveFormDetailsProps.SAVE_FORM_DETAILS,
      payload: Data,
    });
  };
}

////////////SAVE DATA IN PROPS END//////////////

// get  details by id

///////GET LOCATION BY ID

export const GetSurveyDetailsActions = {
  FETCH_FORMBUILDER_DETAILS: "FETCH_FORMBUILDER_DETAILS",
  FETCH_FORMBUILDER_DETAILS_STATUS: "FETCH_FORMBUILDER_DETAILS_STATUS",
  FETCH_FORMBUILDER_DETAILS_FULFILLED: "FETCH_FORMBUILDER_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_DETAILS_REJECTED: "FETCH_FORMBUILDER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/getSurvey/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getSurveyDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSurveyDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyDetailsRequest() {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS,
    isPosting: true,
  };
}
export function getSurveyDetailsError(error) {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyDetailsStatus() {
  return (dispatch) => dispatch(getSurveyDetailsStatusRequest());
}
export function getSurveyDetailsStatusRequest() {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getSurveyDetailsErrorRequest());
}
export function getSurveyDetailsErrorRequest() {
  return {
    type: GetSurveyDetailsActions.SET_EDIT_ERROR,
  };
}
//////EXternal SUrvey Details////

export const GetSurveyForExternalUserActions = {
  FETCH_EXTERNAL_FORMBUILDER_DATA: "FETCH_EXTERNAL_FORMBUILDER_DATA",
  FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS: "FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS",
  FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED: "FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED",
  FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED: "FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyForExternalUser(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/getSurveyForExternalUser/` +
        params.surveyId,
      // headers: {
      //   transactionId: "1",
      //   Authorization: "Bearer " + params.access_token,
      // },
    })
      .then((response) => {
        // dispatch(getSurveyForExternalUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyForExternalUserSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSurveyForExternalUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyForExternalUserRequest() {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA,
    isPosting: true,
  };
}
export function getSurveyForExternalUserError(error) {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED,
    payload: error,
  };
}

export function getSurveyForExternalUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyForExternalUserStatus() {
  return (dispatch) => dispatch(getSurveyForExternalUserStatusRequest());
}
export function getSurveyForExternalUserStatusRequest() {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RequestManagement_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveFormBuilderActions = {
  FETCH_ACTIVE_FORMBUILDER: "FETCH_ACTIVE_FORMBUILDER",
  FETCH_ACTIVE_FORMBUILDER_STATUS: "FETCH_ACTIVE_FORMBUILDER_STATUS",
  FETCH_ACTIVE_FORMBUILDER_FULFILLED: "FETCH_ACTIVE_FORMBUILDER_FULFILLED",
  FETCH_ACTIVE_FORMBUILDER_REJECTED: "FETCH_ACTIVE_FORMBUILDER_REJECTED",
};
export function getActiveFormBuilderList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RequestManagement_URL}/getAllFormBuilderPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveFormBuilderListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveFormBuilderListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveFormBuilderListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveFormBuilderListRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER,
    isPosting: true,
  };
}
export function getActiveFormBuilderListError(error) {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_REJECTED,
    payload: error,
  };
}

export function getActiveFormBuilderListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveFormBuilderListStatus() {
  return (dispatch) => dispatch(getActiveFormBuilderListStatusRequest());
}
export function getActiveFormBuilderListStatusRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_STATUS,
  };
}

//Save Survey///
export const SaveSurveyActions = {
  SAVE_SURVEY: "SAVE_SURVEY",
  SAVE_SURVEY_STATUS: "SAVE_SURVEY_STATUS",
  SAVE_SURVEY_SUCCESS: "SAVE_SURVEY_SUCCESS",
  SAVE_SURVEY_FAILURE: "SAVE_SURVEY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveSurveyRequest() {
  return {
    type: SaveSurveyActions.SAVE_SURVEY,
    isPosting: true,
  };
}

export function SaveSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSurveyActions.SAVE_SURVEY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSurveyFailure(error) {
  return {
    type: SaveSurveyActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function SaveSurveyError(error) {
  return {
    type: SaveSurveyActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function saveSurvey(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RequestManagement_URL}/Apc_Persist/Amc_30/saveSurvey`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        // dispatch(SaveSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSurveySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSurveyError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save Survey///
export const CloseSurveyActions = {
  CLOSE_SURVEY: "CLOSE_SURVEY",
  CLOSE_SURVEY_STATUS: "CLOSE_SURVEY_STATUS",
  CLOSE_SURVEY_SUCCESS: "CLOSE_SURVEY_SUCCESS",
  CLOSE_SURVEY_FAILURE: "CLOSE_SURVEY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function CloseSurveyRequest() {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY,
    isPosting: true,
  };
}

export function CloseSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: CloseSurveyActions.CLOSE_SURVEY_SUCCESS,
      payload: props,
    });
  };
}

export function CloseSurveyFailure(error) {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY_FAILURE,
    payload: error,
  };
}

export function CloseSurveyError(error) {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY_FAILURE,
    payload: error,
  };
}

export function CloseSurvey(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      //data: props,
      url:
        `${RequestManagement_URL}/Apc_Persist/Amc_19/saveSurveyByStatus?surveyId=` +
        props.surveyId +
        `&status=6`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(CloseSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(CloseSurveySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(CloseSurveyError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const SavePageElementActions = {
  SAVE_PAGE_ELEMENT: "SAVE_PAGE_ELEMENT",
  SAVE_PAGE_ELEMENT_STATUS: "SAVE_PAGE_ELEMENT_STATUS",
  SAVE_PAGE_ELEMENT_SUCCESS: "SAVE_PAGE_ELEMENT_SUCCESS",
  SAVE_PAGE_ELEMENT_FAILURE: "SAVE_PAGE_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SavePageElementRequest() {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT,
    isPosting: true,
  };
}

export function SavePageElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SavePageElementActions.SAVE_PAGE_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SavePageElementFailure(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SavePageElementError(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function savePageElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RequestManagement_URL}/saveSurveyElements`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SavePageElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SavePageElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SavePageElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save AssessmentElement///
export const SaveAssessmentElementActions = {
  SAVE_ASSESSMENT_ELEMENT: "SAVE_ASSESSMENT_ELEMENT",
  SAVE_ASSESSMENT_ELEMENT_STATUS: "SAVE_ASSESSMENT_ELEMENT_STATUS",
  SAVE_ASSESSMENT_ELEMENT_SUCCESS: "SAVE_ASSESSMENT_ELEMENT_SUCCESS",
  SAVE_ASSESSMENT_ELEMENT_FAILURE: "SAVE_ASSESSMENT_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveAssessmentElementRequest() {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT,
    isPosting: true,
  };
}

export function SaveAssessmentElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveAssessmentElementFailure(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SaveAssessmentElementError(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function saveAssessmentElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RequestManagement_URL}/Apc_Persist/Amc_30/saveSurveyAssessment`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveAssessmentElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveAssessmentElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveAssessmentElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const DeleteSurveyPageActions = {
  DEL_SURVEY_PAGE: "DEL_SURVEY_PAGE",
  DEL_SURVEY_PAGE_STATUS: "DEL_SURVEY_PAGE_STATUS",
  DEL_SURVEY_PAGE_SUCCESS: "DEL_SURVEY_PAGE_SUCCESS",
  DEL_SURVEY_PAGE_FAILURE: "DEL_SURVEY_PAGE_FAILURE",
  LOGOUT: "LOGOUT",
};

export function DeleteSurveyPageRequest() {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE,
    isPosting: true,
  };
}

export function DeleteSurveyPageSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_SUCCESS,
      payload: props,
    });
  };
}

export function DeleteSurveyPageFailure(error) {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function DeleteSurveyPageError(error) {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function delSurveyPage(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${RequestManagement_URL}/Apc_State/Amc_30/deleteSurveyPages/` +
        props +
        `?status=false`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(DeleteSurveyPageRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(DeleteSurveyPageSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(DeleteSurveyPageError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveTemplateActions = {
  FETCH_ACTIVE_TEMPLATE: "FETCH_ACTIVE_TEMPLATE",
  FETCH_ACTIVE_TEMPLATE_STATUS: "FETCH_ACTIVE_TEMPLATE_STATUS",
  FETCH_ACTIVE_TEMPLATE_FULFILLED: "FETCH_ACTIVE_TEMPLATE_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_REJECTED: "FETCH_ACTIVE_TEMPLATE_REJECTED",
};
export function getActiveTemplateList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=` +
        params.type +
        `&templateFor=` +
        params.templateFor +
        `&surveyStatus=` +
        params.surveyStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveTemplateListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTemplateListRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE,
    isPosting: true,
  };
}
export function getActiveTemplateListError(error) {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getActiveTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveTemplateListStatus() {
  return (dispatch) => dispatch(getActiveTemplateListStatusRequest());
}
export function getActiveTemplateListStatusRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS,
  };
}

////Get review//
export const GetSurveyReviewActions = {
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyReview(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/getSurveyReview/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyReviewRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyReviewSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSurveyReviewError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyReviewRequest() {
  return {
    type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS,
    isPosting: true,
  };
}
export function getSurveyReviewError(error) {
  return {
    type:
      GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyReviewSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyReviewStatus() {
  return (dispatch) => dispatch(getSurveyReviewStatusRequest());
}
export function getSurveyReviewStatusRequest() {
  return {
    type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS,
  };
}

export function setReviewError() {
  return (dispatch) => dispatch(getSurveyReviewErrorRequest());
}
export function getSurveyReviewErrorRequest() {
  return {
    type: GetSurveyReviewActions.SET_EDIT_ERROR,
  };
}

///Get survey by user//
export const GetSurveyByUserActions = {
  FETCH_SURVEY_BY_USER_DETAILS: "FETCH_SURVEY_BY_USER_DETAILS",
  FETCH_SURVEY_BY_USER_DETAILS_STATUS: "FETCH_SURVEY_BY_USER_DETAILS_STATUS",
  FETCH_SURVEY_BY_USER_DETAILS_FULFILLED:
    "FETCH_SURVEY_BY_USER_DETAILS_FULFILLED",
  FETCH_SURVEY_BY_USER_DETAILS_REJECTED:
    "FETCH_SURVEY_BY_USER_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyByUser(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/getSurveyByUserAndSurveyId/` +
        params.userId +
        `/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyByUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyByUserSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSurveyByUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyByUserRequest() {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS,
    isPosting: true,
  };
}
export function getSurveyByUserError(error) {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyByUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyByUserStatus() {
  return (dispatch) => dispatch(getSurveyByUserStatusRequest());
}
export function getSurveyByUserStatusRequest() {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_STATUS,
  };
}

export function setUserSurveyError() {
  return (dispatch) => dispatch(getSurveyByUserErrorRequest());
}
export function getSurveyByUserErrorRequest() {
  return {
    type: GetSurveyByUserActions.SET_EDIT_ERROR,
  };
}

///Validate URL
///Get survey by user//
export const ValidateUrlActions = {
  VALIDATE_URL_DETAILS: "VALIDATE_URL_DETAILS",
  VALIDATE_URL_DETAILS_STATUS: "VALIDATE_URL_DETAILS_STATUS",
  VALIDATE_URL_DETAILS_FULFILLED: "VALIDATE_URL_DETAILS_FULFILLED",
  VALIDATE_URL_DETAILS_REJECTED: "VALIDATE_URL_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function validateUrl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: JSON.stringify({
        ...params,
      }),
      url: `${RequestManagement_URL}/Apc_View/Amc_30/validateTinyUrl`,
      headers: {
        "Content-Type":"application/json"
            },
    })
      .then((response) => {
        dispatch(validateUrlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(validateUrlSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(validateUrlError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function validateUrlRequest() {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS,
    isPosting: true,
  };
}
export function validateUrlError(error) {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS_REJECTED,
    payload: error,
  };
}

export function validateUrlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ValidateUrlActions.VALIDATE_URL_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function validateUrlStatus() {
  return (dispatch) => dispatch(validateUrlStatusRequest());
}
export function validateUrlStatusRequest() {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS_STATUS,
  };
}
/////Get long Url

export const LongUrlActions = {
  LONG_URL_DETAILS: "LONG_URL_DETAILS",
  LONG_URL_DETAILS_STATUS: "LONG_URL_DETAILS_STATUS",
  LONG_URL_DETAILS_FULFILLED: "LONG_URL_DETAILS_FULFILLED",
  LONG_URL_DETAILS_REJECTED: "LONG_URL_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function longUrl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify({
      //   ...params,
      // }),
      url: `${SystemService_URL}/Apc_View/Amc_51/getLongUrl?shortUrl=`+params.url,
      headers: {
        "Content-Type":"application/json"
            },
    })
      .then((response) => {
        // dispatch(longUrlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(longUrlSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(longUrlError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function longUrlRequest() {
  return {
    type: LongUrlActions.LONG_URL_DETAILS,
    isPosting: true,
  };
}
export function longUrlError(error) {
  return {
    type: LongUrlActions.LONG_URL_DETAILS_REJECTED,
    payload: error,
  };
}

export function longUrlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: LongUrlActions.LONG_URL_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function longUrlStatus() {
  return (dispatch) => dispatch(longUrlStatusRequest());
}
export function longUrlStatusRequest() {
  return {
    type: LongUrlActions.LONG_URL_DETAILS_STATUS,
  };
}

///Update Ext Survey status
///Get survey by user//
export const UpdateExtSurveyActions = {
  UPDATE_EXT_SURVEY_DETAILS: "UPDATE_EXT_SURVEY_DETAILS",
  UPDATE_EXT_SURVEY_DETAILS_STATUS: "UPDATE_EXT_SURVEY_DETAILS_STATUS",
  UPDATE_EXT_SURVEY_DETAILS_FULFILLED: "UPDATE_EXT_SURVEY_DETAILS_FULFILLED",
  UPDATE_EXT_SURVEY_DETAILS_REJECTED: "UPDATE_EXT_SURVEY_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function updateExtSurvey(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: JSON.stringify({
        ...params,
      }),
      url: `${RequestManagement_URL}/Apc_Persist/Amc_30/saveExternalUserSurvey`,
      // headers: {
      //   transactionId: "1",
      //   Authorization: "Bearer " + params.access_token,
      // },
    })
      .then((response) => {
        dispatch(updateExtSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(updateExtSurveySuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(updateExtSurveyError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function updateExtSurveyRequest() {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS,
    isPosting: true,
  };
}
export function updateExtSurveyError(error) {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_REJECTED,
    payload: error,
  };
}

export function updateExtSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function updateExtSurveyStatus() {
  return (dispatch) => dispatch(updateExtSurveyStatusRequest());
}
export function updateExtSurveyStatusRequest() {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_STATUS,
  };
}

///VALIDATE OTP FOR EXT USER

//Save Survey///
export const ValidateOTPAction = {
  VALIDATE_OTP: "VALIDATE_OTP",
  VALIDATE_OTP_STATUS: "VALIDATE_OTP_STATUS",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_FAILURE: "VALIDATE_OTP_FAILURE",
  //   LOGOUT: "LOGOUT",
};

export function ValidateOTPRequest() {
  return {
    type: ValidateOTPAction.VALIDATE_OTP,
    isPosting: true,
  };
}

export function ValidateOTPSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ValidateOTPAction.VALIDATE_OTP_SUCCESS,
      payload: props,
    });
  };
}

export function ValidateOTPFailure(error) {
  return {
    type: ValidateOTPAction.VALIDATE_OTP_FAILURE,
    payload: error,
  };
}

export function ValidateOTPError(error) {
  return {
    type: ValidateOTPAction.VALIDATE_OTP_FAILURE,
    payload: error,
  };
}

export function validateOTP(props) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${OTPService_URL}/Apc_View/Amc_49/verifyOTP?mobileNo=` +
        props.loginId +
        `&otp=` +
        props.otp +
        `&transactionId=1`,
      // headers: {
      //   authorization: "Bearer " + access_token,
      // },
    })
      .then((response) => {
        dispatch(ValidateOTPRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(ValidateOTPSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(ValidateOTPError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        }
      });
}

///save page Element for ext user//
//Save AssessmentElement///
export const SaveExternalSurveyElementActions = {
  SAVE_EXT_ASSESSMENT_ELEMENT: "SAVE_EXT_ASSESSMENT_ELEMENT",
  SAVE_EXT_ASSESSMENT_ELEMENT_STATUS: "SAVE_EXT_ASSESSMENT_ELEMENT_STATUS",
  SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS: "SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS",
  SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE: "SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE",
};

export function SaveExternalSurveyElementRequest() {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT,
    isPosting: true,
  };
}

export function SaveExternalSurveyElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveExternalSurveyElementFailure(error) {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SaveExternalSurveyElementError(error) {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function saveExternalAssessmentElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RequestManagement_URL}/Apc_Persist/Amc_30/saveExternalSurveyAssessment`,
    })
      .then((response) => {
        dispatch(SaveExternalSurveyElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveExternalSurveyElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveExternalSurveyElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//////////////////////////////////
export const selfReviewActions = {
  SELF_REVIEW_ACTIONS: "SELF_REVIEW_ACTIONS",
};

export function selfReview(data) {
  return (dispatch) => dispatch(selfReviewData(data));
}

export function selfReviewData(data) {
  return {
    type: selfReviewActions.SELF_REVIEW_ACTIONS,
    payload: data,
  };
}

/////////////////////////////////////

//////////////////////////////////
export const assessmentTypeActions = {
  ASSESSMENT_TYPE_ACTIONS: "ASSESSMENT_TYPE_ACTIONS",
};

export function assessmentType(data) {
  return (dispatch) => dispatch(assessmentTypeData(data));
}

export function assessmentTypeData(data) {
  return {
    type: assessmentTypeActions.ASSESSMENT_TYPE_ACTIONS,
    payload: data,
  };
}

/////////////////////////////////////

export const GetSurveyUsersActions = {
  GET_SURVEY_USERS: "GET_SURVEY_USERS",
  GET_SURVEY_USERS_STATUS: "GET_SURVEY_USERS_STATUS",
  GET_SURVEY_USERS_FULFILLED: "GET_SURVEY_USERS_FULFILLED",
  GET_SURVEY_USERS_REJECTED: "GET_SURVEY_USERS_REJECTED",
};
export function getSurveyUsersList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/getSurveyUsers/`+params.surveyId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyUsersListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyUsersListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSurveyUsersListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyUsersListRequest() {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS,
    isPosting: true,
  };
}
export function getSurveyUsersListError(error) {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS_REJECTED,
    payload: error,
  };
}

export function getSurveyUsersListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyUsersActions.GET_SURVEY_USERS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyUsersListStatus() {
  return (dispatch) => dispatch(getSurveyUsersListStatusRequest());
}
export function getSurveyUsersListStatusRequest() {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS_STATUS,
  };
}
////Survey List

export const GetAllSurveyListActions = {
  FETCH_ALL_SURVEY_LIST: "FETCH_ALL_SURVEY_LIST",
  FETCH_ALL_SURVEY_LIST_STATUS: "FETCH_ALL_SURVEY_LIST_STATUS",
  FETCH_ALL_SURVEY_LIST_FULFILLED: "FETCH_ALL_SURVEY_LIST_FULFILLED",
  FETCH_ALL_SURVEY_LIST_REJECTED: "FETCH_ALL_SURVEY_LIST_REJECTED",
};
export function getAllSurveyListByStatus(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RequestManagement_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=1,2&surveyStatus=` +
        params.surveyStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAllSurveyListByStatusRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllSurveyListByStatusSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getAllSurveyListByStatusError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAllSurveyListByStatusRequest() {
  return {
    type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST,
    isPosting: true,
  };
}
export function getAllSurveyListByStatusError(error) {
  return {
    type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST_REJECTED,
    payload: error,
  };
}

export function getAllSurveyListByStatusSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST_FULFILLED,
      payload: props,
    });
  };
}