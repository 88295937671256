import {
  SaveHostingTypeActions,
  GetHostingTypeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveHostingTypeActions
} from "./actions";

const initState = {
  hostingSuccessMessage: null,
  hostingErrorMessage: null,
  HostingTypeStatus: null,
  HostingTypeMaster: [],
  HostingTypeData: null,
  ActiveHostingTypeList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveHostingTypeActions.SAVE_HOSTING_TYPE:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false
      };
    case SaveHostingTypeActions.SAVE_HOSTING_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HostingTypeStatus: payload.status,
        hostingErrorMessage: null,
        hostingSuccessMessage: "Hosting Type added successfully!"
      };

    case SaveHostingTypeActions.SAVE_HOSTING_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        HostingTypeStatus: null,
        hostingErrorMessage: payload
      };
    case SaveHostingTypeActions.SAVE_HOSTING_TYPE_STATUS:
      return {
        ...state,
        HostingTypeStatus: 0,
        hostingErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false,
        HostingTypeData: null
      };
    case GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        HostingTypeData: payload.data,
        HostingTypeDataStatus: payload.status,
        hostingErrorMessage: null
      };
    case GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        hostingErrorMessage: payload,
        HostingTypeData: null
      };
    case GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_STATUS:
      return {
        ...state,
        HostingTypeData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE:
      return {
        ...state,
        isLoading: true,
        hostingErrorMessage: null,
        modalActive: false,
        ActiveHostingTypeList: null
      };
    case GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveHostingTypeList: payload.data,
        hostingErrorMessage: null
      };
    case GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        hostingErrorMessage: payload,
        ActiveHostingTypeList: null
      };
    case GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_STATUS:
      return {
        ...state,
        ActiveHostingTypeList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetHostingType: null,
        HostingTypeStatus: null,
        hostingSuccessMessage: null,
        GetHostingTypeStatus: null,
        HostingTypeData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
