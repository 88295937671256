import Auth from "./auth/reducer";
import App from "./app/reducer";
import Listings from "./CommonListing/reducer";
import AssetType from "./AssetType/reducer";
import HostingType from "./HostingType/reducer";
import BreachType from "./BreachType/reducer";
import HostingProvider from "./HostingProvider/reducer";
import DataSubCat from "./DataSubCat/reducer";
import DataSub from "./DataSub/reducer";
import SecurityMeasure from "./SecurityMeasure/reducer";
import SecurityType from "./SecurityMeasureType/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import Department from "./DepartmentMaster/reducer";
import DataDisposal from "./DataDisposal/reducer";
import DataSubVol from "./DataSubVolRange/reducer";
import LocationMaster from "./LocationMaster/reducer";
import DataSubElement from "./DataSubElement/reducer";
import Contacts from "./ContactMaster/reducer";
import SVM from "./SystemValueMaster/reducer";
import RiskMaster from "./RiskMaster/reducer";
import UserMaster from "./UserMaster/reducer";
import CompanyMaster from "./CompanyMaster/reducer";
import AssetMaster from "./Asset/reducer";
import GroupMaster from "./GroupList/reducer";
import logoutFlag from "./Logout/reducer";
import RopaMaster from "./Ropa/reducer";
import OrgMaster from "./OrganisationMaster/reducer";
import LegalBasis from "./LegalBasis/reducer";
import ActivityMaster from "./ActivityMaster/reducer";
import ThemeSwitcher from "./themeSwitcher/reducer";
import TemplateMaster from "./TemplateMaster/reducer";
import RegulationMaster from "./RegulationMaster/reducer";
import DataRecipient from "./DataRecipient/reducer";
import DataProcessor from "./DataProcessor/reducer";
import DataRetention from "./DataRetention/reducer";
import DataTransferMethod from "./DataTransferMethod/reducer";
import DataProcCat from "./DataProcCat/reducer";
import PurposeMaster from "./PurposeMaster/reducer";
import DataSubProcessor from "./DataSubProcessor/reducer";
import RopaDataContact from "./RopaDataContact/reducer";
import Survey from "./Survey/reducer";
import Breach from "./Breach/reducer";
import Modules from "./RoleManagement/reducer";
import DesignationMaster from "./DesignationMaster/reducer";
import DashboardLayoutData from "./Dashboard/reducer";
import RequestType from "./RequestType/reducer";
import RequestSources from "./RequestSources/reducer";
import RequestMaster from "./RequestMaster/reducer";
import Cookies from "./ConsentManagement/reducer";
import ConsentPurpose from "./ConsentPurpose/reducer";
import ConsentHead from "./ConsentHead/reducer";
import ConsentPoint from "./ConsentPoint/reducer";
import CookieCategory from "./CookieCategory/reducer";
import CookieTemplate from "./CookieManagement/reducer";
import Threat from "./Threat/reducer";
import RiskCategory from "./RiskCategory/reducer";
import RiskWorkflow from "./RiskWorkflow/reducer";
import ControlCategory from "./ControlCategory/reducer";
import BreachSource from "./BreachSource/reducer";
import ControlRegister from "./ControlRegister/reducer";
import RiskRegister from "./RiskRegister/reducer";
import ControlFramework from "./ControlFramework/reducer";
import ControlLibrary from "./ControlLibrary/reducer";
import Vulnerability from "./Vulnerability/reducer";
import RiskLibrary from "./RiskLibrary/reducer";
import RiskSurvey from "./RiskSurvey/reducer";
import VendorManagement from "./VendorManagement/reducer";
export default {
  Auth,
  App,
  Listings,
  RiskCategory,
  RiskRegister,
  ControlRegister,
  RiskWorkflow,
  AssetType,
  ConsentPurpose,
  ConsentHead,
  ConsentPoint,
  CookieCategory,
  BreachType,
  UserMaster,
  HostingType,
  HostingProvider,
  DataSubCat,
  DataSub,
  SecurityMeasure,
  SecurityType,
  Department,
  LanguageSwitcher,
  DataDisposal,
  DataSubVol,
  LocationMaster,
  DataSubElement,
  Contacts,
  SVM,
  logoutFlag,
  RiskMaster,
  CompanyMaster,
  AssetMaster,
  RopaMaster,
  LegalBasis,
  Breach,
  OrgMaster,
  ThemeSwitcher,
  ActivityMaster,
  TemplateMaster,
  RegulationMaster,
  DataRecipient,
  DataProcessor,
  DataProcCat,
  DataSubProcessor,
  RopaDataContact,
  Modules,
  Survey,
  GroupMaster,
  DataRetention,
  PurposeMaster,
  DataTransferMethod,
  DesignationMaster,
  DashboardLayoutData,
  RequestType,
  RequestSources,
  BreachSource,
  RequestMaster,
  Cookies,
  CookieTemplate,
  Threat,
  ControlCategory,
  ControlFramework,
  ControlLibrary,
  Vulnerability,
  RiskLibrary,
  RiskSurvey,
  VendorManagement,
};
