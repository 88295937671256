// export const Common_URL = "http://52.168.15.130:7010/edge-service/user-service";
// export const System_URL =
//   "http://52.168.15.130:7010/edge-service/system-service";
// export const Auth_URL = "http://52.168.15.130:7013/oauth-server";
// // "http://114.79.157.143:7001/oauth-server";
// export const Location_Master_URL =
//   "http://52.168.15.130:7011/location-service";

// //////////////////
// export const UserService_URL = "http://52.168.15.130:7006/user-service";
// // export const UserManagementService_URL = "http://192.168.0.37:7001";
// // export const UserService_URL = "http://192.168.0.37:7001";
// export const UserManagementService_URL =
//   "http://52.168.15.130:7006/user-service";

// export const SuperAdmin_Service_URL = "http://37.187.78.170:8088/EventService";
// export const RopaManagementService_URL =
//   "http://52.168.15.130:7004/ropa-service";
// export const AssetManagementService_URL =
//   "http://52.168.15.130:7003/asset-service";
//   export const GroupManagementService_URL =
//   "http://52.168.15.130:7003/asset-service";
// export const CompanyService_URL = "http://52.168.15.130:7005/company-service";

// export const SystemService_URL = "http://52.168.15.130:7002/system-service";

// export const Server_IP = "http://52.168.15.130";

//////////////////////////Profiling for deployment///////////////////////

export const Common_URL = process.env.REACT_APP_COMMON_URL_API;
export const System_URL = process.env.REACT_APP_SYSTEM_URL_API;
export const Auth_URL = process.env.REACT_APP_AUTH_URL_API;
export const Location_Master_URL =
  process.env.REACT_APP_LOCATION_MASTER_URL_API;
export const UserService_URL = process.env.REACT_APP_USERSERVICE_URL_API;
export const ConsentManagementService_URL =
  process.env.REACT_APP_CONSENTMANAGEMENT_SERVICE_URL_API;
export const UserManagementService_URL =
  process.env.REACT_APP_USERMANAGEMENT_SERVICE_URL_API;
export const RopaManagementService_URL =
  process.env.REACT_APP_ROPAMANAGEMENT_SERVICE_URL_API;
export const AssetManagementService_URL =
  process.env.REACT_APP_ASSETMANAGEMENT_SERVICE_URL_API;
export const CompanyService_URL =
  process.env.REACT_APP_COMPANY_MANAGEMENT_SERVICE_URL_API;
export const SystemService_URL = process.env.REACT_APP_SYSTEM_SERVICE_URL_API;
export const Server_IP = process.env.REACT_APP_SERVER_IP_API;
export const Server_IP_File = process.env.REACT_APP_SERVER_IP_API_FILE;
export const GroupManagementService_URL =
  process.env.REACT_APP_GROUP_MANAGEMENT_SERVICE_IP_API;
export const EdgeService_URL = process.env.REACT_APP_EDGE_SERVICE_IP_API;
export const DashboardServiceURL =
  process.env.REACT_APP_DASHBOARD_SERVICE_URL_API;
export const IncidentManagementService_URL =
  process.env.REACT_APP_INCIDENT_SERVICE_URL_API;
export const OTPService_URL = process.env.REACT_APP_OTP_SERVICE_API;
export const RequestManagement_URL =
  process.env.REACT_APP_REQUEST_MANAGEMENT_API;
export const Column_URL = process.env.REACT_APP_COLUMN_SERVICE_IP_API;
export const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;
export const DocumentService_Url = process.env.REACT_APP_DOCSERVICE_URL_API;
export const CookieService_Url = process.env.REACT_APP_COOKIE_SERVICE_URL_API;
export const CookieConsent_SDK = process.env.REACT_APP_COOKIE_CONSENT_SDK;
export const RiskManagementService_URL =
  process.env.REACT_APP_RISK_MANAGEMENT_API;
export const SurveyService_URL = process.env.REACT_APP_SURVEY_API;
export const QueryService_URL = process.env.REACT_APP_QUERY_MANAGEMENT_API;
export const VendorService_URL = process.env.REACT_APP_VENDOR_SERVICE_API;
