import {
  SaveDataProcCatActions,
  GetDataProcCatDetailsActions,
  ClearFormDetailsActions,

  GetActiveDataProcCatActions,
} from "./actions";

const initState = {
  dataProcCatSuccessMessage: null,
  dataProcCatErrorMessage: null,
  dataProcCatStatus: null,
  dataProcCatMaster: [],
  dataProcCatData: null,
  ActiveDataProcCatList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataProcCatActions.SAVE_DATA_PROC_CAT:
      return {
        ...state,
        isLoading: true,
        dataProcCatErrorMessage: null,
        modalActive: false
      };
    case SaveDataProcCatActions.SAVE_DATA_PROC_CAT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataProcCatStatus: payload.status,
        ropaDataSubProcErrorMessage: null,
        dataProcCatSuccessMessage: "ROPA Data Processor Category added successfully!"
      };

    case SaveDataProcCatActions.SAVE_DATA_PROC_CAT_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataProcCatStatus: null,
        dataProcCatErrorMessage: payload
      };
    case SaveDataProcCatActions.SAVE_DATA_PROC_CAT_STATUS:
      return {
        ...state,
        dataProcCatStatus: 0,
        dataProcCatErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataProcCatErrorMessage: null,
        modalActive: false,
        dataProcCatData: null
      };
    case GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ropaDataSubProcData: payload.data,
        dataProcCatStatus: payload.status,
        dataProcCatErrorMessage: null
      };
    case GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataProcCatErrorMessage: payload,
        dataProcCatData: null
      };
    case GetDataProcCatDetailsActions.FETCH_DATA_PROC_CAT_DETAILS_STATUS:
      return {
        ...state,
        dataProcCatData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataProcCatActions.FETCH_DATA_PROC_CAT_BASIS:
      return {
        ...state,
        isLoading: true,
        dataProcCatErrorMessage: null,
        modalActive: false,
        ActiveDataProcCatList: null
      };
    case GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataProcCatList: payload.data,
        dataProcCatErrorMessage: null
      };
    case GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataProcCatErrorMessage: payload,
        ActiveDataProcCatList: null
      };
    case GetActiveDataProcCatActions.FETCH_ACTIVE_DATA_PROC_CAT_STATUS:
      return {
        ...state,
        ActiveDataProcCatList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        dataProcCatSuccessMessage: null,
        dataProcCatStatus: null,
        dataProcCatData: null
      };

    default:
      return state;
  }
}
