import {
  SaveUserActions,
  GetUserDetailsActions,
  validateAddUserActions,
  getRoleListActions,
  // GetActiveCompanyActions
} from "./actions";

const initState = {
  userSuccessMessage: null,
  errorMessage: null,
  userStatus: null,
  userMaster: [],
  RoleList: [],
  RoleListStatus: false,
  userData: null,
  validateAddUser: null,
};

export default function Deaprtment(state = initState, { type, payload }) {
  switch (type) {
    case SaveUserActions.SAVE_USER:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
      };
    case SaveUserActions.SAVE_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        userStatus: payload.status,
        errorMessage: null,
        // companySuccessMessage: "company added successfully!"
      };

    case SaveUserActions.SAVE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        userStatus: null,
        errorMessage: payload,
      };
    case SaveUserActions.SAVE_USER_STATUS:
      return {
        ...state,
        userStatus: 0,
        errorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET BY ID
    case GetUserDetailsActions.FETCH_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        userData: null,
      };
    case GetUserDetailsActions.FETCH_USER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        userData: payload.data,
        userDataStatus: payload.status,
        errorMessage: null,
      };
    case GetUserDetailsActions.FETCH_USER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        userData: null,
      };
    case GetUserDetailsActions.FETCH_USER_DETAILS_STATUS:
      return {
        ...state,
        userData: null,
      };

    // VALIDATE USER
    case validateAddUserActions.VALIDATE_ADD_USER:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        validateAddUser: null,
      };
    case validateAddUserActions.VALIDATE_ADD_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        validateAddUser: payload,
        errorMessage: null,
      };
    case validateAddUserActions.VALIDATE_ADD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        validateAddUser: payload,
      };
    case validateAddUserActions.VALIDATE_ADD_USER_STATUS:
      return {
        ...state,
        validateAddUser: null,
        errorMessage: payload,
      };

    ////////////////// FETCH ALL ROLES///////////////////

    case getRoleListActions.FETCH_ROLES_DETAILS:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        modalActive: false,
        RoleList: [],
      };
    case getRoleListActions.FETCH_ROLES_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RoleList: payload.data,
        RoleListStatus: payload.status,
        errorMessage: null,
      };
    case getRoleListActions.FETCH_ROLES_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        RoleList: [],
      };

    default:
      return state;
  }
}
