import {
  SaveDepartmentActions,
  GetDepartmentDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDepartmentActions
} from "./actions";

const initState = {
  departmentSuccessMessage: null,
  departmentErrorMessage: null,
  departmentStatus: null,
  departmentMaster: [],
  departmentData: null,
  ActiveDepartmentList: null
};

export default function Deaprtment(state = initState, { type, payload }) {
  switch (type) {
    case SaveDepartmentActions.SAVE_DEPARTMENT:
      return {
        ...state,
        isLoading: true,
        departmentErrorMessage: null,
        modalActive: false
      };
    case SaveDepartmentActions.SAVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        departmentStatus: payload.status,
        departmentErrorMessage: null,
        departmentSuccessMessage: "Department added successfully!"
      };

    case SaveDepartmentActions.SAVE_DEPARTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        departmentStatus: null,
        departmentErrorMessage: payload
      };
    case SaveDepartmentActions.SAVE_DEPARTMENT_STATUS:
      return {
        ...state,
        departmentStatus: 0,
        departmentErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDepartmentDetailsActions.FETCH_DEPARTMENT_DETAILS:
      return {
        ...state,
        isLoading: true,
        departmentErrorMessage: null,
        modalActive: false,
        departmentData: null
      };
    case GetDepartmentDetailsActions.FETCH_DEPARTMENT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        departmentData: payload.data,
        departmentDataStatus: payload.status,
        departmentErrorMessage: null
      };
    case GetDepartmentDetailsActions.FETCH_DEPARTMENT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        departmentErrorMessage: payload,
        departmentData: null
      };
    case GetDepartmentDetailsActions.FETCH_DEPARTMENT_DETAILS_STATUS:
      return {
        ...state,
        departmentData: null
      };
    ///Active DEPARTMENT
    case GetActiveDepartmentActions.FETCH_ACTIVE_DEPARTMENT:
      return {
        ...state,
        isLoading: true,
        departmentErrorMessage: null,
        modalActive: false,
        ActiveDepartmentList: null
      };
    case GetActiveDepartmentActions.FETCH_ACTIVE_DEPARTMENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDepartmentList: payload.data,
        departmentErrorMessage: null
      };
    case GetActiveDepartmentActions.FETCH_ACTIVE_DEPARTMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        departmentErrorMessage: payload,
        ActiveDepartmentList: null
      };
    case GetActiveDepartmentActions.FETCH_ACTIVE_DEPARTMENT_STATUS:
      return {
        ...state,
        ActiveDepartmentList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        Getdepartment: null,
        departmentStatus: null,
        departmentSuccessMessage: null,
        GetdepartmentStatus: null,
        departmentData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
