import {
  SaveRegulationMasterActions,
  GetRegulationMasterDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRegulationMasterActions,
} from "./actions";

const initState = {
  regulationMasterSuccessMessage: null,
  regulationMasterErrorMessage: null,
  regulationMasterStatus: null,
  regulationMasterMaster: [],
  regulationMasterData: null,
  ActiveRegulationMasterList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveRegulationMasterActions.SAVE_REGULATION_MASTER:
      return {
        ...state,
        isLoading: true,
        regulationMasterErrorMessage: null,
        modalActive: false,
      };
    case SaveRegulationMasterActions.SAVE_REGULATION_MASTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        regulationMasterStatus: payload.status,
        regulationMasterErrorMessage: null,
        regulationMasterSuccessMessage: "Regulation Master added successfully!",
      };

    case SaveRegulationMasterActions.SAVE_REGULATION_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        regulationMasterStatus: null,
        regulationMasterErrorMessage: payload,
      };
    case SaveRegulationMasterActions.SAVE_REGULATION_MASTER_STATUS:
      return {
        ...state,
        regulationMasterStatus: 0,
        regulationMasterErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRegulationMasterDetailsActions.FETCH_REGULATION_MASTER_DETAILS:
      return {
        ...state,
        isLoading: true,
        regulationMasterErrorMessage: null,
        modalActive: false,
        regulationMasterData: null,
      };
    case GetRegulationMasterDetailsActions.FETCH_REGULATION_MASTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        regulationMasterData: payload.data,
        regulationMasterStatus: payload.status,
        regulationMasterErrorMessage: null,
      };
    case GetRegulationMasterDetailsActions.FETCH_REGULATION_MASTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        regulationMasterErrorMessage: payload,
        regulationMasterData: null,
      };
    case GetRegulationMasterDetailsActions.FETCH_REGULATION_MASTER_DETAILS_STATUS:
      return {
        ...state,
        regulationMasterData: null,
      };
    ///Active DATA DISPOSAL
    case GetActiveRegulationMasterActions.FETCH_ACTIVE_REGULATION_MASTER:
      return {
        ...state,
        isLoading: true,
        regulationMasterErrorMessage: null,
        modalActive: false,
        ActiveRegulationMasterList: null,
      };
    case GetActiveRegulationMasterActions.FETCH_ACTIVE_REGULATION_MASTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRegulationMasterList: payload.data,
        regulationMasterErrorMessage: null,
      };
    case GetActiveRegulationMasterActions.FETCH_ACTIVE_REGULATION_MASTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        regulationMasterErrorMessage: payload,
        ActiveRegulationMasterList: null,
      };
    case GetActiveRegulationMasterActions.FETCH_ACTIVE_REGULATION_MASTER_STATUS:
      return {
        ...state,
        ActiveRegulationMasterList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRegulationMaster: null,
        regulationMasterStatus: null,
        legalBasisSuccessMessage: null,
        GetRegulationMasterStatus: null,
        regulationMasterData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
