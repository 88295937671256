import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveRiskLibraryActions = {
  SAVE_RISK_LIBRARY: "SAVE_RISK_LIBRARY",
  SAVE_RISK_LIBRARY_STATUS: "SAVE_RISK_LIBRARY_STATUS",
  SAVE_RISK_LIBRARY_SUCCESS: "SAVE_RISK_LIBRARY_SUCCESS",
  SAVE_RISK_LIBRARY_FAILURE: "SAVE_RISK_LIBRARY_FAILURE",
};

export function SaveRiskLibraryRequest() {
  return {
    type: SaveRiskLibraryActions.SAVE_RISK_LIBRARY,
    isPosting: true,
  };
}

export function SaveRiskLibrarySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveRiskLibraryActions.SAVE_RISK_LIBRARY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveRiskLibraryFailure(error) {
  return {
    type: SaveRiskLibraryActions.SAVE_RISK_LIBRARY_FAILURE,
    payload: error,
  };
}

export function SaveRiskLibraryError(error) {
  return {
    type: SaveRiskLibraryActions.SAVE_RISK_LIBRARY_FAILURE,
    payload: error,
  };
}

export function saveRiskLibrary(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveRiskLibrary`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveRiskLibraryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveRiskLibrarySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveRiskLibraryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveRiskLibraryError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskLibraryError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskLibraryError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveRiskLibraryError(err));
          //   break;
          // case 500:
          //   dispatch(SaveRiskLibraryError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveRiskLibraryError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRiskLibraryDetailsActions = {
  FETCH_RISK_LIBRARY: "FETCH_RISK_LIBRARY_DETAILS",
  FETCH_RISK_LIBRARY_STATUS: "FETCH_RISK_LIBRARY_DETAILS_STATUS",
  FETCH_RISK_LIBRARY_FULFILLED: "FETCH_RISK_LIBRARY_DETAILS_FULFILLED",
  FETCH_RISK_LIBRARY_REJECTED: "FETCH_RISK_LIBRARY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskLibraryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findRiskLibraryById?riskLibraryId=` +
        params.riskLibraryId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRiskLibraryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskLibraryDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskLibraryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryDetailsError(err));
              break;
            case 500:
              dispatch(getRiskLibraryDetailsError("Server Error"));
              break;
            default:
              dispatch(getRiskLibraryDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskLibraryDetailsRequest() {
  return {
    type: GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY,
    isPosting: true,
  };
}
export function getRiskLibraryDetailsError(error) {
  return {
    type: GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_REJECTED,
    payload: error,
  };
}

export function getRiskLibraryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskLibraryDetailsStatus() {
  return (dispatch) => dispatch(getRiskLibraryDetailsStatusRequest());
}
export function getRiskLibraryDetailsStatusRequest() {
  return {
    type: GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRiskLibraryDetailsErrorRequest());
}
export function getRiskLibraryDetailsErrorRequest() {
  return {
    type: GetRiskLibraryDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("riskLibrary", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/Apc_Persist/Amc_9/bulkUploadExcel/RiskLibrary`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveRiskLibraryActions = {
  FETCH_ACTIVE_RISK_LIBRARY: "FETCH_ACTIVE_RISK_LIBRARY",
  FETCH_ACTIVE_RISK_LIBRARY_STATUS: "FETCH_ACTIVE_RISK_LIBRARY_STATUS",
  FETCH_ACTIVE_RISK_LIBRARY_FULFILLED: "FETCH_ACTIVE_RISK_LIBRARY_FULFILLED",
  FETCH_ACTIVE_RISK_LIBRARY_REJECTED: "FETCH_ACTIVE_RISK_LIBRARY_REJECTED",
};
export function getActiveRiskLibraryList(params) {
  const jsonData = params.data;
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      data: jsonData,
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/RiskLibrary`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRiskLibraryListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskLibraryListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveRiskLibraryListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskLibraryListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskLibraryListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskLibraryListError(err));
              break;
            case 500:
              dispatch(getActiveRiskLibraryListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskLibraryListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskLibraryListRequest() {
  return {
    type: GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY,
    isPosting: true,
  };
}
export function getActiveRiskLibraryListError(error) {
  return {
    type: GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_REJECTED,
    payload: error,
  };
}

export function getActiveRiskLibraryListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskLibraryListStatus() {
  return (dispatch) => dispatch(getActiveRiskLibraryListStatusRequest());
}
export function getActiveRiskLibraryListStatusRequest() {
  return {
    type: GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_STATUS,
  };
}

// Get all RiskLibrary Pagination

export const GetRiskLibraryActions = {
  FETCH_RISK_LIBRARY: "FETCH_RISK_LIBRARY",
  FETCH_RISK_LIBRARY_STATUS: "FETCH_RISK_LIBRARY_STATUS",
  FETCH_RISK_LIBRARY_FULFILLED:
    "FETCH_RISK_LIBRARY_FULFILLED",
  FETCH_RISK_LIBRARY_REJECTED:
    "FETCH_RISK_LIBRARY_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskLibrary(params) {
  return (dispatch) =>
  axios({
    method: "post",
    data: params.jsonData,
    url:
      `${RiskManagementService_URL}/Apc_View/Amc_9/listing/RiskLibrary`,
    headers: {
      Authorization: "Bearer " + params.access_token,
    },
  })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskLibrarySuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskLibraryError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskLibraryError(err));
              break;
            case 500:
              dispatch(getRiskLibraryError("Server Error"));
              break;
            default:
              dispatch(getRiskLibraryError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskLibraryRequest() {
  return {
    type: GetRiskLibraryActions.FETCH_RISK_LIBRARY,
    isPosting: true,
  };
}
export function getRiskLibraryError(error) {
  return {
    type: GetRiskLibraryActions.FETCH_RISK_LIBRARY_REJECTED,
    payload: error,
  };
}

export function getRiskLibrarySuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRiskLibraryActions.FETCH_RISK_LIBRARY_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskLibraryStatus() {
  return (dispatch) => dispatch(getRiskLibraryStatusRequest());
}
export function getRiskLibraryStatusRequest() {
  return {
    type: GetRiskLibraryActions.FETCH_RISK_LIBRARY_STATUS,
  };
}