import {
  SaveSecurityMeasureActions,
  GetSecurityMeasureDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveSecurityMeasureActions
} from "./actions";

const initState = {
  securityMeasureSuccessMessage: null,
  securityMeasureErrorMessage: null,
  securityMeasureStatus: null,
  securityMeasureMaster: [],
  securityMeasureData: null,
  ActiveSecurityMeasureList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE:
      return {
        ...state,
        isLoading: true,
        securityMeasureErrorMessage: null,
        modalActive: false
      };
    case SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        securityMeasureStatus: payload.status,
        securityMeasureErrorMessage: null,
        securityMeasureSuccessMessage: "Security Measure added successfully!"
      };

    case SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        securityMeasureStatus: null,
        securityMeasureErrorMessage: payload
      };
    case SaveSecurityMeasureActions.SAVE_SECURITY_MEASURE_STATUS:
      return {
        ...state,
        securityMeasureStatus: 0,
        securityMeasureErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS:
      return {
        ...state,
        isLoading: true,
        securityMeasureErrorMessage: null,
        modalActive: false,
        securityMeasureData: null
      };
    case GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        securityMeasureData: payload.data,
        securityMeasureDataStatus: payload.status,
        securityMeasureErrorMessage: null
      };
    case GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        securityMeasureErrorMessage: payload,
        securityMeasureData: null
      };
    case GetSecurityMeasureDetailsActions.FETCH_SECURITY_MEASURE_DETAILS_STATUS:
      return {
        ...state,
        securityMeasureData: null
      };
    ///Active DEPARTMENT
    case GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE:
      return {
        ...state,
        isLoading: true,
        securityMeasureErrorMessage: null,
        modalActive: false,
        ActiveSecurityMeasureList: null
      };
    case GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveSecurityMeasureList: payload.data,
        securityMeasureErrorMessage: null
      };
    case GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_REJECTED:
      return {
        ...state,
        isLoading: false,
        securityMeasureErrorMessage: payload,
        ActiveSecurityMeasureList: null
      };
    case GetActiveSecurityMeasureActions.FETCH_ACTIVE_SECURITY_MEASURE_STATUS:
      return {
        ...state,
        ActiveSecurityMeasureList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        securityMeasureSuccessMessage: null,
        securityMeasureErrorMessage: null,
        securityMeasureStatus: null,
        securityMeasureMaster: [],
        securityMeasureData: null,
        ActiveSecurityMeasureList: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
