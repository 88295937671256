import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveThreatActions = {
  SAVE_THREAT: "SAVE_THREAT",
  SAVE_THREAT_STATUS: "SAVE_THREAT_STATUS",
  SAVE_THREAT_SUCCESS: "SAVE_THREAT_SUCCESS",
  SAVE_THREAT_FAILURE: "SAVE_THREAT_FAILURE",
};

export function SaveThreatRequest() {
  return {
    type: SaveThreatActions.SAVE_THREAT,
    isPosting: true,
  };
}

export function SaveThreatSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveThreatActions.SAVE_THREAT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveThreatFailure(error) {
  return {
    type: SaveThreatActions.SAVE_THREAT_FAILURE,
    payload: error,
  };
}

export function SaveThreatError(error) {
  return {
    type: SaveThreatActions.SAVE_THREAT_FAILURE,
    payload: error,
  };
}

export function saveThreat(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveThreat`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveThreatRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveThreatSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveThreatError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveThreatError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveThreatError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveThreatError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveThreatError(err));
          //   break;
          // case 500:
          //   dispatch(SaveThreatError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveThreatError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetThreatDetailsActions = {
  FETCH_THREAT: "FETCH_THREAT_DETAILS",
  FETCH_THREAT_STATUS: "FETCH_THREAT_DETAILS_STATUS",
  FETCH_THREAT_FULFILLED: "FETCH_THREAT_DETAILS_FULFILLED",
  FETCH_THREAT_REJECTED: "FETCH_THREAT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getThreatDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findThreatById?threatId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getThreatDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getThreatDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getThreatDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatDetailsError(err));
              break;
            case 500:
              dispatch(getThreatDetailsError("Server Error"));
              break;
            default:
              dispatch(getThreatDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getThreatDetailsRequest() {
  return {
    type: GetThreatDetailsActions.FETCH_THREAT,
    isPosting: true,
  };
}
export function getThreatDetailsError(error) {
  return {
    type: GetThreatDetailsActions.FETCH_THREAT_REJECTED,
    payload: error,
  };
}

export function getThreatDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetThreatDetailsActions.FETCH_THREAT_FULFILLED,
      payload: props,
    });
  };
}

export function getThreatDetailsStatus() {
  return (dispatch) => dispatch(getThreatDetailsStatusRequest());
}
export function getThreatDetailsStatusRequest() {
  return {
    type: GetThreatDetailsActions.FETCH_THREAT_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getThreatDetailsErrorRequest());
}
export function getThreatDetailsErrorRequest() {
  return {
    type: GetThreatDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("threat", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/Apc_Persist/Amc_9/bulkUploadExcel/Threat`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveThreatActions = {
  FETCH_ACTIVE_THREAT: "FETCH_ACTIVE_THREAT",
  FETCH_ACTIVE_THREAT_STATUS: "FETCH_ACTIVE_THREAT_STATUS",
  FETCH_ACTIVE_THREAT_FULFILLED: "FETCH_ACTIVE_THREAT_FULFILLED",
  FETCH_ACTIVE_THREAT_REJECTED: "FETCH_ACTIVE_THREAT_REJECTED",
};
export function getActiveThreatList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/Threat`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveThreatListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveThreatListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveThreatListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveThreatListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveThreatListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveThreatListError(err));
              break;
            case 500:
              dispatch(getActiveThreatListError("Server Error"));
              break;
            default:
              dispatch(getActiveThreatListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveThreatListRequest() {
  return {
    type: GetActiveThreatActions.FETCH_ACTIVE_THREAT,
    isPosting: true,
  };
}
export function getActiveThreatListError(error) {
  return {
    type: GetActiveThreatActions.FETCH_ACTIVE_THREAT_REJECTED,
    payload: error,
  };
}

export function getActiveThreatListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveThreatActions.FETCH_ACTIVE_THREAT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveThreatListStatus() {
  return (dispatch) => dispatch(getActiveThreatListStatusRequest());
}
export function getActiveThreatListStatusRequest() {
  return {
    type: GetActiveThreatActions.FETCH_ACTIVE_THREAT_STATUS,
  };
}

// Get all Threat Pagination

export const GetThreatActions = {
  FETCH_THREAT: "FETCH_THREAT",
  FETCH_THREAT_STATUS: "FETCH_THREAT_STATUS",
  FETCH_THREAT_FULFILLED:
    "FETCH_THREAT_FULFILLED",
  FETCH_THREAT_REJECTED:
    "FETCH_THREAT_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getThreat(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/getAllThreatPagination?length=0&sortOrder=desc&sortField=threatId&status=true`, 
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getThreatSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getThreatError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getThreatError(err));
              break;
            case 500:
              dispatch(getThreatError("Server Error"));
              break;
            default:
              dispatch(getThreatError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getThreatRequest() {
  return {
    type: GetThreatActions.FETCH_THREAT,
    isPosting: true,
  };
}
export function getThreatError(error) {
  return {
    type: GetThreatActions.FETCH_THREAT_REJECTED,
    payload: error,
  };
}

export function getThreatSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetThreatActions.FETCH_THREAT_FULFILLED,
      payload: props,
    });
  };
}

export function getThreatStatus() {
  return (dispatch) => dispatch(getThreatStatusRequest());
}
export function getThreatStatusRequest() {
  return {
    type: GetThreatActions.FETCH_THREAT_STATUS,
  };
}