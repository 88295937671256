import axios from "axios";
import { SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save DataRetention

export const saveDataRetentionActions = {
  SAVE_DATA_RETENTION: "SAVE_DATA_RETENTION",
  SAVE_DATA_RETENTION_STATUS: "SAVE_DATA_RETENTION_STATUS",
  SAVE_DATA_RETENTION_SUCCESS: "SAVE_DATA_RETENTION_SUCCESS",
  SAVE_DATA_RETENTION_FAILURE: "SAVE_DATA_RETENTION_FAILURE",
};

export function saveDataRetentionRequest() {
  return {
    type: saveDataRetentionActions.SAVE_DATA_RETENTION,
    isPosting: true,
  };
}

export function saveDataRetentionSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveDataRetentionActions.SAVE_DATA_RETENTION_SUCCESS,
      payload: props,
    });
  };
}

export function saveDataRetentionFailure(error) {
  return {
    type: saveDataRetentionActions.SAVE_DATA_RETENTION_FAILURE,
    payload: error,
  };
}

export function saveDataRetentionError(error) {
  return {
    type: saveDataRetentionActions.SAVE_DATA_RETENTION_FAILURE,
    payload: error,
  };
}

export function saveDataRetention(props, access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || 0,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SystemService_URL}/Apc_Persist/Amc_51/saveDataRetentionPeriod`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveDataRetentionRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveDataRetentionSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(saveDataRetentionError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}
export function logout() {
  return {
    type: saveDataRetentionActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
// Delete DataRetention

export const deleteDataRetentionActions = {
  DELETE_DATA_RETENTION: "DELETE_DATA_RETENTION",
  DELETE_DATA_RETENTION_STATUS: "DELETE_DATA_RETENTION_STATUS",
  DELETE_DATA_RETENTION_SUCCESS: "DELETE_DATA_RETENTION_SUCCESS",
  DELETE_DATA_RETENTION_FAILURE: "DELETE_DATA_RETENTION_FAILURE",
};

export function deleteDataRetentionRequest() {
  return {
    type: deleteDataRetentionActions.DELETE_DATA_RETENTION,
    isPosting: true,
  };
}

export function deleteDataRetentionSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteDataRetentionActions.DELETE_DATA_RETENTION_SUCCESS,
      payload: props,
    });
  };
}

export function deleteDataRetentionFailure(error) {
  return {
    type: deleteDataRetentionActions.DELETE_DATA_RETENTION_FAILURE,
    payload: error,
  };
}

export function deleteDataRetentionError(error) {
  return {
    type: deleteDataRetentionActions.DELETE_DATA_RETENTION_FAILURE,
    payload: error,
  };
}

export function deleteDataRetention(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SystemService_URL}/deleteDataRetention?assetTypeId=` +
        params.assetTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteDataRetentionRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteDataRetentionSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteDataRetentionError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataRetentionDetailsActions = {
  FETCH_DATA_RETENTION_DETAILS: "FETCH_DATA_RETENTION_DETAILS",
  FETCH_DATA_RETENTION_DETAILS_STATUS: "FETCH_DATA_RETENTION_DETAILS_STATUS",
  FETCH_DATA_RETENTION_DETAILS_FULFILLED:
    "FETCH_DATA_RETENTION_DETAILS_FULFILLED",
  FETCH_DATA_RETENTION_DETAILS_REJECTED:
    "FETCH_DATA_RETENTION_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataRetentionDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/findDataRetentionMasterById?dataRetentionId=` +
        params.dataRetentionId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataRetentionDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataRetentionDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getDataRetentionDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getDataRetentionDetailsRequest() {
  return {
    type: GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS,
    isPosting: true,
  };
}
export function getDataRetentionDetailsError(error) {
  return {
    type: GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataRetentionDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataRetentionDetailsStatus() {
  return (dispatch) => dispatch(getDataRetentionDetailsStatusRequest());
}
export function getDataRetentionDetailsStatusRequest() {
  return {
    type: GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataRetentionDetailsErrorRequest());
}
export function getDataRetentionDetailsErrorRequest() {
  return {
    type: GetDataRetentionDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("assetTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${SystemService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LISTActiveDataRetention

export const GetActiveDataRetentionActions = {
  FETCH_ACTIVE_DATA_RETENTION: "FETCH_ACTIVE_DATA_RETENTION",
  FETCH_ACTIVE_DATA_RETENTION_STATUS: "FETCH_ACTIVE_DATA_RETENTION_STATUS",
  FETCH_ACTIVE_DATA_RETENTION_FULFILLED:
    "FETCH_ACTIVE_DATA_RETENTION_FULFILLED",
  FETCH_ACTIVE_DATA_RETENTION_REJECTED: "FETCH_ACTIVE_DATA_RETENTION_REJECTED",
};

export function getActiveDataRetentionList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_51/findAllPeriodsPagination?start=0&sortOrder=asc&booleanfield=true&sortField=id&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataRetentionListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataRetentionListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveDataRetentionListError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          if (err.errCode === 401) {
            //dispatch(logout());
          } else {
            return err;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataRetentionListRequest() {
  return {
    type: GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION,
    isPosting: true,
  };
}
export function getActiveDataRetentionListError(error) {
  return {
    type: GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_REJECTED,
    payload: error,
  };
}

export function getActiveDataRetentionListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataRetentionListStatus() {
  return (dispatch) => dispatch(getActiveDataRetentionListStatusRequest());
}
export function getActiveDataRetentionListStatusRequest() {
  return {
    type: GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_STATUS,
  };
}
