import axios from "axios";
import {
  SuperAdmin_Service_URL,
  ConsentManagementService_URL,
  AssetManagementService_URL,
  SystemService_URL
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Consent Head

export const saveConsentPointActions = {
  SAVE_CONSENT_POINT: "SAVE_CONSENT_POINT",
  SAVE_CONSENT_POINT_STATUS: "SAVE_CONSENT_POINT_STATUS",
  SAVE_CONSENT_POINT_SUCCESS: "SAVE_CONSENT_POINT_SUCCESS",
  SAVE_CONSENT_POINT_FAILURE: "SAVE_CONSENT_POINT_FAILURE",
};

export function saveConsentPointRequest() {
  return {
    type: saveConsentPointActions.SAVE_CONSENT_POINT,
    isPosting: true,
  };
}

export function saveConsentPointSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveConsentPointActions.SAVE_CONSENT_POINT_SUCCESS,
      payload: props,
    });
  };
}

export function saveConsentPointFailure(error) {
  return {
    type: saveConsentPointActions.SAVE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function saveConsentPointError(error) {
  return {
    type: saveConsentPointActions.SAVE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function saveConsentPoint(props, access_token) {
  // var value = {
  //   id: props.id || 0,
  //   ConsentPointName: props.ConsentPointName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${ConsentManagementService_URL}/Apc_Persist/Amc_52/saveConsentPoint`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveConsentPointRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveConsentPointSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveConsentPointError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveConsentPurposeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveConsentPurposeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveConsentPurposeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveConsentPurposeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveConsentPurposeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}
//Delete by id

export const deleteConsentPointActions = {
  DELETE_CONSENT_POINT: "DELETE_CONSENT_POINT",
  DELETE_CONSENT_POINT_STATUS: "DELETE_CONSENT_POINT_STATUS",
  DELETE_CONSENT_POINT_SUCCESS: "DELETE_CONSENT_POINT_SUCCESS",
  DELETE_CONSENT_POINT_FAILURE: "DELETE_CONSENT_POINT_FAILURE",
};

export function deleteConsentPointRequest() {
  return {
    type: deleteConsentPointActions.DELETE_CONSENT_POINT,
    isPosting: true,
  };
}

export function deleteConsentPointSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteConsentPointActions.DELETE_CONSENT_POINT_SUCCESS,
      payload: props,
    });
  };
}

export function deleteConsentPointFailure(error) {
  return {
    type: deleteConsentPointActions.DELETE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function deleteConsentPointError(error) {
  return {
    type: deleteConsentPointActions.DELETE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function deleteConsentPoint(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${ConsentManagementService_URL}/Apc_State/Amc_52/deleteConsentPoint?id=` + params.id,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteConsentPointRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteConsentPointSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteConsentPointError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentPointError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentPointError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteConsentPointError(err));
              break;
            case 500:
              dispatch(deleteConsentPointError("Server Error"));
              break;
            default:
              dispatch(deleteConsentPointError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// get  details by id

export const getConsentPointDetailsActions = {
  FETCH_CONSENT_POINT_DETAILS: "FETCH_CONSENT_POINT_DETAILS",
  FETCH_CONSENT_POINT_DETAILS_STATUS: "FETCH_CONSENT_POINT_DETAILS_STATUS",
  FETCH_CONSENT_POINT_DETAILS_FULFILLED:
    "FETCH_CONSENT_POINT_DETAILS_FULFILLED",
  FETCH_CONSENT_POINT_DETAILS_REJECTED: "FETCH_CONSENT_POINT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getConsentPointDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_52/findConsentPointById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentPointDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentPointDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentPointDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointDetailsError(err));
              break;
            case 500:
              dispatch(getConsentPointDetailsError("Server Error"));
              break;
            default:
              dispatch(getConsentPointDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentPointDetailsRequest() {
  return {
    type: getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS,
    isPosting: true,
  };
}
export function getConsentPointDetailsError(error) {
  return {
    type: getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getConsentPointDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getConsentPointDetailsStatus() {
  return (dispatch) => dispatch(getConsentPointDetailsStatusRequest());
}
export function getConsentPointDetailsStatusRequest() {
  return {
    type: getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getConsentPointDetailsErrorRequest());
}
export function getConsentPointDetailsErrorRequest() {
  return {
    type: getConsentPointDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ConsentPoints", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveConsentPointActions = {
  FETCH_ACTIVE_CONSENT_POINT: "FETCH_ACTIVE_CONSENT_POINT",
  FETCH_ACTIVE_CONSENT_POINT_STATUS: "FETCH_ACTIVE_CONSENT_POINT_STATUS",
  FETCH_ACTIVE_CONSENT_POINT_FULFILLED: "FETCH_ACTIVE_CONSENT_POINT_FULFILLED",
  FETCH_ACTIVE_CONSENT_POINT_REJECTED: "FETCH_ACTIVE_CONSENT_POINT_REJECTED",
};

export function getActiveConsentPointList(params, access_token) {
  return (dispatch) =>
    axios({
      method: "POST",
      data: params,
      url: `${ConsentManagementService_URL}/Apc_View/Amc_52/listing/ConsentPoint`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveConsentPointListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveConsentPointListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveConsentPointListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPointListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPointListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentPointListError(err));
              break;
            case 500:
              dispatch(getActiveConsentPointListError("Server Error"));
              break;
            default:
              dispatch(getActiveConsentPointListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveConsentPointListRequest() {
  return {
    type: GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT,
    isPosting: true,
  };
}
export function getActiveConsentPointListError(error) {
  return {
    type: GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_REJECTED,
    payload: error,
  };
}

export function getActiveConsentPointListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveConsentPointListStatus() {
  return (dispatch) => dispatch(getActiveConsentPointListStatusRequest());
}
export function getActiveConsentPointListStatusRequest() {
  return {
    type: GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_STATUS,
  };
}

// get  details by id

export const getConsentPointGraphDataActions = {
  FETCH_CONSENT_POINT_GRAPH: "FETCH_CONSENT_POINT_GRAPH",
  FETCH_CONSENT_POINT_GRAPH_STATUS: "FETCH_CONSENT_POINT_GRAPH_STATUS",
  FETCH_CONSENT_POINT_GRAPH_FULFILLED: "FETCH_CONSENT_POINT_GRAPH_FULFILLED",
  FETCH_CONSENT_POINT_GRAPH_REJECTED: "FETCH_CONSENT_POINT_GRAPH_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getConsentPointGraphData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_52/getConsentGraphData?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentPointGraphDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentPointGraphDataSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentPointGraphDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointGraphDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointGraphDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointGraphDataError(err));
              break;
            case 500:
              dispatch(getConsentPointGraphDataError("Server Error"));
              break;
            default:
              dispatch(getConsentPointGraphDataError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentPointGraphDataRequest() {
  return {
    type: getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH,
    isPosting: true,
  };
}
export function getConsentPointGraphDataError(error) {
  return {
    type: getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH_REJECTED,
    payload: error,
  };
}

export function getConsentPointGraphDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH_FULFILLED,
      payload: props,
    });
  };
}

export function getConsentPointGraphDataStatus() {
  return (dispatch) => dispatch(getConsentPointGraphDataStatusRequest());
}
export function getConsentPointGraphDataStatusRequest() {
  return {
    type: getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH_STATUS,
  };
}

export function setGraphDataError() {
  return (dispatch) => dispatch(getConsentPointGraphDataErrorRequest());
}
export function getConsentPointGraphDataErrorRequest() {
  return {
    type: getConsentPointGraphDataActions.SET_EDIT_ERROR,
  };
}
// get  Summary details by id

export const getConsentPointSummaryDataActions = {
  FETCH_CONSENT_POINT_SUMMARY_DATA: "FETCH_CONSENT_POINT_SUMMARY_DATA",
  FETCH_CONSENT_POINT_SUMMARY_DATA_STATUS:
    "FETCH_CONSENT_POINT_SUMMARY_DATA_STATUS",
  FETCH_CONSENT_POINT_SUMMARY_DATA_FULFILLED:
    "FETCH_CONSENT_POINT_SUMMARY_DATA_FULFILLED",
  FETCH_CONSENT_POINT_SUMMARY_DATA_REJECTED:
    "FETCH_CONSENT_POINT_SUMMARY_DATA_REJECTED",
};

export function getConsentPointSummaryData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_52/viewConsentPointById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentPointSummaryDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentPointSummaryDataSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentPointSummaryDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointSummaryDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointSummaryDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointSummaryDataError(err));
              break;
            case 500:
              dispatch(getConsentPointSummaryDataError("Server Error"));
              break;
            default:
              dispatch(getConsentPointSummaryDataError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentPointSummaryDataRequest() {
  return {
    type: getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA,
    isPosting: true,
  };
}
export function getConsentPointSummaryDataError(error) {
  return {
    type: getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA_REJECTED,
    payload: error,
  };
}

export function getConsentPointSummaryDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA_FULFILLED,
      payload: props,
    });
  };
}

export function getConsentPointSummaryDataStatus() {
  return (dispatch) => dispatch(getConsentPointSummaryDataStatusRequest());
}
export function getConsentPointSummaryDataStatusRequest() {
  return {
    type: getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA_STATUS,
  };
}

export function setSummaryDataError() {
  return (dispatch) => dispatch(getConsentPointSummaryDataErrorRequest());
}
export function getConsentPointSummaryDataErrorRequest() {
  return {
    type: getConsentPointSummaryDataActions.SET_EDIT_ERROR,
  };
}

///consent point entry

// get  Summary details by id

export const getConsentEntryListActions = {
  FETCH_CONSENT_ENTRY_LIST: "FETCH_CONSENT_ENTRY_LIST",
  FETCH_CONSENT_ENTRY_LIST_STATUS: "FETCH_CONSENT_ENTRY_LIST_STATUS",
  FETCH_CONSENT_ENTRY_LIST_FULFILLED: "FETCH_CONSENT_ENTRY_LIST_FULFILLED",
  FETCH_CONSENT_ENTRY_LIST_REJECTED: "FETCH_CONSENT_ENTRY_LIST_REJECTED",
};

export function getConsentTransactionList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: {
        defaultSort: {
          sortField: "id",
          sortOrder: "desc",
        },
        length: "0",
        search: [
          { search: params.consentPointId, searchCol: "consentPointId" },
        ],
        sort: [
          {
            sortField: "id",
            sortOrder: "desc",
          },
        ],
        start: "0",
        status: true,
      },
      url: `${ConsentManagementService_URL}/Apc_View/Amc_57/listing/listingConsentInteractions`,
      headers: {
        // transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getConsentEntryListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentEntryListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentEntryListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryListError(err));
              break;
            case 500:
              dispatch(getConsentEntryListError("Server Error"));
              break;
            default:
              dispatch(getConsentEntryListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentEntryListRequest() {
  return {
    type: getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST,
    isPosting: true,
  };
}
export function getConsentEntryListError(error) {
  return {
    type: getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST_REJECTED,
    payload: error,
  };
}

export function getConsentEntryListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST_FULFILLED,
      payload: props,
    });
  };
}

///Open API

export const getConsentPointApiActions = {
  FETCH_CONSENT_POINT_API: "FETCH_CONSENT_POINT_API",
  FETCH_CONSENT_POINT_API_STATUS: "FETCH_CONSENT_POINT_API_STATUS",
  FETCH_CONSENT_POINT_API_FULFILLED: "FETCH_CONSENT_POINT_API_FULFILLED",
  FETCH_CONSENT_POINT_API_REJECTED: "FETCH_CONSENT_POINT_API_REJECTED",
};

export function getConsentPointApi(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${ConsentManagementService_URL}/Apc_View/Amc_52/getConsentApisById?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //   dispatch(getConsentPointApiRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getConsentPointApiSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentPointApiError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointApiError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointApiError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentPointApiError(err));
              break;
            case 500:
              dispatch(getConsentPointApiError("Server Error"));
              break;
            default:
              dispatch(getConsentPointApiError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentPointApiRequest() {
  return {
    type: getConsentPointApiActions.FETCH_CONSENT_POINT_API,
    isPosting: true,
  };
}
export function getConsentPointApiError(error) {
  return {
    type: getConsentPointApiActions.FETCH_CONSENT_POINT_API_REJECTED,
    payload: error,
  };
}

export function getConsentPointApiSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getConsentPointApiActions.FETCH_CONSENT_POINT_API_FULFILLED,
      payload: props,
    });
  };
}

///Data for consentr Entry

export const getConsentEntryDataActions = {
  FETCH_CONSENT_ENTRY_DATA: "FETCH_CONSENT_ENTRY_DATA",
  FETCH_CONSENT_ENTRY_DATA_STATUS: "FETCH_CONSENT_ENTRY_DATA_STATUS",
  FETCH_CONSENT_ENTRY_DATA_FULFILLED: "FETCH_CONSENT_ENTRY_DATA_FULFILLED",
  FETCH_CONSENT_ENTRY_DATA_REJECTED: "FETCH_CONSENT_ENTRY_DATA_REJECTED",
};

export function getConsentEntryData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${ConsentManagementService_URL}/Apc_View/Amc_52/viewConsentPointData?id=` + params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getConsentEntryDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(
            getConsentEntryDataSuccess(
              response,
              params.id,
              params.createdOn,
              params.consentPointName
            )
          );
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getConsentEntryDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getConsentEntryDataError(err));
              break;
            case 500:
              dispatch(getConsentEntryDataError("Server Error"));
              break;
            default:
              dispatch(getConsentEntryDataError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getConsentEntryDataRequest() {
  return {
    type: getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA,
    isPosting: true,
  };
}
export function getConsentEntryDataError(error) {
  return {
    type: getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA_REJECTED,
    payload: error,
  };
}

export function getConsentEntryDataSuccess(
  props,
  id,
  createdOn,
  consentPointName
) {
  var data = { ...props, id, createdOn, consentPointName };
  return (dispatch) => {
    dispatch({
      type: getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA_FULFILLED,
      payload: data,
    });
  };
}

export function validateAddConsentCapturePoint(access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${ConsentManagementService_URL}/Apc_Persist/Amc_52/companyConsentPointLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}


// api for language response
export function getPurposesDataRequest() {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA,
    isPosting: true,
  };
}
export function getPurposesDataError(error) {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA_REJECTED,
    payload: error,
  };
}

export function getPurposesDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getPurposesDataActions.FETCH_PURPOSES_DATA_FULFILLED,
      payload: props,
    });
  };
}

///Data for get Purposes

export const getPurposesDataActions = {
  FETCH_PURPOSES_DATA: "FETCH_PURPOSES_DATA",
  FETCH_PURPOSES_DATA_STATUS: "FETCH_PURPOSES_DATA_STATUS",
  FETCH_PURPOSES_DATA_FULFILLED: "FETCH_PURPOSES_DATA_FULFILLED",
  FETCH_PURPOSES_DATA_REJECTED: "FETCH_PURPOSES_DATA_REJECTED",
};

export function getPurposesData(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${ConsentManagementService_URL}${params.endPoint}?apiKey=${params.apikey}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getPurposesDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(
            getPurposesDataSuccess(
              response,
              params.id,
              params.createdOn,
              params.consentPointName
            )
          );
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getPurposesDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposesDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposesDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposesDataError(err));
              break;
            case 500:
              dispatch(getPurposesDataError("Server Error"));
              break;
            default:
              dispatch(getPurposesDataError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          // dispatch(clearLogoutData());
        }
      });
}

// save offline form


export const saveOfflineConsentPointActions = {
  SAVE_OFFLINE_CONSENT_POINT: "SAVE_OFFLINE_CONSENT_POINT",
  SAVE_OFFLINE_CONSENT_POINT_STATUS: "SAVE_OFFLINE_CONSENT_POINT_STATUS",
  SAVE_OFFLINE_CONSENT_POINT_SUCCESS: "SAVE_OFFLINE_CONSENT_POINT_SUCCESS",
  SAVE_OFFLINE_CONSENT_POINT_FAILURE: "SAVE_OFFLINE_CONSENT_POINT_FAILURE",
};

export function saveOfflineConsentPointRequest() {
  return {
    type: saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT,
    isPosting: true,
  };
}

export function saveOfflineConsentPointSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_SUCCESS,
      payload: props,
    });
  };
}

export function saveOfflineConsentPointFailure(error) {
  return {
    type: saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function saveOfflineConsentPointError(error) {
  return {
    type: saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_FAILURE,
    payload: error,
  };
}

export function saveOfflineConsentPoint(props, access_token) {
  
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${ConsentManagementService_URL}/Apc_Persist/Amc_90/saveOfflineconsentEntry/?apiName=${props.consentPointId}`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveOfflineConsentPointRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveOfflineConsentPointSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveOfflineConsentPointError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          // dispatch(clearLogoutData());
        }
      });
}

// get call for calculated expire on date 

export function getcalculateExpiryDateRequest() {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA,
    isPosting: true,
  };
}
export function getcalculateExpiryDateError(error) {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA_REJECTED,
    payload: error,
  };
}

export function getcalculateExpiryDateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getPurposesDataActions.FETCH_PURPOSES_DATA_FULFILLED,
      payload: props,
    });
  };
}



export const getcalculateExpiryDateActions = {
  FETCH_CALCULATED_EXP_DATA: "FETCH_CALCULATED_EXP_DATA",
  FETCH_CALCULATED_EXP_STATUS: "FETCH_CALCULATED_EXP_STATUS",
  FETCH_CALCULATED_EXP_FULFILLED: "FETCH_CALCULATED_EXP_FULFILLED",
  FETCH_CALCULATED_EXP_REJECTED: "FETCH_CALCULATED_EXP_REJECTED",
};

export function getcalculateExpiryDate(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data:params.data,
      url:
        `${ConsentManagementService_URL}/Apc_Persist/Amc_90/calculateExpiryDate`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getcalculateExpiryDateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(
            getcalculateExpiryDateSuccess(
              response,
              params.id,
              params.createdOn,
              params.consentPointName
            )
          );
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getcalculateExpiryDateError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getcalculateExpiryDateError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getcalculateExpiryDateError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getcalculateExpiryDateError(err));
              break;
            case 500:
              dispatch(getcalculateExpiryDateError("Server Error"));
              break;
            default:
              dispatch(getcalculateExpiryDateError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          // dispatch(clearLogoutData());
        }
      });
}

// get purpose by regestry code

export function getPurposeByRegistryCodeRequest() {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA,
    isPosting: true,
  };
}
export function getPurposeByRegistryCodeError(error) {
  return {
    type: getPurposesDataActions.FETCH_PURPOSES_DATA_REJECTED,
    payload: error,
  };
}

export function getPurposeByRegistryCodeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getPurposesDataActions.FETCH_PURPOSES_DATA_FULFILLED,
      payload: props,
    });
  };
}



export const getPurposeByRegistryCodeActions = {
  FETCH_PURPOSE_BY_REGESTRY_DATA: "FETCH_GET_PURPOSE_BY_REGESTRY_DATA",
  FETCH_PURPOSE_BY_REGESTRY_STATUS: "FETCH_PURPOSE_BY_REGESTRY_STATUS",
  FETCH_PURPOSE_BY_REGESTRY_FULFILLED: "FETCH_PURPOSE_BY_REGESTRY_FULFILLED",
  FETCH_PURPOSE_BY_REGESTRY_REJECTED: " FETCH_PURPOSE_BY_REGESTRY_REJECTED",
};

export function getPurposeByRegistryCode(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${ConsentManagementService_URL}-V2/getConsentByTxId?txId=${params.txId}&uniqueidentifier=${params.uniqueidentifier}&companyId=${params.companyId}&consentPointId=${params.consentPointId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getPurposeByRegistryCodeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(
            getPurposeByRegistryCodeSuccess(response)
          );
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getPurposeByRegistryCodeError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposeByRegistryCodeError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposeByRegistryCodeError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getPurposeByRegistryCodeError(err));
              break;
            case 500:
              dispatch(getPurposeByRegistryCodeError("Server Error"));
              break;
            default:
              dispatch(getPurposeByRegistryCodeError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          // dispatch(clearLogoutData());
        }
      });
}
// lawfull basis 
export const getLawfullBasisActions = {
  FETCH_LAWFUL_BASIS_API: "FETCH_LAWFUL_BASIS_API",
  FETCH_LAWFUL_BASIS_API_STATUS: "FETCH_LAWFUL_BASIS_API_STATUS",
  FETCH_LAWFUL_BASIS_API_FULFILLED: "FETCH_LAWFUL_BASIS_API_FULFILLED",
  FETCH_LAWFUL_BASIS_API_REJECTED: "FETCH_LAWFUL_BASIS_API_REJECTED",
};

export function getLawfullBasisApi(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_23/getAllLegalBasisPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //   dispatch(getConsentPointApiRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getLawfullBasisApiSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getLawfullBasisApiError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getLawfullBasisApiError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getLawfullBasisApiError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getLawfullBasisApiError(err));
              break;
            case 500:
              dispatch(getLawfullBasisApiError("Server Error"));
              break;
            default:
              dispatch(getLawfullBasisApiError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getLawfullBasisApiRequest() {
  return {
    type: getLawfullBasisActions.FETCH_LAWFUL_BASIS_API,
    isPosting: true,
  };
}
export function getLawfullBasisApiError(error) {
  return {
    type: getLawfullBasisActions.FETCH_LAWFUL_BASIS_API_REJECTED,
    payload: error,
  };
}

export function getLawfullBasisApiSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getLawfullBasisActions.FETCH_LAWFUL_BASIS_API_FULFILLED,
      payload: props,
    });
  };
}