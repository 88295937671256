import {
  SaveSecurityMeasureTypeActions,
  GetSecurityMeasureTypeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveSecTypeActions,
} from "./actions";

const initState = {
  securityMeasureTypeSuccessMessage: null,
  securityMeasureTypeErrorMessage: null,
  securityMeasureTypeStatus: null,
  securityMeasureTypeMaster: [],
  securityMeasureTypeData: null,
  ActiveSecurityMeasureType: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE:
      return {
        ...state,
        isLoading: true,
        securityMeasureTypeErrorMessage: null,
        modalActive: false,
      };
    case SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        securityMeasureTypeStatus: payload.status,
        securityMeasureTypeErrorMessage: null,
        securityMeasureTypeSuccessMessage:
          "Security Measure Type added successfully!",
      };

    case SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        securityMeasureTypeStatus: null,
        securityMeasureTypeErrorMessage: payload,
      };
    case SaveSecurityMeasureTypeActions.SAVE_SECURITY_MEASURE_TYPE_STATUS:
      return {
        ...state,
        securityMeasureTypeStatus: 0,
        securityMeasureTypeErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS:
      return {
        ...state,
        isLoading: true,
        securityMeasureTypeErrorMessage: null,
        modalActive: false,
        securityMeasureTypeData: null,
      };
    case GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        securityMeasureTypeData: payload.data,
        securityMeasureTypeDataStatus: payload.status,
        securityMeasureTypeErrorMessage: null,
      };
    case GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        securityMeasureTypeErrorMessage: payload,
        securityMeasureTypeData: null,
      };
    case GetSecurityMeasureTypeDetailsActions.FETCH_SECURITY_MEASURE_TYPE_DETAILS_STATUS:
      return {
        ...state,
        securityMeasureTypeData: null,
      };

    case GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE:
      return {
        ...state,
        isLoading: true,
        securityMeasureTypeErrorMessage: null,
        modalActive: false,
        ActiveSecurityMeasureType: null,
      };
    case GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveSecurityMeasureType: payload.data,
        securityMeasureTypeErrorMessage: null,
      };
    case GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        securityMeasureTypeErrorMessage: payload,
        ActiveSecurityMeasureType: null,
      };
    case GetActiveSecTypeActions.FETCH_ACTIVE_SECURITY_TYPE_STATUS:
      return {
        ...state,
        ActiveSecurityMeasureType: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetsecurityMeasureType: null,
        securityMeasureTypeStatus: null,
        securityMeasureTypeSuccessMessage: null,
        GetsecurityMeasureTypeStatus: null,
        securityMeasureTypeData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
