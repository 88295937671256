import {
  SaveControlLibraryActions,
  GetControlLibraryDetailsActions,
  ClearFormDetailsActions,
  //UploadexcelActions,
  GetActiveControlLibraryActions,
} from "./actions";

const initState = {
  controlFrameWkSuccessMessage: null,
  controlFrameWkErrorMessage: null,
  ControlStatus: null,
  ControlLibraryMaster: [],
  ControlLibraryData: null,
  ActiveControlLibraryList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveControlLibraryActions.SAVE_CONTROL_LIBRARY:
      return {
        ...state,
        isLoading: true,
        controlFrameWkErrorMessage: null,
        modalActive: false,
      };
    case SaveControlLibraryActions.SAVE_CONTROL_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlStatus: payload.status,
        controlFrameWkErrorMessage: null,
        controlFrameWkSuccessMessage: "Control Category added successfully!",
      };

    case SaveControlLibraryActions.SAVE_CONTROL_LIBRARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ControlStatus: null,
        controlFrameWkErrorMessage: payload,
      };
    case SaveControlLibraryActions.SAVE_CONTROL_LIBRARY_STATUS:
      return {
        ...state,
        ControlStatus: 0,
        controlFrameWkErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetControlLibraryDetailsActions.FETCH_CONTROL_LIBRARY:
      return {
        ...state,
        isLoading: true,
        controlFrameWkErrorMessage: null,
        modalActive: false,
        ControlLibraryData: null,
      };
    case GetControlLibraryDetailsActions.FETCH_CONTROL_LIBRARY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ControlLibraryData: payload.data,
        ControlLibraryDataStatus: payload.status,
        controlFrameWkErrorMessage: null,
      };
    case GetControlLibraryDetailsActions.FETCH_CONTROL_LIBRARY_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlFrameWkErrorMessage: payload,
        ControlLibraryData: null,
      };
    case GetControlLibraryDetailsActions.FETCH_CONTROL_LIBRARY_STATUS:
      return {
        ...state,
        ControlLibraryData: payload.data,
        ControlLibraryDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveControlLibraryActions.FETCH_ACTIVE_CONTROL_LIBRARY:
      return {
        ...state,
        isLoading: true,
        controlFrameWkErrorMessage: null,
        modalActive: false,
        ActiveControlLibraryList: null,
      };
    case GetActiveControlLibraryActions.FETCH_ACTIVE_CONTROL_LIBRARY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveControlLibraryList: payload.data,
        controlFrameWkErrorMessage: null,
      };
    case GetActiveControlLibraryActions.FETCH_ACTIVE_CONTROL_LIBRARY_REJECTED:
      return {
        ...state,
        isLoading: false,
        controlFrameWkErrorMessage: payload,
        ActiveControlLibraryList: null,
      };
    case GetActiveControlLibraryActions.FETCH_ACTIVE_CONTROL_LIBRARY_STATUS:
      return {
        ...state,
        ActiveControlLibraryList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetControlCategory: null,
        ControlStatus: null,
        controlFrameWkSuccessMessage: null,
        GetControlStatus: null,
        ControlLibraryData: null,
      };

    /*Excel Actions */

    /*case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
   case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
       ...state,
        ItineraryImage: payload.data,
       ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
       //error = payload || { message: payload.message };//2nd one is network or server down errors
     return {
       ...state,
       errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
