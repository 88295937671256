import axios from "axios";
import {
  UserManagementService_URL,
  EdgeService_URL,
  CompanyService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveUserActions = {
  SAVE_USER: "SAVE_USER",
  SAVE_USER_STATUS: "SAVE_USER_STATUS",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE: "SAVE_USER_FAILURE",
};

export function logout() {
  return {
    type: SaveUserActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveUserRequest() {
  return {
    type: SaveUserActions.SAVE_USER,
    isPosting: true,
  };
}

export function SaveUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveUserActions.SAVE_USER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveUserFailure(error) {
  return {
    type: SaveUserActions.SAVE_USER_FAILURE,
    payload: error,
  };
}

export function SaveUserError(error) {
  return {
    type: SaveUserActions.SAVE_USER_FAILURE,
    payload: error,
  };
}

export function saveUser(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url:
        // `http://192.168.0.37:7001/addCompanyUser`,
        `${UserManagementService_URL}/Apc_Persist/Amc_31/addCompanyUser`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveUserSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveUserError(error));
          throw error;
          // return response;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveRoleError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveRoleError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetUserDetailsActions = {
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  FETCH_USER_DETAILS_STATUS: "FETCH_USER_DETAILS_STATUS",
  FETCH_USER_DETAILS_FULFILLED: "FETCH_USER_DETAILS_FULFILLED",
  FETCH_USER_DETAILS_REJECTED: "FETCH_USER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getUserDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${UserManagementService_URL}/Apc_View/Amc_31/getUserById/` +
        params.userId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getUserDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getUserDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getUserDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getUserDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getUserDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getUserDetailsError(err));
              break;
            case 500:
              dispatch(getUserDetailsError("Server Error"));
              break;
            default:
              dispatch(getUserDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getUserDetailsRequest() {
  return {
    type: GetUserDetailsActions.FETCH_USER_DETAILS,
    isPosting: true,
  };
}
export function getUserDetailsError(error) {
  return {
    type: GetUserDetailsActions.FETCH_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getUserDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetUserDetailsActions.FETCH_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getUserDetailsStatus() {
  return (dispatch) => dispatch(getUserDetailsStatusRequest());
}
export function getUserDetailsStatusRequest() {
  return {
    type: GetUserDetailsActions.FETCH_USER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getUserDetailsErrorRequest());
}
export function getUserDetailsErrorRequest() {
  return {
    type: GetUserDetailsActions.SET_EDIT_ERROR,
  };
}

// export const GetActiveCompanyActions = {
//   FETCH_ACTIVE_COMPANY: "FETCH_ACTIVE_COMPANY",
//   FETCH_ACTIVE_COMPANY_STATUS: "FETCH_ACTIVE_COMPANY_STATUS",
//   FETCH_ACTIVE_COMPANY_FULFILLED: "FETCH_ACTIVE_COMPANY_FULFILLED",
//   FETCH_ACTIVE_COMPANY_REJECTED: "FETCH_ACTIVE_COMPANY_REJECTED"
// };
// export function getActiveCompanyList(params) {
//   return dispatch =>
//     axios({
//       method: "post",
//       // data: JSON.stringify( {
//       //           ...params
//       //         }),
//       url: `${CompanyManagementService_URL}/getAllCompanyMasterPagination?start=0&length=10&sortOrder=desc&sortField=subscriptionPlanId&booleanfield=true`,
//       headers: {
//         transactionId: "1",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + params.access_token
//       }
//     })
//       .then(response => {
//         dispatch(getActiveCompanyListRequest());
//         if (response.status >= 200 && response.status < 300) {
//           dispatch(getActiveCompanyListSuccess(response));
//           return response;
//         } else {
//           
//           const error = new Error(response.statusText);
//           dispatch(getActiveCompanyListError(error));
//           throw error;
//         }
//       })
//       .catch(err => {
//         
//         if (err.response) {
//           switch (err.response.status) {
//             case 400:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach(ele => (err = ele));

//               dispatch(getActiveCompanyListError(err));
//               break;
//             case 404:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach(ele => (err = ele));

//               dispatch(getActiveCompanyListError(err));
//               break;
//             case 406:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach(ele => (err = ele));

//               dispatch(getActiveCompanyListError(err));
//               break;
//             case 500:
//               dispatch(getActiveCompanyListError("Server Error"));
//               break;
//             default:
//               dispatch(getActiveCompanyListError("Unknown Error"));
//               break;
//           }
//         } else {
//           // //dispatch(logout());
//         }
//       });
// }

// export function getActiveCompanyListRequest() {
//   return {
//     type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY,
//     isPosting: true
//   };
// }
// export function getActiveCompanyListError(error) {
//   return {
//     type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_REJECTED,
//     payload: error
//   };
// }

// export function getActiveCompanyListSuccess(props) {
//   return dispatch => {
//     dispatch({
//       type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_FULFILLED,
//       payload: props
//     });
//   };
// }

// export function getActiveCompanyListStatus() {
//   return dispatch => dispatch(getActiveCompanyListStatusRequest());
// }
// export function getActiveCompanyListStatusRequest() {
//   return {
//     type: GetActiveCompanyActions.FETCH_ACTIVE_COMPANY_STATUS
//   };
// }

////////////ADD USER SUBSCRIPTION VALIDATION///////////////

export const validateAddUserActions = {
  VALIDATE_ADD_USER: "VALIDATE_ADD_USER",
  VALIDATE_ADD_USER_STATUS: "VALIDATE_ADD_USER_STATUS",
  VALIDATE_ADD_USER_SUCCESS: "VALIDATE_ADD_USER_SUCCESS",
  VALIDATE_ADD_USER_FAILURE: "VALIDATE_ADD_USER_FAILURE",
};

export function validateAddUserRequest() {
  return {
    type: validateAddUserActions.VALIDATE_ADD_USER,
    isPosting: true,
  };
}

export function validateAddUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: validateAddUserActions.VALIDATE_ADD_USER_SUCCESS,
      payload: props,
    });
  };
}

export function validateAddUserFailure(error) {
  return {
    type: validateAddUserActions.VALIDATE_ADD_USER_FAILURE,
    payload: error,
  };
}

export function validateAddUserError(error) {
  return {
    type: validateAddUserActions.VALIDATE_ADD_USER_STATUS,
    payload: error,
  };
}
// export function logout() {
//   return {
//     type: validateAddUserActions.LOGOUT,
//     payload: ""
//   };
// }
export function validateAddUser(access_token) {
  // var value = {
  //   assetTypeId: props.assetTypeId || null,
  //   assetTypeName: props.assetTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url: `${CompanyService_URL}/Apc_View/Amc_32/subscriptionPlanUserLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(validateAddUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(validateAddUserSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(validateAddUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(validateAddUserError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(validateAddUserError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(validateAddUserError(err));
              break;
            case 500:
              err = { message: "Server Error" };
              dispatch(validateAddUserFailure(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(validateAddUserError(err));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

/////////////////get All Roles/////////////////////////////////

export const getRoleListActions = {
  FETCH_ROLES_DETAILS: "FETCH_ROLES_DETAILS",
  FETCH_ROLES_DETAILS_STATUS: "FETCH_ROLES_DETAILS_STATUS",
  FETCH_ROLES_DETAILS_FULFILLED: "FETCH_ROLES_DETAILS_FULFILLED",
  FETCH_ROLES_DETAILS_REJECTED: "FETCH_ROLES_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAllRoleList(props) {
  const search = props.search || "";
  const active = props.active;
  const result = props.result || "10";
  const page = props.page || "0";
  const sortOrder = props.sortOrder === false ? "asc" : "desc" || "desc";
  const searchCol = (props.search === null ? "" : props.searchCol) || "";
  const access_token = props.access_token;
  const sortField = props.sortField || "id";
  return (dispatch) =>
    axios({
      method: "post",
      //data: params,
      url:
        `${EdgeService_URL}/findAllCompanyRolesPaginationCriteria?start=` +
        page +
        `&length=` +
        result +
        `&sortOrder=` +
        sortOrder +
        `&sortField=` +
        sortField +
        `&status=` +
        active +
        `&search=` +
        search +
        `&searchCol=` +
        searchCol +
        `&access_token=` +
        access_token,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(getRoleListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRoleListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRoleListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleListError(err));
              break;
            case 500:
              dispatch(getRoleListError("Server Error"));
              break;
            default:
              dispatch(getRoleListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRoleListRequest() {
  return {
    type: getRoleListActions.FETCH_ROLES_DETAILS,
    isPosting: true,
  };
}
export function getRoleListError(error) {
  return {
    type: getRoleListActions.FETCH_ROLES_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRoleListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getRoleListActions.FETCH_ROLES_DETAILS_FULFILLED,
      payload: props,
    });
  };
}
