import {
  SaveRiskLibraryActions,
  GetRiskLibraryDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRiskLibraryActions,
  GetRiskLibraryActions
} from "./actions";

const initState = {
  riskLibrarySuccessMessage: null,
  riskLibraryErrorMessage: null,
  RiskLibraryStatus: null,
  RiskLibraryMaster: [],
  RiskLibraryData: null,
  ActiveRiskLibraryList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveRiskLibraryActions.SAVE_RISK_LIBRARY:
      return {
        ...state,
        isLoading: true,
        riskLibraryErrorMessage: null,
        modalActive: false,
      };
    case SaveRiskLibraryActions.SAVE_RISK_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskLibraryStatus: payload.status,
        riskLibraryErrorMessage: null,
        riskLibrarySuccessMessage: "RiskLibrary added successfully!",
      };

    case SaveRiskLibraryActions.SAVE_RISK_LIBRARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskLibraryStatus: null,
        riskLibraryErrorMessage: payload,
      };
    case SaveRiskLibraryActions.SAVE_RISK_LIBRARY_STATUS:
      return {
        ...state,
        RiskLibraryStatus: 0,
        riskLibraryErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY:
      return {
        ...state,
        isLoading: true,
        riskLibraryErrorMessage: null,
        modalActive: false,
        RiskLibraryData: null,
      };
    case GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskLibraryData: payload.data,
        RiskLibraryDataStatus: payload.status,
        riskLibraryErrorMessage: null,
      };
    case GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskLibraryErrorMessage: payload,
        RiskLibraryData: null,
      };
    case GetRiskLibraryDetailsActions.FETCH_RISK_LIBRARY_STATUS:
      return {
        ...state,
        RiskLibraryData: payload.data,
        RiskLibraryDataStatus: payload.status,
      };
    ///Active DATA DISPOSAL
    case GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY:
      return {
        ...state,
        isLoading: true,
        riskLibraryErrorMessage: null,
        modalActive: false,
        ActiveRiskLibraryList: null,
      };
    case GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRiskLibraryList: payload.data,
        riskLibraryErrorMessage: null,
      };
    case GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskLibraryErrorMessage: payload,
        ActiveRiskLibraryList: null,
      };
    case GetActiveRiskLibraryActions.FETCH_ACTIVE_RISK_LIBRARY_STATUS:
      return {
        ...state,
        ActiveRiskLibraryList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRiskLibrary: null,
        RiskLibraryStatus: null,
        riskLibrarySuccessMessage: null,
        GetRiskLibraryStatus: null,
        RiskLibraryData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

//Get Pagination
case GetRiskLibraryActions.FETCH_RISK_LIBRARY:
  return {
    ...state,
    isLoading: true,
    riskLibraryErrorMessage: null,
    modalActive: false,
    RiskLibraryData: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveRiskLibraryList: payload.data,
    RiskLibraryStatus: payload.status,  
    riskLibraryErrorMessage: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_REJECTED:
  return {
    ...state,
    isLoading: false,
    riskLibraryErrorMessage: payload,
    RiskLibraryData: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_STATUS:
  return {
    ...state,
    RiskLibraryData: null
  };
    
case GetRiskLibraryActions.FETCH_RISK_LIBRARY:
  return {
    ...state,
    isLoading: true,
    riskLibraryErrorMessage: null,
    modalActive: false,
    RiskLibraryData: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveRiskLibraryList: payload.data,
    RiskLibraryStatus: payload.status,  
    riskLibraryErrorMessage: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_REJECTED:
  return {
    ...state,
    isLoading: false,
    riskLibraryErrorMessage: payload,
    RiskLibraryData: null
  };
case GetRiskLibraryActions.FETCH_RISK_LIBRARY_STATUS:
  return {
    ...state,
    RiskLibraryData: null
  };
  /* */

  default:
    return state;


}
}

