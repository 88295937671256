import axios from "axios";
import { clearLogoutData } from "../Logout/actions";
import {
  AssetManagementService_URL,
  RequestManagement_URL,
  SystemService_URL,
  UserService_URL,
} from "../Url";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveRoleActions = {
  SAVE_ROLE: "SAVE_ROLE",
  SAVE_ROLE_STATUS: "SAVE_ROLE_STATUS",
  SAVE_ROLE_SUCCESS: "SAVE_ROLE_SUCCESS",
  SAVE_ROLE_FAILURE: "SAVE_ROLE_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveRoleActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveRoleRequest() {
  return {
    type: SaveRoleActions.SAVE_ROLE,
    isPosting: true,
  };
}

export function SaveRoleSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveRoleActions.SAVE_ROLE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveRoleFailure(error) {
  return {
    type: SaveRoleActions.SAVE_ROLE_FAILURE,
    payload: error,
  };
}

export function SaveRoleError(error) {
  return {
    type: SaveRoleActions.SAVE_ROLE_FAILURE,
    payload: error,
  };
}

export function saveRole(props) {
  var value = {
    contactRoleId: props.contactRoleId || 0,
    contactRoleName: props.role,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${AssetManagementService_URL}/Apc_Persist/Amc_33/saveContactRole`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveRoleRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveRoleSuccess(response));
          return response;
          // } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveRoleError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveRoleError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveRoleError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveRoleError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get Roles user by role ID

export const GetRoleDetailsActions = {
  FETCH_ROLE_DETAILS: "FETCH_ROLE_DETAILS",
  FETCH_ROLE_DETAILS_STATUS: "FETCH_ROLE_DETAILS_STATUS",
  FETCH_ROLE_DETAILS_FULFILLED: "FETCH_ROLE_DETAILS_FULFILLED",
  FETCH_ROLE_DETAILS_REJECTED: "FETCH_ROLE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRoleDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${AssetManagementService_URL}/Apc_View/Amc_33/getContactRoleById?contactRoleId=` +
        params.contactRoleId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRoleDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRoleDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRoleDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRoleDetailsError(err));
              break;
            case 500:
              dispatch(getRoleDetailsError("Server Error"));
              break;
            default:
              dispatch(getRoleDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRoleDetailsRequest() {
  return {
    type: GetRoleDetailsActions.FETCH_ROLE_DETAILS,
    isPosting: true,
  };
}
export function getRoleDetailsError(error) {
  return {
    type: GetRoleDetailsActions.FETCH_ROLE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRoleDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRoleDetailsActions.FETCH_ROLE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRoleDetailsStatus() {
  return (dispatch) => dispatch(getRoleDetailsStatusRequest());
}
export function getRoleDetailsStatusRequest() {
  return {
    type: GetRoleDetailsActions.FETCH_ROLE_DETAILS_STATUS,
  };
}

export function setRoleDetailsError() {
  return (dispatch) => dispatch(getRoleDetailsErrorRequest());
}
export function getRoleDetailsErrorRequest() {
  return {
    type: GetRoleDetailsActions.SET_EDIT_ERROR,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetContactsDetailsActions = {
  FETCH_CONTACTS_DETAILS: "FETCH_CONTACTS_DETAILS",
  FETCH_CONTACTS_DETAILS_STATUS: "FETCH_CONTACTS_DETAILS_STATUS",
  FETCH_CONTACTS_DETAILS_FULFILLED: "FETCH_CONTACTS_DETAILS_FULFILLED",
  FETCH_CONTACTS_DETAILS_REJECTED: "FETCH_CONTACTS_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getContactsDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/findcontactsCategoryMasterById?dataSubjectCategoryId=` +
        params.dataSubjectCategoryId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getContactsDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getContactsDetailsSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(getContactsDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getContactsDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getContactsDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getContactsDetailsError(err));
              break;
            case 500:
              dispatch(getContactsDetailsError("Server Error"));
              break;
            default:
              dispatch(getContactsDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getContactsDetailsRequest() {
  return {
    type: GetContactsDetailsActions.FETCH_CONTACTS_DETAILS,
    isPosting: true,
  };
}
export function getContactsDetailsError(error) {
  return {
    type: GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_REJECTED,
    payload: error,
  };
}

export function getContactsDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getContactsDetailsStatus() {
  return (dispatch) => dispatch(getContactsDetailsStatusRequest());
}
export function getContactsDetailsStatusRequest() {
  return {
    type: GetContactsDetailsActions.FETCH_CONTACTS_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getContactsDetailsErrorRequest());
}
export function getContactsDetailsErrorRequest() {
  return {
    type: GetContactsDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const GetActivecontactsActions = {
  FETCH_ACTIVE_CONTACTS: "FETCH_ACTIVE_CONTACTS",
  FETCH_ACTIVE_CONTACTS_STATUS: "FETCH_ACTIVE_CONTACTS_STATUS",
  FETCH_ACTIVE_CONTACTS_FULFILLED: "FETCH_ACTIVE_CONTACTS_FULFILLED",
  FETCH_ACTIVE_CONTACTS_REJECTED: "FETCH_ACTIVE_CONTACTS_REJECTED",
};
export function getActivecontactsList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${AssetManagementService_URL}/Apc_View/Amc_33/getAllContactRolePagination?length=0&sortOrder=desc&sortField=contactRoleId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActivecontactsListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActivecontactsListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActivecontactsListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivecontactsListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivecontactsListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActivecontactsListError(err));
              break;
            case 500:
              dispatch(getActivecontactsListError("Server Error"));
              break;
            default:
              dispatch(getActivecontactsListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActivecontactsListRequest() {
  return {
    type: GetActivecontactsActions.FETCH_ACTIVE_CONTACTS,
    isPosting: true,
  };
}
export function getActivecontactsListError(error) {
  return {
    type: GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_REJECTED,
    payload: error,
  };
}

export function getActivecontactsListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_FULFILLED,
      payload: props,
    });
  };
}

export function getActivecontactsListStatus() {
  return (dispatch) => dispatch(getActivecontactsListStatusRequest());
}
export function getActivecontactsListStatusRequest() {
  return {
    type: GetActivecontactsActions.FETCH_ACTIVE_CONTACTS_STATUS,
  };
}

///users by company//
export const GetAllUsersByCompanyIdActions = {
  FETCH_USERS_BY_COMPANY: "FETCH_USERS_BY_COMPANY",
  FETCH_USERS_BY_COMPANY_STATUS: "FETCH_USERS_BY_COMPANY_STATUS",
  FETCH_USERS_BY_COMPANY_FULFILLED: "FETCH_USERS_BY_COMPANY_FULFILLED",
  FETCH_USERS_BY_COMPANY_REJECTED: "FETCH_USERS_BY_COMPANY_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR"
};

export function getAllUsersByCompanyId(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${UserService_URL}/Apc_View/Amc_31/getAllUsersByCompanyId`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAllUsersByCompanyIdRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllUsersByCompanyIdSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllUsersByCompanyIdError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUsersByCompanyIdError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUsersByCompanyIdError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUsersByCompanyIdError(err));
              break;
            case 500:
              dispatch(getAllUsersByCompanyIdError("Server Error"));
              break;
            default:
              dispatch(getAllUsersByCompanyIdError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllUsersByCompanyIdRequest() {
  return {
    type: GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY,
    isPosting: true,
  };
}
export function getAllUsersByCompanyIdError(error) {
  return {
    type: GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_REJECTED,
    payload: error,
  };
}

export function getAllUsersByCompanyIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_FULFILLED,
      payload: props,
    });
  };
}

export function getAllUsersByCompanyIdStatus() {
  return (dispatch) => dispatch(getAllUsersByCompanyIdStatusRequest());
}
export function getAllUsersByCompanyIdStatusRequest() {
  return {
    type: GetAllUsersByCompanyIdActions.FETCH_USERS_BY_COMPANY_STATUS,
  };
}

export function validateAddSubjectRequest(access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${RequestManagement_URL}/companyRequestLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}
