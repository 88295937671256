import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Control Category

export const SaveControlCategoryActions = {
  SAVE_CONTROL_CATEGORY: "SAVE_CONTROL_CATEGORY",
  SAVE_CONTROL_CATEGORY_STATUS: "SAVE_CONTROL_CATEGORY_STATUS",
  SAVE_CONTROL_CATEGORY_SUCCESS: "SAVE_CONTROL_CATEGORY_SUCCESS",
  SAVE_CONTROL_CATEGORY_FAILURE: "SAVE_CONTROL_CATEGORY_FAILURE",
};

export function SaveControlCategoryRequest() {
  return {
    type: SaveControlCategoryActions.SAVE_CONTROL_CATEGORY,
    isPosting: true,
  };
}

export function SaveControlCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveControlCategoryFailure(error) {
  return {
    type: SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_FAILURE,
    payload: error,
  };
}

export function SaveControlCategoryError(error) {
  return {
    type: SaveControlCategoryActions.SAVE_CONTROL_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveControlCategory(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveControlCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveControlCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveControlCategorySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveControlCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveControlCategoryError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlCategoryError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlCategoryError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlCategoryError(err));
          //   break;
          // case 500:
          //   dispatch(SaveControlCategoryError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveControlCategoryError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetControlCategoryDetailsActions = {
  FETCH_CONTROL_CATEGORY: "FETCH_CONTROL_CATEGORY_DETAILS",
  FETCH_CONTROL_CATEGORY_STATUS: "FETCH_CONTROL_CATEGORY_DETAILS_STATUS",
  FETCH_CONTROL_CATEGORY_FULFILLED: "FETCH_CONTROL_CATEGORY_DETAILS_FULFILLED",
  FETCH_CONTROL_CATEGORY_REJECTED: "FETCH_CONTROL_CATEGORY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getControlCategoryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findControlCategoryById?controlCategoryId=` +
        params.id,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlCategoryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlCategoryDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlCategoryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlCategoryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlCategoryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlCategoryDetailsError(err));
              break;
            case 500:
              dispatch(getControlCategoryDetailsError("Server Error"));
              break;
            default:
              dispatch(getControlCategoryDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllControlCategory(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: params.jsonData,
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/listing/ControlCategory`,
      headers: {
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getControlCategoryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlCategoryDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlCategoryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getControlCategoryDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getControlCategoryDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getControlCategoryDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getControlCategoryDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getControlCategoryDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getControlCategoryDetailsRequest() {
  return {
    type: GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY,
    isPosting: true,
  };
}
export function getControlCategoryDetailsError(error) {
  return {
    type: GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getControlCategoryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getControlCategoryDetailsStatus() {
  return (dispatch) => dispatch(getControlCategoryDetailsStatusRequest());
}
export function getControlCategoryDetailsStatusRequest() {
  return {
    type: GetControlCategoryDetailsActions.FETCH_CONTROL_CATEGORY_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getControlCategoryDetailsErrorRequest());
}
export function getControlCategoryDetailsErrorRequest() {
  return {
    type: GetControlCategoryDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
 CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ControlCategories", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/bulkUploadExcel/ControlCategory`,
     headers: {
       "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
     },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
         dispatch(UploadexcelSuccess(response));
      } else {
          
          const error = new Error(response.statusText);
         dispatch(UploadexcelFailure(error));
         throw error;
       }
     })
     .catch((err) => {
       if (err.response) {
         switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
             break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveControlCategoryActions = {
  FETCH_ACTIVE_CONTROL_CATEGORY: "FETCH_ACTIVE_CONTROL_CATEGORY",
  FETCH_ACTIVE_CONTROL_CATEGORY_STATUS: "FETCH_ACTIVE_CONTROL_CATEGORY_STATUS",
  FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED: "FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED",
  FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED: "FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED",
};
export function getActiveControlCategoryList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/ControlCategory`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveControlCatListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveControlCategoryListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveControlCategoryListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlCategoryListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlCategoryListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlCategoryListError(err));
              break;
            case 500:
              dispatch(getActiveControlCategoryListError("Server Error"));
              break;
            default:
              dispatch(getActiveControlCategoryListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveControlCatListRequest() {
  return {
    type: GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY,
    isPosting: true,
  };
}
export function getActiveControlCategoryListError(error) {
  return {
    type: GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getActiveControlCategoryListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveControlCategoryListStatus() {
  return (dispatch) => dispatch(getActiveControlCategoryListStatusRequest());
}
export function getActiveControlCategoryListStatusRequest() {
  return {
    type: GetActiveControlCategoryActions.FETCH_ACTIVE_CONTROL_CATEGORY_STATUS,
  };
}

// //listing
// export const GetActiveControlCategoryActions = {
//   FETCH_ACTIVE_CONTROL_CATEGORY: "FETCH_ACTIVE_CONTROL_CATEGORY",
//   FETCH_ACTIVE_CONTROL_CATEGORY_STATUS: "FETCH_ACTIVE_CONTROL_CATEGORY_STATUS",
//   FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED: "FETCH_ACTIVE_CONTROL_CATEGORY_FULFILLED",
//   FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED: "FETCH_ACTIVE_CONTROL_CATEGORY_REJECTED",
// };

// export function getActiveControlCategoryList(params,access_token) {
//   return (dispatch) =>
//     axios({
//       method: "POST",
//       data:params,
//       url: `${IncidentManagementService_URL}/listing/Threat`,
//       headers: {
//         transactionId: "1",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + access_token,
//       },
//     })
//       .then((response) => {
//         dispatch(getActiveControlCatListRequest());
//         if (response.status >= 200 && response.status < 300) {
//           dispatch(getActiveControlCategoryListSuccess(response));
//           return response;
//         } else {
//           const error = new Error(response.statusText);
//           dispatch(getActiveControlCategoryListError(error));
//           throw error;
//         }
//       })
//       .catch((err) => {
//         if (err.response) {
//           switch (err.response.status) {
//             case 400:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlCategoryListError(err));
//               break;
//             case 404:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlCategoryListError(err));
//               break;
//             case 406:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlCategoryListError(err));
//               break;
//             case 500:
//               dispatch(getActiveControlCategoryListError("Server Error"));
//               break;
//             default:
//               dispatch(getActiveControlCategoryListError("Unknown Error"));
//               break;
//           }
//         } else {
//           // //dispatch(logout());
//           dispatch(clearLogoutData());
//         }
//       });
// }