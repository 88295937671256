import axios from "axios";
import { setLogoutFlag } from "../Logout/actions";

import {
  User_Service_URL,
  Common_URL,
  Auth_URL,
  UserService_URL,
  EdgeService_URL,
} from "../Url";
import HashMap from "hashmap";

var map = new HashMap();
var err;
//////////////////////////////////////////////////////////////////////////////////////////////
function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}
export const dashboardViewActions = {
  VIEW_DASHBOARD_STATUS_TRUE: "VIEW_DASHBOARD_STATUS_TRUE",
  VIEW_DASHBOARD_STATUS_FALSE: "VIEW_DASHBOARD_STATUS_FALSE",
};

export function setDashboardView(props) {
  if (props) {
    return (dispatch) => dispatch(viewDashboardStatusTrue(props));
  } else {
    return (dispatch) => dispatch(viewDashboardStatusFalse(props));
  }
}

export function viewDashboardStatusTrue() {
  return {
    type: dashboardViewActions.VIEW_DASHBOARD_STATUS_TRUE,
  };
}

export function viewDashboardStatusFalse() {
  return {
    type: dashboardViewActions.VIEW_DASHBOARD_STATUS_FALSE,
  };
}

const actions = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
};

export function verifyOTP(props) {
  //
  let values = {
    deviceKey: "key",
    isForgotPassword: props.isForgotPassword,
    password: props.password,
    role: "COMPANYADMIN",
    sourceId: "COMPANY",
    username: props.loginId,
  };
  return (dispatch) =>
    axios({
      method: "post",
      url: `${Auth_URL}/loginWithLoginId`,
      data: values,
    })
      .then((response) => {
        dispatch(verifyOTPRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(verifyOTPSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(verifyOTPError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(verifyOTPError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(verifyOTPError(err));
          //     break;
          //   case 401:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(verifyOTPError(err));
          //     break;
          //   case 403:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(verifyOTPError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(verifyOTPError(err));
          //     break;
          //   case 500:
          //     dispatch(verifyOTPError("Server Error"));
          //     break;
          //   default:
          //     dispatch(verifyOTPError("Unknown Error"));
          //     break;
          // }
        } else if (!err.response) {
          dispatch(verifyOTPError("Internal Server Error!"));
        }
      });
}

export function verifyOTPRequest() {
  return {
    type: actions.VERIFY_OTP_REQUEST,
    isPosting: true,
  };
}
export function verifyOTPError(error) {
  return {
    type: actions.VERIFY_OTP_ERROR,
    payload: error,
  };
}

export function verifyOTPSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: actions.VERIFY_OTP_SUCCESS,
      payload: props,
    });
  };
}
////Verify otp

export function login(props) {
  // debugger;
  let values = {
    deviceKey: "key",
    isForgotPassword: props.isForgotPassword,
    password: props.password,
    role: "COMPANYADMIN",
    sourceId: "COMPANY",
    username: props.loginId,
  };
  return (dispatch) =>
    axios({
      method: "post",
      url: `${Auth_URL}/loginWithLoginId`,
      data: values,
    })
      .then((response) => {
        // debugger;
        if (response.status >= 200 && response.status < 300) {
          // const token = response.data.data.access_token;
          // const userId = response.data.userdto.userId;
          dispatch(loginSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);

          dispatch(loginError(error));
          throw error;
        }
      })
      .catch((err) => {
        // debugger;
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(loginError(err));
              break;
            case 401:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(loginError(err));
              break;
            case 403:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(loginError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(loginError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(loginError(err));
              break;
            // dispatch(loginError("Server Error"));
            // break;
            default:
              dispatch(loginError("Unknown Error"));
              break;
          }
        } else {
          dispatch(loginError("Internal Server Error!"));
        }
      });
}

export function loginRequest() {
  return {
    type: actions.LOGIN,
    isPosting: true,
  };
}
export function loginError(error) {
  return {
    type: actions.LOGIN_ERROR,
    payload: error,
  };
}

export function logout() {
  return {
    type: actions.LOGOUT,
    payload: "",
  };
}

export function loginSuccess(props) {
  // debugger;
  return (dispatch) => {
    dispatch({
      type: actions.LOGIN_SUCCESS,
      payload: props,
    });
  };
}

// IS FIRST TIME LOGIN

export const IsFirstTimeLoginActions = {
  IS_FIRST_TIME_LOGIN: "IS_FIRST_TIME_LOGIN",
  IS_FIRST_TIME_LOGIN_SUCCESS: "IS_FIRST_TIME_LOGIN_SUCCESS",
  IS_FIRST_TIME_LOGIN_ERROR: "IS_FIRST_TIME_LOGIN_ERROR",
};

export function isFirstTimeLogin(props) {
  //
  let values = {
    deviceKey: "key",
    isForgotPassword: props.isForgotPassword,
    password: props.password,
    role: "COMPANYADMIN",
    sourceId: "COMPANY",
    username: props.loginId,
  };
  return (dispatch) =>
    axios({
      method: "post",
      url: `${Auth_URL}/isFirstTimeLoginWithLoginId`,
      data: values,
    })
      .then((response) => {
        dispatch(isFirstTimeLoginRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(isFirstTimeLoginSuccess(response));
          dispatch(setLogoutFlag());
          return response;
        } else if (response.status === 401) {
          // return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(isFirstTimeLoginError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(isFirstTimeLoginError(err));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(isFirstTimeLoginError(err));
          //     break;
          //   case 401:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(isFirstTimeLoginError(err));
          //     break;
          //   case 403:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(isFirstTimeLoginError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(isFirstTimeLoginError(err));
          //     break;
          //   case 500:
          //     dispatch(isFirstTimeLoginError("Server Error"));
          //     break;
          //   default:
          //     dispatch(isFirstTimeLoginError("Unknown Error"));
          //     break;
          // }
        } else if (!err.response) {
          dispatch(isFirstTimeLoginError("Internal Server Error!"));
        }
      });
}

export function isFirstTimeLoginRequest() {
  return {
    type: IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN,
    isPosting: true,
  };
}
export function isFirstTimeLoginError(error) {
  return {
    type: IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN_ERROR,
    payload: error,
  };
}

export function isFirstTimeLoginSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN_SUCCESS,
      payload: props,
    });
  };
}

// logout: () => ({
//   type: actions.LOGOUT
// });
////SET PASSWORD////
export const resetPassActions = {
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  // LOGOUT: "LOGOUT"
};

export function resetPass(props) {
  // debugger;
  var values = {
    newPassword: props.newPassword,
    confirmNewPassword: props.confirmPassword,
  };
  return (dispatch) =>
    axios({
      method: "POST",
      url: `${UserService_URL}/resetPassword` /*?newPassword=`  +
        props.newPassword +
        `&confirmPassword=` +
        props.confirmPassword +
        `&access_token=` +
        props.access_token */,
      data: values,
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(resetPassRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(resetPassSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(resetPassError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(resetPassError(err));
              break;
            case 401:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(resetPassError(err));
              break;
            case 403:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(isFirstTimeLoginError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(resetPassError(err));
              break;
            case 500:
              dispatch(resetPassError("Server Error"));
              break;
            default:
              dispatch(resetPassError("Unknown Error"));
              break;
          }
        } else if (!err.response) {
          dispatch(resetPassError("Internal Server Error!"));
        }
      });
}

export function resetPassSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: resetPassActions.RESET_PASSWORD_SUCCESS,
      payload: props,
    });
  };
}
export function resetPassRequest() {
  return {
    type: resetPassActions.RESET_PASSWORD,
    isPosting: true,
  };
}
export function resetPassError(error) {
  return {
    type: resetPassActions.RESET_PASSWORD_ERROR,
    payload: error,
  };
}
export default actions;

// get user details by id

export const UpdateUserDetailsActions = {
  SAVE_USER_DETAILS: "SAVE_USER_DETAILS",
  SAVE_USER_DETAILS_STATUS: "SAVE_USER_DETAILS_STATUS",
  SAVE_USER_DETAILS_FULFILLED: "SAVE_USER_DETAILS_FULFILLED",
  SAVE_USER_DETAILS_REJECTED: "SAVE_USER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function updateUserDetails(params) {
  var imageId = 0;
  if (params.fileName != null) {
    imageId = params.id;
  } else {
    imageId = null;
  }
  var values = {
    dob: params.dob || null,
    currency: params.currency || null,
    firstName: params.userFirstName || null,
    language: params.language || null,
    lastName: params.userLastName || null,
    newPassword: params.newPassword || null,
    oldPassword: params.oldPassword || null,
    timeZone: params.timeZone || null,
    mobileNo: params.mobileNo || null,
    profileImage:
      params.base64 != null && params.fileName != null
        ? {
            base64: params.base64 || null,
            documentName: params.fileName || null,
            id: imageId,
          }
        : null,
  };
  return (dispatch) =>
    axios({
      method: "post",
      data: values,
      url: `${UserService_URL}/updateProfile`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(updateUserDetailsRequest());
        if (response) {
          if (response.status >= 200 && response.status < 300) {
            dispatch(updateUserDetailsSuccess(response));
            return response;
          } else {
            const error = new Error(response.statusText);
            dispatch(updateUserDetailsError(error));
            throw error;
          }
        }
      })
      .catch((err) => {
        // debugger;
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateUserDetailsError(err));
              break;
            case 401:
              return err.response;
              // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              // map.forEach(ele => (err = ele));

              // dispatch(updateUserDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateUserDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateUserDetailsError(err));
              break;
            case 500:
              dispatch(updateUserDetailsError("Server Error"));
              break;
            default:
              dispatch(updateUserDetailsError("Unknown Error"));
              break;
          }
        } else {
          dispatch(updateUserDetailsError("Server Error"));
          // //dispatch(logout());
        }
      });
}

export function updateUserDetailsRequest() {
  return {
    type: UpdateUserDetailsActions.SAVE_USER_DETAILS,
    isPosting: true,
  };
}
export function updateUserDetailsError(error) {
  return {
    type: UpdateUserDetailsActions.SAVE_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function updateUserDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UpdateUserDetailsActions.SAVE_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function updateUserDetailsStatus() {
  return (dispatch) => dispatch(updateUserDetailsStatusRequest());
}
export function updateUserDetailsStatusRequest() {
  return {
    type: UpdateUserDetailsActions.SAVE_USER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(updateUserDetailsErrorRequest());
}
export function updateUserDetailsErrorRequest() {
  return {
    type: UpdateUserDetailsActions.SET_EDIT_ERROR,
  };
}

/////////////////CUSTOM_LOGOUT///////////////
export const customLogoutActions = {
  CUSTOM_LOGOUT: "CUSTOM_LOGOUT",
  CUSTOM_LOGOUT_SUCCESS: "CUSTOM_LOGOUT_SUCCESS",
  CUSTOM_LOGOUT_ERROR: "CUSTOM_LOGOUT_ERROR",
  LOGOUT_UNAUTHORIZED: "LOGOUT_UNAUTHORIZED",
};

export function customLogout(props) {
  //
  // let values = {
  //  access_token:props.access_token
  // };
  return (dispatch) =>
    axios({
      method: "post",
      url: `${UserService_URL}/customLogoutNew`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
      },
      //data: values
    })
      .then((response) => {
        // debugger;
        // dispatch(customLogoutRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(customLogoutSuccess(response));

          return response;
        } else if (response.status === 401) {
          dispatch(logoutOnUnauthorized());
        } else {
          const error = new Error(response.statusText);
          dispatch(customLogoutError(error));

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(customLogoutError(err));
              break;
            case 401:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(logoutOnUnauthorized());
              break;
            case 403:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(customLogoutError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(customLogoutError(err));
              break;
            case 500:
              dispatch(customLogoutError("Server Error"));
              break;
            default:
              dispatch(customLogoutError("Unknown Error"));
              break;
          }
        } else if (err.response) {
          dispatch(customLogoutError("Internal Server Error!"));
          dispatch(setLogoutFlag());
        } else {
          dispatch(logoutOnUnauthorized());
          dispatch(setLogoutFlag());
        }
      });
}
export function logoutOnUnauthorized(props) {
  return (dispatch) => {
    dispatch({
      type: actions.LOGOUT,
      payload: props,
    });
  };
}

export function customLogoutSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: customLogoutActions.CUSTOM_LOGOUT_SUCCESS,
      payload: props,
    });
  };
}
export function customLogoutRequest() {
  return {
    type: customLogoutActions.CUSTOM_LOGOUT,
    isPosting: true,
  };
}
export function customLogoutError(error) {
  return {
    type: customLogoutActions.CUSTOM_LOGOUT_ERROR,
    payload: error,
  };
}

// clear AuthId  details
export const ClearAuthActions = {
  CLEAR_AUTH_DETAILS: "CLEAR_AUTH_DETAILS",
};

export function clearAuthhData() {
  return (dispatch) => dispatch(clearAuthDetails());
}

export function clearAuthDetails() {
  return {
    type: ClearAuthActions.CLEAR_AUTH_DETAILS,
  };
}

//////////////Get User Permission////////////////////////////////

export const UserPermissionActions = {
  FETCH_USER_PERMISSION: "FETCH_USER_PERMISSION",
  FETCH_USER_PERMISSION_FULLFIELD: "FETCH_USER_PERMISSION_FULLFIELD",
  FETCH_USER_PERMISSION_REJECTED: "FETCH_USER_PERMISSION_REJECTED",
};

export function getUserPermission(props) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${EdgeService_URL}/getUserPermissions`,
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(FetchPermissionRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(FetchPermissionSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(FetchPermissionError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(FetchPermissionError(err));
              break;
            case 401:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(FetchPermissionError(err));
              break;
            case 403:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(FetchPermissionError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(FetchPermissionError(err));
              break;
            case 500:
              dispatch(FetchPermissionError("Server Error"));
              break;
            default:
              dispatch(FetchPermissionError("Unknown Error"));
              break;
          }
        } else if (!err.response) {
          dispatch(FetchPermissionError("Internal Server Error!"));
        }
      });
}

export function FetchPermissionRequest() {
  return {
    type: UserPermissionActions.FETCH_USER_PERMISSION,
    isPosting: true,
  };
}
export function FetchPermissionError(error) {
  return {
    type: UserPermissionActions.FETCH_USER_PERMISSION_REJECTED,
    payload: error,
  };
}

export function FetchPermissionSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UserPermissionActions.FETCH_USER_PERMISSION_FULLFIELD,
      payload: props,
    });
  };
}
