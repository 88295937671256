import { message } from "antd";
import { Map } from "immutable";
import DOMPurify from "dompurify";
export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function HandleDebounceBeforeEvent(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function removeHTMLTags(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const textContent = doc.body.textContent || "";
  return textContent.trim();
}

export function removeLeadingTrailingNbsp(str) {
  if (str) {
    // Remove &nbsp; from the start and end of the string
    let trimmed = str.replace(/^(&nbsp;|<br>)+|(&nbsp;|<br>)+$/g, "");

    // Remove &nbsp; between words (more than one occurrence)
    trimmed = trimmed.replace(/(&nbsp;|<br>)+/g, " ");

    return trimmed;
  } else {
    return str;
  }
}

export const downloadFile = ({ base64Data, docType = "", docName = "" }) => {
  if (!docType) {
    message.error("Invalid Document Type", 5);
    return null;
  }
  let fileType = "";
  if (docType.includes("ppt")) {
    fileType = "application/vnd.ms-powerpoint";
  } else if (docType.includes("pdf")) {
    fileType = "application/pdf";
  } else if (docType.includes("doc")) {
    fileType = "application/msword";
  } else {
    fileType = `image/${docType}`;
  }
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${docName}.${docType}`;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
};

const decodeHTMLEntities = (text) => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
};

export const sanitizeFieldLabel = (label) => {
  // Decode HTML entities first
  const decodedLabel = decodeHTMLEntities(label);
  // Sanitize the decoded label using DOMPurify
  return DOMPurify.sanitize(decodedLabel, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
};

export const generateUniqueTabName = (panes, baseName) => {
  if (panes && panes.length > 0) {
    let index = 1;
    const existingFolders = panes.map((ele) => ele.title);
    let folderName = `${baseName}_${index}`;

    while (existingFolders.includes(folderName)) {
      index++;
      folderName = `${baseName}_${index}`;
    }
    return parseInt(folderName.replace("Section_", ""));
  } else {
    return 1;
  }
};

export const renderAssetOrRecord = (data) => {
  var dataDisplay = [];
  if (data !== null && data !== undefined) {
    dataDisplay = data.split(",");
    return dataDisplay.join("\n");
  }
};

export const formBuilderCommonOptions = {
  fieldRemoveWarn: true, // or defaults to false
  typeUserDisabledAttrs: {
    select: ["multiple"],
    file: ["subtype", "multiple"],
    textarea: ["maxlength", "max", "rows"],
    date: ["value"],
  },
  disabledActionButtons: ["data", "clear", "save"],
  disableFields: ["autocomplete", "hidden", "button"],
  disabledAttrs: ["className", "other", "multiple", "toggle", "step", "inline"],
  i18n: {
    locale: "en-US",
    location: "https://formbuilder.online/assets/lang/",
    extension: ".lang",
  },
};

export const toCheckSequenceIdExist = (arrSeqeunce) => {
  let num = "";
  if (!Array.isArray(arrSeqeunce)) {
    return num;
  }

  const seqValue = arrSeqeunce.map((ele) => ele.tabSequence);
  num = Math.max(...seqValue) + 1;
  return num;
};

export const masterListConstant = [
  "assetMaster",
  "assetType",
  "hostingProviderType",
  "hostingProviders",
  "dataSubjectGroups",
  "dataType",
  "dataSubjectElements",
  "dataSubjectVolumeRange",
  "dataDisposalMethods",
  "securityMeasureTypes",
  "securityMeasures",
  "regulationMaster",
  "dataProcessorCategories",
  "dataProcessors",
  "dataSubProcessors",
  "dataRecipients",
  "dataCustodians",
  "legalBasis",
  "purposeOfProcess",
  "userMaster",
  "cityMaster",
  "countryMaster",
];
