import { Layout, Modal, Form, message } from "antd";
import IntlMessages from "../../components/utility/intlMessages";
import React, { Component } from "react";
import { clearAuthDetails } from "../../redux/auth/actions";
import FormattedMessage from "../../components/utility/intlMessages";
import {
  setLogoutFlag,
  modalOpenFlag,
  modalCloseFlag,
} from "../../redux/Logout/actions";

import { connect } from "react-redux";
import { Redirect } from "react-router";

class CustomLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleDrawer: true,
      showDataRecipient: false,
      formLabel: true,
      modalVisibile: true,
    };
  }

  componentDidMount = () => {
    //this.props.modalOpenFlag();
  };

  handleCancel = () => {
    this.props.setLogoutFlag();
    this.setState({
      modalVisibile: false,
    });
  };

  handleOk = () => {
    this.setState({
      redirectToSignin: true,
    });
    this.props.clearAuthDetails();
    this.props.setLogoutFlag();
  };

  render() {
    
    if (this.state.redirectToSignin === true) {
      return <Redirect to={"/signin"} />;
    }
    return (
      <div className="main-login-modal">
        <Modal
          title=""
          visible={this.state.modalVisibile}
          onOk={this.handleOk}
          className="main-login-modal"
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
          //onCancel={this.handleCancel}
          okText={<FormattedMessage id="text.Ok" />}
        >
          <p>
            <IntlMessages id={"sidebar.logout"} />
          </p>
        </Modal>
      </div>
    );
  }
}

const WrappedCustomLogout = Form.create()(CustomLogout);

export default connect((state) => ({}), {
  clearAuthDetails,
  setLogoutFlag,
  modalOpenFlag,
  modalCloseFlag,
})(WrappedCustomLogout);
