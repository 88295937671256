import {
  SaveDataSubActions,
  GetDataSubDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataSubjectActions
} from "./actions";

const initState = {
  dataSubSuccessMessage: null,
  dataSubErrorMessage: null,
  dataSubStatus: null,
  dataSubMaster: [],
  dataSubData: null,
  ActiveDataSubjectList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataSubActions.SAVE_DATA_SUB:
      return {
        ...state,
        isLoading: true,
        dataSubErrorMessage: null,
        modalActive: false
      };
    case SaveDataSubActions.SAVE_DATA_SUB_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubStatus: payload.status,
        dataSubErrorMessage: null,
        dataSubSuccessMessage: "Data Subject added successfully!"
      };

    case SaveDataSubActions.SAVE_DATA_SUB_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubStatus: null,
        dataSubErrorMessage: payload
      };
    case SaveDataSubActions.SAVE_DATA_SUB_STATUS:
      return {
        ...state,
        dataSubStatus: 0,
        dataSubErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSubDetailsActions.FETCH_DATA_SUB_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubErrorMessage: null,
        modalActive: false,
        dataSubData: null
      };
    case GetDataSubDetailsActions.FETCH_DATA_SUB_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubData: payload.data,
        dataSubDataStatus: payload.status,
        dataSubErrorMessage: null
      };
    case GetDataSubDetailsActions.FETCH_DATA_SUB_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubErrorMessage: payload,
        dataSubData: null
      };
    case GetDataSubDetailsActions.FETCH_DATA_SUB_DETAILS_STATUS:
      return {
        ...state,
        dataSubData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataSubjectActions.FETCH_ACTIVE_DATA_SUB:
      return {
        ...state,
        isLoading: true,
        dataSubErrorMessage: null,
        modalActive: false,
        ActiveDataSubjectList: null
      };
    case GetActiveDataSubjectActions.FETCH_ACTIVE_DATA_SUB_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubjectList: payload.data,
        dataSubErrorMessage: null
      };
    case GetActiveDataSubjectActions.FETCH_ACTIVE_DATA_SUB_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubErrorMessage: payload,
        ActiveDataSubjectList: null
      };
    case GetActiveDataSubjectActions.FETCH_ACTIVE_DATA_SUB_STATUS:
      return {
        ...state,
        ActiveDataSubjectList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSub: null,
        dataSubStatus: null,
        dataSubSuccessMessage: null,
        GetdataSubStatus: null,
        dataSubData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
