import {
  savePurposeActions,
  deletePurposeActions,
  GetPurposeDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActivePurposeActions
} from "./actions";

const initState = {
  purposeProcSuccessMessage: null,
  purposeProcErrorMessage: null,
  purposeProcStatus: null,
  purposeProcMaster: [],
  purposeProcData: null,
  ActivePurposeProcList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case savePurposeActions.SAVE_PURPOSE:
      return {
        ...state,
        isLoading: true,
        purposeProcErrorMessage: null,
        modalActive: false
      };
    case savePurposeActions.SAVE_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        PurposeProcStatus: payload.status,
        purposeProcErrorMessage: null,
        purposeProcSuccessMessage: "Asset Type added successfully!"
      };

    case savePurposeActions.SAVE_PURPOSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        PurposeProcStatus: null,
        purposeProcErrorMessage: payload
      };
    case savePurposeActions.SAVE_PURPOSE_STATUS:
      return {
        ...state,
        PurposeProcStatus: 0,
        purposeProcErrorMessage: null
      };

    // Delete Asset Type

    case deletePurposeActions.DELETE_PURPOSE:
      return {
        ...state,
        isLoading: true,
        purposeProcErrorMessage: null,
        modalActive: false
      };
    case deletePurposeActions.DELETE_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        PurposeProcStatus: payload.status,
        purposeProcErrorMessage: null,
        purposeProcSuccessMessage: "Asset Type Deleted successfully!"
      };

    case deletePurposeActions.DELETE_PURPOSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        PurposeProcStatus: null,
        purposeProcErrorMessage: payload
      };
    case deletePurposeActions.DELETE_PURPOSE_STATUS:
      return {
        ...state,
        PurposeProcStatus: 0,
        purposeProcErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS:
      return {
        ...state,
        isLoading: true,
        purposeProcErrorMessage: null,
        modalActive: false,
        PurposeProcData: null
      };
    case GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        PurposeProcData: payload.data,
        PurposeProcDataStatus: payload.status,
        purposeProcErrorMessage: null
      };
    case GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        purposeProcErrorMessage: payload,
        PurposeProcData: null
      };
    case GetPurposeDetailsActions.FETCH_PURPOSE_DETAILS_STATUS:
      return {
        ...state,
        PurposeProcData: null
      };
    ///Active purposeProcs
    case GetActivePurposeActions.FETCH_ACTIVE_PURPOSE:
      return {
        ...state,
        isLoading: true,
        purposeProcErrorMessage: null,
        modalActive: false,
        ActivePurposeProcList: null
      };
    case GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActivePurposeProcList: payload.data,
        purposeProcErrorMessage: null
      };
    case GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_REJECTED:
      return {
        ...state,
        isLoading: false,
        purposeProcErrorMessage: payload,
        ActivePurposeProcList: null
      };
    case GetActivePurposeActions.FETCH_ACTIVE_PURPOSE_STATUS:
      return {
        ...state,
        ActivePurposeProcList: null
      };
    // clear purposeProc details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetPurposeProc: null,
        PurposeProcStatus: null,
        purposeProcSuccessMessage: null,
        GetPurposeProcStatus: null,
        PurposeProcData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
