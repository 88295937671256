import {
  saveBreachSourceActions,
  deleteBreachSourceActions,
  GetBreachSourceDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveBreachSourceTypeActions,
} from "./actions";

const initState = {
  requestSuccessMessage: null,
  breachSourceErrorMessage: null,
  BreachSourceStatus: null,
  BreachSourceMaster: [],
  BreachSourceData: null,
  ActiveBreachSourceList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveBreachSourceActions.SAVE_BREACH_SOURCE:
      return {
        ...state,
        isLoading: true,
        breachSourceErrorMessage: null,
        modalActive: false,
      };
    case saveBreachSourceActions.SAVE_BREACH_SOURCE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachSourceStatus: payload.status,
        breachSourceErrorMessage: null,
        requestSuccessMessage: "Breach Source added successfully!",
      };

    case saveBreachSourceActions.SAVE_BREACH_SOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        BreachSourceStatus: null,
        breachSourceErrorMessage: payload,
      };
    case saveBreachSourceActions.SAVE_BREACH_SOURCE_STATUS:
      return {
        ...state,
        BreachSourceStatus: 0,
        breachSourceErrorMessage: null,
      };

    // Delete Breach Source

    case deleteBreachSourceActions.DELETE_BREACH_SOURCE:
      return {
        ...state,
        isLoading: true,
        breachSourceErrorMessage: null,
        modalActive: false,
      };
    case deleteBreachSourceActions.DELETE_BREACH_SOURCE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachSourceStatus: payload.status,
        breachSourceErrorMessage: null,
        requestSuccessMessage: "Breach Source Deleted successfully!",
      };

    case deleteBreachSourceActions.DELETE_BREACH_SOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        BreachSourceStatus: null,
        breachSourceErrorMessage: payload,
      };
    case deleteBreachSourceActions.DELETE_BREACH_SOURCE_STATUS:
      return {
        ...state,
        BreachSourceStatus: 0,
        breachSourceErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS:
      return {
        ...state,
        isLoading: true,
        breachSourceErrorMessage: null,
        modalActive: false,
        BreachSourceData: null,
      };
    case GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        BreachSourceData: payload.data,
        BreachSourceDataStatus: payload.status,
        breachSourceErrorMessage: null,
      };
    case GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        breachSourceErrorMessage: payload,
        BreachSourceData: null,
      };
    case GetBreachSourceDetailsActions.FETCH_BREACH_SOURCE_DETAILS_STATUS:
      return {
        ...state,
        BreachSourceData: null,
      };
    ///Active requests
    case GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE:
      return {
        ...state,
        isLoading: true,
        breachSourceErrorMessage: null,
        modalActive: false,
        ActiveBreachSourceList: null,
      };
    case GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveBreachSourceList: payload.data,
        breachSourceErrorMessage: null,
      };
    case GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_REJECTED:
      return {
        ...state,
        isLoading: false,
        breachSourceErrorMessage: payload,
        ActiveBreachSourceList: null,
      };
    case GetActiveBreachSourceTypeActions.FETCH_ACTIVE_BREACH_SOURCE_STATUS:
      return {
        ...state,
        ActiveBreachSourceList: null,
      };
    // clear breachSource details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetBreachSource: null,
        BreachSourceStatus: null,
        requestSuccessMessage: null,
        GetBreachSourceStatus: null,
        BreachSourceData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
