import {
  SaveFormBuilderActions,
  GetSurveyDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveFormBuilderActions,
  SaveSurveyActions,
  SaveAssessmentElementActions,
  CopyFormDetailsActions,
  SaveFormDetailsProps,
  TemplateDetailsActions,
  GetSurveyReviewActions,
  CloseSurveyActions,
  selfReviewActions,
  assessmentTypeActions,
  GetSurveyUsersActions,
  GetSurveyForExternalUserActions,
  GetSurveyByRulesActions,
  GetSurveyMasterActions,
  SaveSurveyMasterActions,
} from "./actions";

const initState = {
  formBuilderSuccessMessage: null,
  formBuilderErrorMessage: null,
  formBuilderStatus: null,
  formBuilderMaster: [],
  formBuilderData: null,
  reviewMasterData: null,
  ExtFormbuilderData: null,
  ActiveFormBuilderList: null,
  surveyStatus: null,
  surveyErrorMessage: null,
  surveySuccessMessage: null,
  SaveSurveyData: null,
  CloseSurveyData: null,
  CopyDetails: false,
  TemplateSelected: false,
  TemplateFormData: null,
  formbuilderResponse: null,
  SelfReview: null,
  AssessmentType: null,
  SurveyUsers: null,
  modifyTemplate: false,
  SaveSurveyMasterData: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveFormBuilderActions.SAVE_FORMBUILDER:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
      };
    case SaveFormBuilderActions.SAVE_FORMBUILDER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formBuilderStatus: payload.status,
        formBuilderErrorMessage: null,
        formBuilderSuccessMessage: "Survey added successfully!",
      };

    case SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        formBuilderStatus: null,
        formBuilderErrorMessage: payload,
      };
    case SaveFormBuilderActions.SAVE_FORMBUILDER_STATUS:
      return {
        ...state,
        formBuilderStatus: 0,
        formBuilderErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        formBuilderData: null,
      };
    case GetSurveyDetailsActions.MODIFY_TEMPLATE:
      return {
        ...state,
        modifyTemplate: payload,
      };
    case GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formBuilderData: payload.data,
        formBuilderDataStatus: payload.status,
        formBuilderErrorMessage: null,
      };
    case GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        formBuilderData: null,
      };
    case GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_STATUS:
      return {
        ...state,
        formBuilderData: null,
      };

      /// get master

      case GetSurveyMasterActions.FETCH_MASTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        reviewMasterData: payload.data,
        reviewMasterDataStatus: payload.status,
        formBuilderErrorMessage: null,
      };
    case GetSurveyMasterActions.FETCH_MASTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        reviewMasterData: null,
      };
    case GetSurveyMasterActions.FETCH_MASTER_DETAILS_STATUS:
      return {
        ...state,
        reviewMasterData: null,
      };

    // Ext data

    case GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        ExtFormbuilderData: null,
      };
    case GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ExtFormbuilderData: payload.data,
        formBuilderDataStatus: payload.status,
        formBuilderErrorMessage: null,
      };
    case GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        ExtFormbuilderData: null,
      };
    // case GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED:
    //   return {
    //     ...state,
    //     formBuilderData: null,
    //   };
    ///Active DATA DISPOSAL
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        ActiveFormBuilderList: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveFormBuilderList: payload.data,
        formBuilderErrorMessage: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        ActiveFormBuilderList: null,
      };
    case GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_STATUS:
      return {
        ...state,
        ActiveFormBuilderList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetformBuilder: null,
        formBuilderStatus: null,
        formBuilderSuccessMessage: null,
        GetformBuilderStatus: null,
        formBuilderData: null,
        SaveSurveyData: null,
        CopyDetails: false,
        TemplateSelected: false,
        TemplateFormData: null,
      };

    case CopyFormDetailsActions.COPY_FORM_DETAILS:
      // debugger;
      return {
        ...state,
        CopyDetails: true,
        isDraft: payload,
      };

    case selfReviewActions.SELF_REVIEW_ACTIONS:
      // debugger;
      return {
        ...state,
        SelfReview: payload,
      };

    case assessmentTypeActions.ASSESSMENT_TYPE_ACTIONS:
      // debugger;
      return {
        ...state,
        AssessmentType: payload,
      };

    case TemplateDetailsActions.TEMPLATE_FORM_DETAILS:
      return {
        ...state,
        TemplateSelected: true,
      };

    case SaveFormDetailsProps.SAVE_FORM_DETAILS:
      return {
        ...state,
        TemplateFormData: payload,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */
    case SaveSurveyActions.SAVE_SURVEY:
      return {
        ...state,
        isLoading: true,
        surveyErrorMessage: null,
        modalActive: false,
      };
    case SaveSurveyActions.SAVE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveSurveyData: payload.data,
        surveyStatus: payload.status,
        surveyErrorMessage: null,
        surveySuccessMessage: "Survey added successfully!",
      };

    case SaveSurveyActions.SAVE_SURVEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        surveyStatus: null,
        surveyErrorMessage: payload,
        SaveSurveyData: null,
      };
    case SaveSurveyActions.SAVE_SURVEY_STATUS:
      return {
        ...state,
        surveyStatus: 0,
        surveyErrorMessage: null,
      };

      /// master 

      case SaveSurveyMasterActions.SAVE_SURVEY_MASTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SaveSurveyMasterData: payload.data,
        surveyStatus: payload.status,
        surveyErrorMessage: null,
      };

    case SaveSurveyMasterActions.SAVE_SURVEY_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        surveyStatus: null,
        surveyErrorMessage: payload,
        SaveSurveyMasterData: null,
      };

    case CloseSurveyActions.CLOSE_SURVEY:
      return {
        ...state,
        isLoading: true,
        surveyErrorMessage: null,
        modalActive: false,
      };
    case CloseSurveyActions.CLOSE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        CloseSurveyData: payload.data,
        surveyStatus: payload.status,
        surveyErrorMessage: null,
        surveySuccessMessage: "Survey Closed successfully!",
      };

    case CloseSurveyActions.CLOSE_SURVEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        surveyStatus: null,
        surveyErrorMessage: payload,
        SaveSurveyData: null,
      };
    case CloseSurveyActions.CLOSE_SURVEY_STATUS:
      return {
        ...state,
        surveyStatus: 0,
        surveyErrorMessage: null,
      };
    case GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
      };
    case GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formbuilderResponse: payload,
        formBuilderErrorMessage: null,
        // formBuilderSuccessMessage: "Survey added successfully!",
      };
    case GetSurveyUsersActions.GET_SURVEY_USERS:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
        SurveyUsers: null,
      };
    case GetSurveyUsersActions.GET_SURVEY_USERS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        SurveyUsers: payload.data,
        // formBuilderErrorMessage: null,
      };
    case GetSurveyUsersActions.GET_SURVEY_USERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        SurveyUsers: null,
      };
    ///////////Get survey By rules///
    case GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES:
      return {
        ...state,
        isLoading: true,
        formBuilderErrorMessage: null,
        modalActive: false,
        formBuilderData: null,
      };
    case GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        formBuilderData: payload.data,
        formBuilderDataStatus: payload.status,
        formBuilderErrorMessage: null,
      };
    case GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_REJECTED:
      return {
        ...state,
        isLoading: false,
        formBuilderErrorMessage: payload,
        formBuilderData: null,
      };
    case GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_STATUS:
      return {
        ...state,
        formBuilderData: null,
      };

    default:
      return state;
  }
}
