import axios from "axios";
import {
  AssetManagementService_URL,
  SystemService_URL,
  RopaManagementService_URL,
} from "../Url";
import { clearLogoutData } from "../Logout/actions";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataProcessorActions = {
  SAVE_DATA_PROCESSOR: "SAVE_DATA_PROCESSOR",
  SAVE_DATA_PROCESSOR_STATUS: "SAVE_DATA_PROCESSOR_STATUS",
  SAVE_DATA_PROCESSOR_SUCCESS: "SAVE_DATA_PROCESSOR_SUCCESS",
  SAVE_DATA_PROCESSOR_FAILURE: "SAVE_DATA_PROCESSOR_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataProcessorActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataProcessorRequest() {
  return {
    type: SaveDataProcessorActions.SAVE_DATA_PROCESSOR,
    isPosting: true,
  };
}

export function SaveDataProcessorSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataProcessorActions.SAVE_DATA_PROCESSOR_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataProcessorFailure(error) {
  return {
    type: SaveDataProcessorActions.SAVE_DATA_PROCESSOR_FAILURE,
    payload: error,
  };
}

export function SaveDataProcessorError(error) {
  return {
    type: SaveDataProcessorActions.SAVE_DATA_PROCESSOR_FAILURE,
    payload: error,
  };
}

export function saveDataProcessor(props) {
  
  var value = {
    id: props.dataProcessorId || 0,
    name: props.dataProcessorName,
    addressLine1: props.addressLine1,
    dataCategoryId:props.dataCategoryId,
    addressLine2: props.addressLine2,
    pincode: props.pincode,
    // stateId:props.stateId,
    cityId: props.cityId,
    countryId: props.countryId,
    ropaContactIds: props.dataContactId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_24/saveDataProcessor`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataProcessorRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataProcessorSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          // dispatch(SaveLegalBasisError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          // case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveLegalBasisError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveLegalBasisError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveLegalBasisError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataProcessorDetailsActions = {
  FETCH_DATA_PROCESSOR_DETAILS: "FETCH_DATA_PROCESSOR_DETAILS",
  FETCH_DATA_PROCESSOR_DETAILS_STATUS: "FETCH_DATA_PROCESSOR_DETAILS_STATUS",
  FETCH_DATA_PROCESSOR_DETAILS_FULFILLED:
    "FETCH_DATA_PROCESSOR_DETAILS_FULFILLED",
  FETCH_DATA_PROCESSOR_DETAILS_REJECTED:
    "FETCH_DATA_PROCESSOR_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataProcessorDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_24/getDataProcessorById?id=` +
        params.dataProcessorId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataProcessorDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataProcessorDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          // dispatch(getLegalBasisDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getLegalBasisDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getLegalBasisDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getLegalBasisDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataProcessorDetailsRequest() {
  return {
    type: GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS,
    isPosting: true,
  };
}
export function getDataProcessorDetailsError(error) {
  return {
    type: GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataProcessorDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataProcessorDetailsStatus() {
  return (dispatch) => dispatch(getDataProcessorDetailsStatusRequest());
}
export function getDataProcessorDetailsStatusRequest() {
  return {
    type: GetDataProcessorDetailsActions.FETCH_DATA_PROCESSOR_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataProcessorDetailsErrorRequest());
}
export function getDataProcessorDetailsErrorRequest() {
  return {
    type: GetDataProcessorDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RopaManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataProcessorActions = {
  FETCH_ACTIVE_DATA_PROCESSOR: "FETCH_ACTIVE_DATA_PROCESSOR",
  FETCH_ACTIVE_DATA_PROCESSOR_STATUS: "FETCH_ACTIVE_DATA_PROCESSOR_STATUS",
  FETCH_ACTIVE_DATA_PROCESSOR_FULFILLED:
    "FETCH_ACTIVE_DATA_PROCESSOR_FULFILLED",
  FETCH_ACTIVE_DATA_PROCESSOR_REJECTED: "FETCH_ACTIVE_DATA_PROCESSOR_REJECTED",
};
export function getActiveDataProcessorList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RopaManagementService_URL}/findAllDataProcessor?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataProcessorListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataProcessorListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataProcessorListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataProcessorListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataProcessorListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataProcessorListError(err));
              break;
            case 500:
              dispatch(getActiveDataProcessorListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataProcessorListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataProcessorListRequest() {
  return {
    type: GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR,
    isPosting: true,
  };
}
export function getActiveDataProcessorListError(error) {
  return {
    type: GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_REJECTED,
    payload: error,
  };
}

export function getActiveDataProcessorListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataProcessorListStatus() {
  return (dispatch) => dispatch(getActiveDataProcessorListStatusRequest());
}
export function getActiveDataProcessorListStatusRequest() {
  return {
    type: GetActiveDataProcessorActions.FETCH_ACTIVE_DATA_PROCESSOR_STATUS,
  };
}
