import {
  SaveDesignationMasterActions,
  GetDesignationMasterDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDesignationMasterActions,
} from "./actions";

const initState = {
  designationMasterSuccessMessage: null,
  designationMasterErrorMessage: null,
  designationMasterStatus: null,
  designationMasterMaster: [],
  designationMasterData: null,
  ActiveDesignationMasterList: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER:
      return {
        ...state,
        isLoading: true,
        designationMasterErrorMessage: null,
        modalActive: false,
      };
    case SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        designationMasterStatus: payload.status,
        designationMasterErrorMessage: null,
        designationMasterSuccessMessage:
          "Designation Master added successfully!",
      };

    case SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        designationMasterStatus: null,
        designationMasterErrorMessage: payload,
      };
    case SaveDesignationMasterActions.SAVE_DESIGNATION_MASTER_STATUS:
      return {
        ...state,
        designationMasterStatus: 0,
        designationMasterErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS:
      return {
        ...state,
        isLoading: true,
        designationMasterErrorMessage: null,
        modalActive: false,
        designationMasterData: null,
      };
    case GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        designationMasterData: payload.data,
        designationMasterErrorMessage: null,
      };
    case GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        designationMasterErrorMessage: payload,
        designationMasterData: null,
      };
    case GetDesignationMasterDetailsActions.FETCH_DESIGNATION_MASTER_DETAILS_STATUS:
      return {
        ...state,
        designationMasterData: null,
      };
    ///Active DATA DISPOSAL
    case GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER:
      return {
        ...state,
        isLoading: true,
        designationMasterErrorMessage: null,
        modalActive: false,
        ActiveDesignationMasterList: null,
      };
    case GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDesignationMasterList: payload.data,
        designationMasterErrorMessage: null,
      };
    case GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        designationMasterErrorMessage: payload,
        ActiveDesignationMasterList: null,
      };
    case GetActiveDesignationMasterActions.FETCH_ACTIVE_DESIGNATION_MASTER_STATUS:
      return {
        ...state,
        ActiveDesignationMasterList: null,
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetDesignationMaster: null,
        designationMasterStatus: null,
        legalBasisSuccessMessage: null,
        GetDesignationMasterStatus: null,
        designationMasterData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */

    default:
      return state;
  }
}
