import actions from "./actions";
import {
  IsFirstTimeLoginActions,
  resetPassActions,
  UpdateUserDetailsActions,
  customLogoutActions,
  ClearAuthActions,
  UserPermissionActions,
} from "./actions";
import { dashboardViewActions } from "./actions";

const initState = {
  idToken: null,
  userId: null,
  credentials: null,
  VerifyOTPToken: null,
  status: null,
  error: null,
  user_role: null,
  data: null,
  checkLogin: null,
  resetPassStatus: null,
  dashboardStatus: false,
  userErrorMessage: null,
  userDataStatus: null,
  userData: null,
  profileData: null,
  logoutData: null,
  logoutStatus: null,
  PermissionData: [],
  PermissionStatus: null,
};

export default function LoginData(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOGIN:
      return {
        ...state,
        error: null,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: payload.data.data.access_token,
        // userId: payload.data.user.userId,
        credentials: payload,
        profileData: payload.data.data.user,
        error: null,
        logoutStatus: null,
        // VerifyOTPToken: payload.data.data.access_token
        // loginStatus: payload.status
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: payload,
        VerifyOTPToken: null,
        // status: action.payload.status
      };
    case actions.LOGOUT:
      return {
        idToken: null,
        PermissionData: [],
        userId: null,
        VerifyOTPToken: null,
        status: null,
        error: "Session has expired login again to continue!",
      };

    ///VERIFY OTP
    case actions.VERIFY_OTP_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        // idToken: payload.data.data.access_token,
        // userId: payload.data.user.userId,
        credentials: payload,
        error: null,
        VerifyOTPToken: payload.data.data.access_token,
        logoutStatus: null,
        // loginStatus: payload.status
      };
    case actions.VERIFY_OTP_ERROR:
      return {
        ...state,
        error: payload,
        VerifyOTPToken: null,
        // status: action.payload.status
      };

    ///ISFIRST TIME LOGIN//
    case IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN:
      return {
        ...state,
        error: null,
      };
    case IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        checkLogin: payload.data,
        PermissionData: [],
        status: payload.status,
        logoutStatus: null,
      };
    case IsFirstTimeLoginActions.IS_FIRST_TIME_LOGIN_ERROR:
      return {
        ...state,
        error: payload,
        // status: payload.status
      };

    //SET PASSWORD//
    case resetPassActions.RESET_PASSWORD:
      return {
        ...state,
        error: null,
      };
    case resetPassActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        resetPassStatus: payload.status,
        VerifyOTPToken: null,
        idToken: payload.data.data.access_token,
        credentials: payload,
        profileData: payload.data.data.user,
        logoutStatus: null,
        // status: action.payload.status
      };
    case resetPassActions.RESET_PASSWORD_ERROR:
      return {
        error: payload,
        resetPassStatus: null,
        //error: payload
      };

    case dashboardViewActions.VIEW_DASHBOARD_STATUS_TRUE:
      return {
        ...state,
        dashboardStatus: true,
      };

    case dashboardViewActions.VIEW_DASHBOARD_STATUS_FALSE:
      return {
        ...state,
        dashboardStatus: false,
      };
    // GET USER BY ID
    case UpdateUserDetailsActions.SAVE_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
        userErrorMessage: null,
        userData: null,
      };
    case UpdateUserDetailsActions.SAVE_USER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        profileData: payload.data.data,
        userData: payload.data,
        userDataStatus: payload.status,
        userErrorMessage: null,
      };
    case UpdateUserDetailsActions.SAVE_USER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        userErrorMessage: payload,
        userData: null,
      };
    case UpdateUserDetailsActions.SAVE_USER_DETAILS_STATUS:
      return {
        ...state,
        userData: null,
      };
    case customLogoutActions.CUSTOM_LOGOUT:
      return {
        ...state,
        error: null,
      };

    case ClearAuthActions.CLEAR_AUTH_DETAILS:
      return {
        ...state,
        idToken: null,
      };

    case customLogoutActions.CUSTOM_LOGOUT_SUCCESS:
      return {
        ...state,
        idToken: null,
        userId: null,
        VerifyOTPToken: null,
        logoutStatus: payload.status,
        logoutData: payload.data,
      };
    case customLogoutActions.CUSTOM_LOGOUT_ERROR:
      return {
        error: payload,
        logoutStatus: null,
        //error: payload
      };

    /////User Permission///////////
    case UserPermissionActions.FETCH_USER_PERMISSION:
      return {
        ...state,
        error: null,
      };
    case UserPermissionActions.FETCH_USER_PERMISSION_FULLFIELD:
      return {
        ...state,
        error: null,
        PermissionData: payload.data,
        PermissionStatus: payload.status,
      };
    case UserPermissionActions.FETCH_USER_PERMISSION_REJECTED:
      return {
        ...state,
        error: payload,
        PermissionData: [],
        PermissionStatus: null,
      };
    default:
      return state;
  }
}
