import axios from "axios";
import { AssetManagementService_URL, SystemService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveHostingTypeActions = {
  SAVE_HOSTING_TYPE: "SAVE_HOSTING_TYPE",
  SAVE_HOSTING_TYPE_STATUS: "SAVE_HOSTING_TYPE_STATUS",
  SAVE_HOSTING_TYPE_SUCCESS: "SAVE_HOSTING_TYPE_SUCCESS",
  SAVE_HOSTING_TYPE_FAILURE: "SAVE_HOSTING_TYPE_FAILURE",
};

export function SaveHostingTypeRequest() {
  return {
    type: SaveHostingTypeActions.SAVE_HOSTING_TYPE,
    isPosting: true,
  };
}

export function SaveHostingTypeSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveHostingTypeActions.SAVE_HOSTING_TYPE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveHostingTypeFailure(error) {
  return {
    type: SaveHostingTypeActions.SAVE_HOSTING_TYPE_FAILURE,
    payload: error,
  };
}

export function SaveHostingTypeError(error) {
  return {
    type: SaveHostingTypeActions.SAVE_HOSTING_TYPE_FAILURE,
    payload: error,
  };
}

export function saveHostingType(props) {
  var value = {
    hostProviderTypeId: props.hostingTypeId || null,
    hostProviderTypeName: props.hostingTypeName,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${SystemService_URL}/Apc_Persist/Amc_10/saveHostProviderTypeMaster`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveHostingTypeRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveHostingTypeSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveHostingTypeError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingTypeError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingTypeError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveHostingTypeError(err));
              break;
            case 500:
              dispatch(SaveHostingTypeError("Server Error"));
              break;
            default:
              dispatch(SaveHostingTypeError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetHostingTypeDetailsActions = {
  FETCH_HOSTING_DETAILS: "FETCH_HOSTING_DETAILS",
  FETCH_HOSTING_DETAILS_STATUS: "FETCH_HOSTING_DETAILS_STATUS",
  FETCH_HOSTING_DETAILS_FULFILLED: "FETCH_HOSTING_DETAILS_FULFILLED",
  FETCH_HOSTING_DETAILS_REJECTED: "FETCH_HOSTING_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getHostingTypeDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SystemService_URL}/Apc_View/Amc_10/findHostProviderTypeMasterById?hostProviderTypeId=` +
        params.hostingTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getHostingTypeDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getHostingTypeDetailsSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(getHostingTypeDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingTypeDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingTypeDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getHostingTypeDetailsError(err));
              break;
            case 500:
              dispatch(getHostingTypeDetailsError("Server Error"));
              break;
            default:
              dispatch(getHostingTypeDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getHostingTypeDetailsRequest() {
  return {
    type: GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS,
    isPosting: true,
  };
}
export function getHostingTypeDetailsError(error) {
  return {
    type: GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_REJECTED,
    payload: error,
  };
}

export function getHostingTypeDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getHostingTypeDetailsStatus() {
  return (dispatch) => dispatch(getHostingTypeDetailsStatusRequest());
}
export function getHostingTypeDetailsStatusRequest() {
  return {
    type: GetHostingTypeDetailsActions.FETCH_HOSTING_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getHostingTypeDetailsErrorRequest());
}
export function getHostingTypeDetailsErrorRequest() {
  return {
    type: GetHostingTypeDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveHostingTypeActions = {
  FETCH_ACTIVE_HOSTING_TYPE: "FETCH_ACTIVE_HOSTING_TYPE",
  FETCH_ACTIVE_HOSTING_TYPE_STATUS: "FETCH_ACTIVE_HOSTING_TYPE_STATUS",
  FETCH_ACTIVE_HOSTING_TYPE_FULFILLED: "FETCH_ACTIVE_HOSTING_TYPE_FULFILLED",
  FETCH_ACTIVE_HOSTING_TYPE_REJECTED: "FETCH_ACTIVE_HOSTING_TYPE_REJECTED",
};
export function getActiveHostingTypeList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_10/getAllHostProviderTypeMasterPagination?length=0&sortOrder=desc&sortField=hostProviderTypeId&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveHostingTypeListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveHostingTypeListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveHostingTypeListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingTypeListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingTypeListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveHostingTypeListError(err));
              break;
            case 500:
              dispatch(getActiveHostingTypeListError("Server Error"));
              break;
            default:
              dispatch(getActiveHostingTypeListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveHostingTypeListRequest() {
  return {
    type: GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE,
    isPosting: true,
  };
}
export function getActiveHostingTypeListError(error) {
  return {
    type: GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_REJECTED,
    payload: error,
  };
}

export function getActiveHostingTypeListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveHostingTypeListStatus() {
  return (dispatch) => dispatch(getActiveHostingTypeListStatusRequest());
}
export function getActiveHostingTypeListStatusRequest() {
  return {
    type: GetActiveHostingTypeActions.FETCH_ACTIVE_HOSTING_TYPE_STATUS,
  };
}
