import {
  saveDataRetentionActions,
  deleteDataRetentionActions,
  GetDataRetentionDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataRetentionActions,
} from "./actions";

const initState = {
  dataRetentionSuccessMessage: null,
  dataRetentionErrorMessage: null,
  dataRetentionStatus: null,
  dataRetentionMaster: [],
  dataRetentionData: null,
  ActiveDataRetentionList: null
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveDataRetentionActions.SAVE_DATA_RETENTION:
      return {
        ...state,
        isLoading: true,
        dataRetentionErrorMessage: null,
        modalActive: false
      };
    case saveDataRetentionActions.SAVE_DATA_RETENTION_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataRetentionStatus: payload.status,
        dataRetentionErrorMessage: null,
        dataRetentionSuccessMessage: "Data Retention added successfully!"
      };

    case saveDataRetentionActions.SAVE_DATA_RETENTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataRetentionStatus: null,
        dataRetentionErrorMessage: payload
      };
    case saveDataRetentionActions.SAVE_DATA_RETENTION_STATUS:
      return {
        ...state,
        dataRetentionStatus: 0,
        dataRetentionErrorMessage: null
      };

    // Delete Asset Type

    case deleteDataRetentionActions.DELETE_DATA_RETENTION:
      return {
        ...state,
        isLoading: true,
        dataRetentionErrorMessage: null,
        modalActive: false
      };
    case deleteDataRetentionActions.DELETE_DATA_RETENTION_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataRetentionStatus: payload.status,
        dataRetentionErrorMessage: null,
        dataRetentionSuccessMessage: "Data Retention Deleted successfully!"
      };

    case deleteDataRetentionActions.DELETE_DATA_RETENTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataRetentionStatus: null,
        dataRetentionErrorMessage: payload
      };
    case deleteDataRetentionActions.DELETE_DATA_RETENTION_STATUS:
      return {
        ...state,
        dataRetentionStatus: 0,
        dataRetentionErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataRetentionErrorMessage: null,
        modalActive: false,
        dataRetentionData: null
      };
    case GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataRetentionData: payload.data,
        dataRetentionStatus: payload.status,
        dataRetentionErrorMessage: null
      };
    case GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataRetentionErrorMessage: payload,
        dataRetentionData: null
      };
    case GetDataRetentionDetailsActions.FETCH_DATA_RETENTION_DETAILS_STATUS:
      return {
        ...state,
        dataRetentionData: null
      };
    ///Active assets
    case GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION:
      return {
        ...state,
        isLoading: true,
        dataRetentionErrorMessage: null,
        modalActive: false,
        ActiveDataRetentionList: null
      };
    case GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataRetentionList: payload.data,
        dataRetentionErrorMessage: null
      };
    case GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_REJECTED:
      return {
        ...state,
        isLoading: false,
        assetTypeErrorMessage: payload,
        ActiveAssetTypeList: null
      };
    case GetActiveDataRetentionActions.FETCH_ACTIVE_DATA_RETENTION_STATUS:
      return {
        ...state,
        ActiveDataRetentionList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        dataRetentionStatus: null,
        dataRetentionSuccessMessage: null,
        dataRetentionErrorMessage:null,
        dataRetentionData: null,
        ActiveDataRetentionList:null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
