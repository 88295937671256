import axios from "axios";
import {
  SuperAdmin_Service_URL,
  ConsentManagementService_URL,
  CookieService_Url,
  AssetManagementService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Cookie Category

export const saveCookieCategoryActions = {
  SAVE_COOKIE_CATEGORY: "SAVE_COOKIE_CATEGORY",
  SAVE_COOKIE_CATEGORY_STATUS: "SAVE_COOKIE_CATEGORY_STATUS",
  SAVE_COOKIE_CATEGORY_SUCCESS: "SAVE_COOKIE_CATEGORY_SUCCESS",
  SAVE_COOKIE_CATEGORY_FAILURE: "SAVE_COOKIE_CATEGORY_FAILURE",
};
export const saveCatIdCookieDetailsActions = {
  SAVE_CATID_CATEGORY: "SAVE_CATID_CATEGORY",
  SAVE_CATID_CATEGORY_STATUS: "SAVE_CATID_CATEGORY_STATUS",
  SAVE_CATID_CATEGORY_SUCCESS: "SAVE_CATID_CATEGORY_SUCCESS",
  SAVE_CATID_CATEGORY_FAILURE: "SAVE_CATID_CATEGORY_FAILURE",
};
export function saveCookieCategoryRequest() {
  return {
    type: saveCookieCategoryActions.SAVE_COOKIE_CATEGORY,
    isPosting: true,
  };
}

export function saveCookieCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCookieCategoryActions.SAVE_COOKIE_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function saveCookieCategoryFailure(error) {
  return {
    type: saveCookieCategoryActions.SAVE_COOKIE_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveCookieCategoryError(error) {
  return {
    type: saveCookieCategoryActions.SAVE_COOKIE_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveCookieCategory(props) {
  // var value = {
  //   id: props.id || 0,
  //   ConsentHeadName: props.ConsentHeadName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CookieService_Url}/Apc_Persist/Amc_62/saveCookieCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveCookieCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCookieCategorySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCookieCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveConsentPurposeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveConsentPurposeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveConsentPurposeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveConsentPurposeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveConsentPurposeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}
//Delete by id

export const deleteCookieCategoryActions = {
  DELETE_COOKIE_CATEGORY: "DELETE_COOKIE_CATEGORY",
  DELETE_COOKIE_CATEGORY_STATUS: "DELETE_COOKIE_CATEGORY_STATUS",
  DELETE_COOKIE_CATEGORY_SUCCESS: "DELETE_COOKIE_CATEGORY_SUCCESS",
  DELETE_COOKIE_CATEGORY_FAILURE: "DELETE_COOKIE_CATEGORY_FAILURE",
};

export function deleteCookieCategoryRequest() {
  return {
    type: deleteCookieCategoryActions.DELETE_COOKIE_CATEGORY,
    isPosting: true,
  };
}

export function deleteCookieCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteCookieCategoryActions.DELETE_COOKIE_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function deleteCookieCategoryFailure(error) {
  return {
    type: deleteCookieCategoryActions.DELETE_COOKIE_CATEGORY_FAILURE,
    payload: error,
  };
}

export function deleteCookieCategoryError(error) {
  return {
    type: deleteCookieCategoryActions.DELETE_COOKIE_CATEGORY_FAILURE,
    payload: error,
  };
}

export function deleteCookieCategory(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${CookieService_Url}/Apc_State/Amc_62/deleteCookieCategory?id=` +
        params.id,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteCookieCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteCookieCategorySuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteCookieCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteCookieCategoryError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteCookieCategoryError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteCookieCategoryError(err));
              break;
            case 500:
              dispatch(deleteCookieCategoryError("Server Error"));
              break;
            default:
              dispatch(deleteCookieCategoryError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// get  details by id

export const getCokieCategoryDetailsActions = {
  FETCH_COOKIE_CATEGORY_DETAILS: "FETCH_COOKIE_CATEGORY_DETAILS",
  FETCH_COOKIE_CATEGORY_DETAILS_STATUS: "FETCH_COOKIE_CATEGORY_DETAILS_STATUS",
  FETCH_COOKIE_CATEGORY_DETAILS_FULFILLED:
    "FETCH_COOKIE_CATEGORY_DETAILS_FULFILLED",
  FETCH_COOKIE_CATEGORY_DETAILS_REJECTED:
    "FETCH_COOKIE_CATEGORY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getCookieCategoryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CookieService_Url}/Apc_View/Amc_62/findCookieCategoryById?id=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getCookieCategoryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieCategoryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getCookieCategoryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieCategoryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieCategoryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getCookieCategoryDetailsError(err));
              break;
            case 500:
              dispatch(getCookieCategoryDetailsError("Server Error"));
              break;
            default:
              dispatch(getCookieCategoryDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getCookieCategoryDetailsRequest() {
  return {
    type: getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS,
    isPosting: true,
  };
}
export function getCookieCategoryDetailsError(error) {
  return {
    type: getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getCookieCategoryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getCookieCategoryDetailsStatus() {
  return (dispatch) => dispatch(getCookieCategoryDetailsStatusRequest());
}
export function getCookieCategoryDetailsStatusRequest() {
  return {
    type: getCokieCategoryDetailsActions.FETCH_COOKIE_CATEGORY_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getCookieCategoryDetailsErrorRequest());
}
export function getCookieCategoryDetailsErrorRequest() {
  return {
    type: getCokieCategoryDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ConsentHeads", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveConsentHeadActions = {
  FETCH_ACTIVE_COOKIE_CATEGORY: "FETCH_ACTIVE_COOKIE_CATEGORY",
  FETCH_ACTIVE_COOKIE_CATEGORY_STATUS: "FETCH_ACTIVE_COOKIE_CATEGORY_STATUS",
  FETCH_ACTIVE_COOKIE_CATEGORY_FULFILLED:
    "FETCH_ACTIVE_COOKIE_CATEGORY_FULFILLED",
  FETCH_ACTIVE_COOKIE_CATEGORY_REJECTED:
    "FETCH_ACTIVE_COOKIE_CATEGORY_REJECTED",
};

export function getActiveConsentHeadList(params, access_token) {
  return (dispatch) =>
    axios({
      method: "POST",
      data: params,
      url: `${ConsentManagementService_URL}/Apc_View/Amc_56/listing/ConsentHead`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveConsentHeadListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveConsentHeadListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveConsentHeadListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveConsentHeadListError(err));
              break;
            case 500:
              dispatch(getActiveConsentHeadListError("Server Error"));
              break;
            default:
              dispatch(getActiveConsentHeadListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveConsentHeadListRequest() {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_COOKIE_CATEGORY,
    isPosting: true,
  };
}
export function getActiveConsentHeadListError(error) {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_COOKIE_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getActiveConsentHeadListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveConsentHeadActions.FETCH_ACTIVE_COOKIE_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveConsentHeadListStatus() {
  return (dispatch) => dispatch(getActiveConsentHeadListStatusRequest());
}
export function getActiveConsentHeadListStatusRequest() {
  return {
    type: GetActiveConsentHeadActions.FETCH_ACTIVE_COOKIE_CATEGORY_STATUS,
  };
}

/////////////////////get coockie category /////////////////

export const getAllUnassignedCookiesDetailsActions = {
  FETCH_UNASSIGN_COOKIE_DETAILS: "FETCH_UNASSIGN_COOKIE_DETAILS",
  FETCH_UNASSIGN_COOKIE_DETAILS_STATUS: "FETCH_UNASSIGN_COOKIE_DETAILS_STATUS",
  FETCH_UNASSIGN_COOKIE_DETAILS_FULFILLED:
    "FETCH_UNASSIGN_COOKIE_DETAILS_FULFILLED",
  FETCH_UNASSIGN_COOKIE_DETAILS_REJECTED:
    "FETCH_UNASSIGN_COOKIE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getAllUnassignedCookiesDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CookieService_Url}/Apc_View/Amc_62/getAllUnassignedCookies?id=${params.id}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getAllUnassignedCookiesDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getCookieCategoryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllUnassignedCookiesDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUnassignedCookiesDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUnassignedCookiesDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getAllUnassignedCookiesDetailsError(err));
              break;
            case 500:
              dispatch(getAllUnassignedCookiesDetailsError("Server Error"));
              break;
            default:
              dispatch(getAllUnassignedCookiesDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllUnassignedCookiesDetailsRequest() {
  return {
    type: getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS,
    isPosting: true,
  };
}
export function getAllUnassignedCookiesDetailsError(error) {
  return {
    type: getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getAllUnassignedCookiesDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getAllUnassignedCookiesDetailsStatus() {
  return (dispatch) => dispatch(getCookieCategoryDetailsStatusRequest());
}
export function getAllUnassignedCookiesDetailsStatusRequest() {
  return {
    type: getAllUnassignedCookiesDetailsActions.FETCH_UNASSIGN_COOKIE_DETAILS_STATUS,
  };
}

/////// save cookie cat //////

export function saveCatIdCookieDetailsRequest() {
  return {
    type: saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY,
    isPosting: true,
  };
}

export function saveCatIdCookieDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function saveCatIdCookieDetailsFailure(error) {
  return {
    type: saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveCatIdCookieDetailsError(error) {
  return {
    type: saveCatIdCookieDetailsActions.SAVE_CATID_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveCatIdCookieDetails(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props.data,
      url: `${CookieService_Url}/Apc_Persist/Amc_62/setCategoryInCookieDetails`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveCatIdCookieDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveCatIdCookieDetailsSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveCatIdCookieDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}
