import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Control Category

export const SaveControlFrameworkActions = {
  SAVE_CONTROL_FRAMEWORK: "SAVE_CONTROL_FRAMEWORK",
  SAVE_CONTROL_FRAMEWORK_STATUS: "SAVE_CONTROL_FRAMEWORK_STATUS",
  SAVE_CONTROL_FRAMEWORK_SUCCESS: "SAVE_CONTROL_FRAMEWORK_SUCCESS",
  SAVE_CONTROL_FRAMEWORK_FAILURE: "SAVE_CONTROL_FRAMEWORK_FAILURE",
};

export function SaveControlFrameworkRequest() {
  return {
    type: SaveControlFrameworkActions.SAVE_CONTROL_FRAMEWORK,
    isPosting: true,
  };
}

export function SaveControlFrameworkSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveControlFrameworkActions.SAVE_CONTROL_FRAMEWORK_SUCCESS,
      payload: props,
    });
  };
}

export function SaveControlFrameworkFailure(error) {
  return {
    type: SaveControlFrameworkActions.SAVE_CONTROL_FRAMEWORK_FAILURE,
    payload: error,
  };
}

export function SaveControlFrameworkError(error) {
  return {
    type: SaveControlFrameworkActions.SAVE_CONTROL_FRAMEWORK_FAILURE,
    payload: error,
  };
}

export function saveControlFramework(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_9/saveControlFramework`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveControlFrameworkRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveControlFrameworkSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveControlFrameworkError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveControlFrameworkError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlFrameworkError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlFrameworkError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlFrameworkError(err));
          //   break;
          // case 500:
          //   dispatch(SaveControlFrameworkError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveControlFrameworkError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetControlFrameworkDetailsActions = {
  FETCH_CONTROL_FRAMEWORK: "FETCH_CONTROL_FRAMEWORK_DETAILS",
  FETCH_CONTROL_FRAMEWORK_STATUS: "FETCH_CONTROL_FRAMEWORK_DETAILS_STATUS",
  FETCH_CONTROL_FRAMEWORK_FULFILLED: "FETCH_CONTROL_FRAMEWORK_DETAILS_FULFILLED",
  FETCH_CONTROL_FRAMEWORK_REJECTED: "FETCH_CONTROL_FRAMEWORK_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getControlFrameworkDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/findControlFrameworkById?controlFrameworkId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlFrameworkDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlFrameworkDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlFrameworkDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 500:
              dispatch(getControlFrameworkDetailsError("Server Error"));
              break;
            default:
              dispatch(getControlFrameworkDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getAllControlFramework(params) {

  const jsonData = params.data;
  return (dispatch) =>
    axios({
      method: "post",
      data: jsonData,
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/listing/ControlFramework`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlFrameworkDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlFrameworkDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlFrameworkDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlFrameworkDetailsError(err));
              break;
            case 500:
              dispatch(getControlFrameworkDetailsError("Server Error"));
              break;
            default:
              dispatch(getControlFrameworkDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getControlFrameworkDetailsRequest() {
  return {
    type: GetControlFrameworkDetailsActions.FETCH_CONTROL_FRAMEWORK,
    isPosting: true,
  };
}
export function getControlFrameworkDetailsError(error) {
  return {
    type: GetControlFrameworkDetailsActions.FETCH_CONTROL_FRAMEWORK_REJECTED,
    payload: error,
  };
}

export function getControlFrameworkDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetControlFrameworkDetailsActions.FETCH_CONTROL_FRAMEWORK_FULFILLED,
      payload: props,
    });
  };
}

export function getControlFrameworkDetailsStatus() {
  return (dispatch) => dispatch(getControlFrameworkDetailsStatusRequest());
}
export function getControlFrameworkDetailsStatusRequest() {
  return {
    type: GetControlFrameworkDetailsActions.FETCH_CONTROL_FRAMEWORK_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getControlFrameworkDetailsErrorRequest());
}
export function getControlFrameworkDetailsErrorRequest() {
  return {
    type: GetControlFrameworkDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
//export const UploadexcelActions = {
 //CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  //CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  //CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
//};

//export function UploadexcelRequest() {
  //return {
    //type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    //isPosting: true,
  //};
//}

//export function UploadexcelSuccess(props) {
 // return (dispatch) => {
    //dispatch({
    //  type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
    //  payload: props,
  //  });
//  };
//}

//export function UploadexcelFailure(error) {
//  return {
//    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
//    payload: error,
//  };
//}

//export function UploadExcel(props) {
  //let data = new FormData();
  //data.append("ControlCategories", props.Excel);

  //return (dispatch) =>
    //axios({
      //method: "post",
      //data: data,
      //url:
        //`${RiskManagementService_URL}/bulkUploadExcel/ControlCategory`,
     //headers: {
       //"Content-Type": "application/json",
       // Authorization: "Bearer " + props.access_token,
       // transactionId: "1",
     //},
   // })
      //.then((response) => {
      //  dispatch(UploadexcelRequest());
      //  if (response.status === 200) {
      //   dispatch(UploadexcelSuccess(response));
     // } else {
          
      //    const error = new Error(response.statusText);
      //   dispatch(UploadexcelFailure(error));
      //   throw error;
      // }
     //})
     //.catch((err) => {
      // if (err.response) {
      //   switch (err.response.status) {
      //      case 400:
      //        map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

      //        map.forEach((ele) => (err = ele));

      //        dispatch(UploadexcelFailure(err));
              //break;
            //case 404:
             // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              //map.forEach((ele) => (err = ele));

              //dispatch(UploadexcelFailure(err));
              //break;
            //case 500:
              //dispatch(UploadexcelFailure("Server Error"));
              //break;
            //default:
              //dispatch(UploadexcelFailure("Unknown Error"));
            // break;
          //}
        //} else {
     //     dispatch(UploadexcelFailure("Internal Server Error"));
    //    dispatch(clearLogoutData());
    //    }
  //    });
//}

export const GetActiveControlFrameworkActions = {
  FETCH_ACTIVE_CONTROL_FRAMEWORK: "FETCH_ACTIVE_CONTROL_FRAMEWORK",
  FETCH_ACTIVE_CONTROL_FRAMEWORK_STATUS: "FETCH_ACTIVE_CONTROL_FRAMEWORK_STATUS",
  FETCH_ACTIVE_CONTROL_FRAMEWORK_FULFILLED: "FETCH_ACTIVE_CONTROL_FRAMEWORK_FULFILLED",
  FETCH_ACTIVE_CONTROL_FRAMEWORK_REJECTED: "FETCH_ACTIVE_CONTROL_FRAMEWORK_REJECTED",
};
export function getActiveControlFrameworkList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/getAllControlFrameworkPagination?start=0&sortOrder=desc&sortField=controlFrameworkId&status=true&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveControlCatListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveControlFrameworkListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveControlFrameworkListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlFrameworkListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlFrameworkListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlFrameworkListError(err));
              break;
            case 500:
              dispatch(getActiveControlFrameworkListError("Server Error"));
              break;
            default:
              dispatch(getActiveControlFrameworkListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveControlCatListRequest() {
  return {
    type: GetActiveControlFrameworkActions.FETCH_ACTIVE_CONTROL_FRAMEWORK,
    isPosting: true,
  };
}
export function getActiveControlFrameworkListError(error) {
  return {
    type: GetActiveControlFrameworkActions.FETCH_ACTIVE_CONTROL_FRAMEWORK_REJECTED,
    payload: error,
  };
}

export function getActiveControlFrameworkListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveControlFrameworkActions.FETCH_ACTIVE_CONTROL_FRAMEWORK_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveControlFrameworkListStatus() {
  return (dispatch) => dispatch(getActiveControlFrameworkListStatusRequest());
}
export function getActiveControlFrameworkListStatusRequest() {
  return {
    type: GetActiveControlFrameworkActions.FETCH_ACTIVE_CONTROL_FRAMEWORK_STATUS,
  };
}

// //listing
// export const GetActiveControlFrameworkActions = {
//   FETCH_ACTIVE_CONTROL_FRAMEWORK: "FETCH_ACTIVE_CONTROL_FRAMEWORK",
//   FETCH_ACTIVE_CONTROL_FRAMEWORK_STATUS: "FETCH_ACTIVE_CONTROL_FRAMEWORK_STATUS",
//   FETCH_ACTIVE_CONTROL_FRAMEWORK_FULFILLED: "FETCH_ACTIVE_CONTROL_FRAMEWORK_FULFILLED",
//   FETCH_ACTIVE_CONTROL_FRAMEWORK_REJECTED: "FETCH_ACTIVE_CONTROL_FRAMEWORK_REJECTED",
// };

// export function getActiveControlFrameworkList(params,access_token) {
//   return (dispatch) =>
//     axios({
//       method: "POST",
//       data:params,
//       url: `${IncidentManagementService_URL}/listing/Threat`,
//       headers: {
//         transactionId: "1",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + access_token,
//       },
//     })
//       .then((response) => {
//         dispatch(getActiveControlCatListRequest());
//         if (response.status >= 200 && response.status < 300) {
//           dispatch(getActiveControlFrameworkListSuccess(response));
//           return response;
//         } else {
//           const error = new Error(response.statusText);
//           dispatch(getActiveControlFrameworkListError(error));
//           throw error;
//         }
//       })
//       .catch((err) => {
//         if (err.response) {
//           switch (err.response.status) {
//             case 400:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlFrameworkListError(err));
//               break;
//             case 404:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlFrameworkListError(err));
//               break;
//             case 406:
//               map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

//               map.forEach((ele) => (err = ele));

//               dispatch(getActiveControlFrameworkListError(err));
//               break;
//             case 500:
//               dispatch(getActiveControlFrameworkListError("Server Error"));
//               break;
//             default:
//               dispatch(getActiveControlFrameworkListError("Unknown Error"));
//               break;
//           }
//         } else {
//           // //dispatch(logout());
//           dispatch(clearLogoutData());
//         }
//       });
// }