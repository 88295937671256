import axios from "axios";
import { RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveControlRegisterActions = {
  SAVE_CONTROLREGISTER: "SAVE_CONTROLREGISTER",
  SAVE_CONTROLREGISTER_STATUS: "SAVE_CONTROLREGISTER_STATUS",
  SAVE_CONTROLREGISTER_SUCCESS: "SAVE_CONTROLREGISTER_SUCCESS",
  SAVE_CONTROLREGISTER_FAILURE: "SAVE_CONTROLREGISTER_FAILURE",
};

export function SaveControlRegisterRequest() {
  return {
    type: SaveControlRegisterActions.SAVE_CONTROLREGISTER,
    isPosting: true,
  };
}

export function SaveControlRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveControlRegisterActions.SAVE_CONTROLREGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveControlRegisterFailure(error) {
  return {
    type: SaveControlRegisterActions.SAVE_CONTROLREGISTER_FAILURE,
    payload: error,
  };
}

export function SaveControlRegisterError(error) {
  return {
    type: SaveControlRegisterActions.SAVE_CONTROLREGISTER_FAILURE,
    payload: error,
  };
}

export function saveControlRegister(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/saveControlRegister`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveControlRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveControlRegisterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveControlRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(SaveControlRegisterError(err));
          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlRegisterError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlRegisterError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach((ele) => (err = ele));

          //   dispatch(SaveControlRegisterError(err));
          //   break;
          // case 500:
          //   dispatch(SaveControlRegisterError("Server Error"));
          //   break;
          // default:
          //   dispatch(SaveControlRegisterError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetControlRegisterDetailsActions = {
  FETCH_CONTROLREGISTER: "FETCH_CONTROLREGISTER_DETAILS",
  FETCH_CONTROLREGISTER_STATUS: "FETCH_CONTROLREGISTER_DETAILS_STATUS",
  FETCH_CONTROLREGISTER_FULFILLED: "FETCH_CONTROLREGISTER_DETAILS_FULFILLED",
  FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED: "FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED",
  FETCH_CONTROLREGISTER_REJECTED: "FETCH_CONTROLREGISTER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getControlRegisterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/getByIdControlRegister?controlRegisterId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlRegisterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlRegisterDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlRegisterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsError(err));
              break;
            case 500:
              dispatch(getControlRegisterDetailsError("Server Error"));
              break;
            default:
              dispatch(getControlRegisterDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


export function getControlRegisterDetailsByRiskId(params) {
  
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_9/getControlRegisterByRiskId?riskId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getControlRegisterDetailsByRiskIdRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlRegisterDetailsByRiskIdSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlRegisterDetailsByRiskIdError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsByRiskIdError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsByRiskIdError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterDetailsByRiskIdError(err));
              break;
            case 500:
              dispatch(getControlRegisterDetailsByRiskIdError("Server Error"));
              break;
            default:
              dispatch(getControlRegisterDetailsByRiskIdError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


export function getControlRegisterDetailsRequest() {
  return {
    type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER,
    isPosting: true,
  };
}

export function getControlRegisterDetailsByRiskIdRequest() {
  return {
    type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER,
    isPosting: true,
  };
}

export function getControlRegisterDetailsError(error) {
  return {
    type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_REJECTED,
    payload: error,
  };
}

export function getControlRegisterDetailsByRiskIdError(error) {
  return {
    type:GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_REJECTED,
    payload: error,
  };
}

export function getControlRegisterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_FULFILLED,
      payload: props,
    });
  };
}

export function getControlRegisterDetailsByRiskIdSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED,
      payload: props,
    });
  };
}

export function getControlRegisterDetailsStatus() {
  return (dispatch) => dispatch(getControlRegisterDetailsStatusRequest());
}
export function getControlRegisterDetailsStatusRequest() {
  return {
    type: GetControlRegisterDetailsActions.FETCH_CONTROLREGISTER_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getControlRegisterDetailsErrorRequest());
}
export function getControlRegisterDetailsErrorRequest() {
  return {
    type: GetControlRegisterDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("ControlRegister", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/Apc_Persist/Amc_9/bulkUploadExcel/ControlRegister`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveControlRegisterActions = {
  FETCH_ACTIVE_CONTROLREGISTER: "FETCH_ACTIVE_CONTROLREGISTER",
  FETCH_ACTIVE_CONTROLREGISTER_STATUS: "FETCH_ACTIVE_CONTROLREGISTER_STATUS",
  FETCH_ACTIVE_CONTROLREGISTER_FULFILLED: "FETCH_ACTIVE_CONTROLREGISTER_FULFILLED",
  FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED: "FETCH_CONTROLREGISTER_LIST_BY_RISKID_FULFILLED",
  FETCH_ACTIVE_CONTROLREGISTER_REJECTED: "FETCH_ACTIVE_CONTROLREGISTER_REJECTED",
};
export function getActiveControlRegisterList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/listing/ControlRegister`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveControlRegisterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveControlRegisterListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveControlRegisterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlRegisterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlRegisterListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveControlRegisterListError(err));
              break;
            case 500:
              dispatch(getActiveControlRegisterListError("Server Error"));
              break;
            default:
              dispatch(getActiveControlRegisterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveControlRegisterListRequest() {
  return {
    type: GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER,
    isPosting: true,
  };
}
export function getActiveControlRegisterListError(error) {
  return {
    type: GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_REJECTED,
    payload: error,
  };
}

export function getActiveControlRegisterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveControlRegisterListStatus() {
  return (dispatch) => dispatch(getActiveControlRegisterListStatusRequest());
}
export function getActiveControlRegisterListStatusRequest() {
  return {
    type: GetActiveControlRegisterActions.FETCH_ACTIVE_CONTROLREGISTER_STATUS,
  };
}

// Get all ControlRegister Pagination

export const GetControlRegisterActions = {
  FETCH_CONTROLREGISTER: "FETCH_CONTROLREGISTER",
  FETCH_CONTROLREGISTER_STATUS: "FETCH_CONTROLREGISTER_STATUS",
  FETCH_CONTROLREGISTER_FULFILLED:
    "FETCH_CONTROLREGISTER_FULFILLED",
  FETCH_CONTROLREGISTER_REJECTED:
    "FETCH_CONTROLREGISTER_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getControlRegister(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/getAllControlRegisterPagination?length=0&sortOrder=desc&sortField=ControlRegisterId&status=true`, 
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getControlRegisterSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getControlRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getControlRegisterError(err));
              break;
            case 500:
              dispatch(getControlRegisterError("Server Error"));
              break;
            default:
              dispatch(getControlRegisterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getControlRegisterRequest() {
  return {
    type: GetControlRegisterActions.FETCH_CONTROLREGISTER,
    isPosting: true,
  };
}
export function getControlRegisterError(error) {
  return {
    type: GetControlRegisterActions.FETCH_CONTROLREGISTER_REJECTED,
    payload: error,
  };
}

export function getControlRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetControlRegisterActions.FETCH_CONTROLREGISTER_FULFILLED,
      payload: props,
    });
  };
}

export function getControlRegisterStatus() {
  return (dispatch) => dispatch(getControlRegisterStatusRequest());
}
export function getControlRegisterStatusRequest() {
  return {
    type: GetControlRegisterActions.FETCH_CONTROLREGISTER_STATUS,
  };
}