import {
  saveRiskCategoryActions,
  getRiskCategoryDetailsActions,
  ClearFormDetailsActions,
  GetActiveRiskCategoryActions,
  UploadexcelActions,
  GetRiskCategoryActions,
  deleteRiskCategoryActions,

  
} from "./actions";

const initState = {
  riskCategorySuccessMessage: null,
  riskCategoryErrorMessage: null,
  riskCategoryStatus: null,
  riskCategoryMaster: [],
  riskCategoryData: null,
  ActiveriskCategoryList: []
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRiskCategoryActions.SAVE_RISK_CATEGORY:
      return {
        ...state,
        isLoading: true,
        riskCategoryErrorMessage: null,
        modalActive: false
      };
    case saveRiskCategoryActions.SAVE_RISK_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        riskCategoryStatus: payload.status,
        riskCategoryErrorMessage: null,
        riskCategorySuccessMessage: "Risk Category added successfully!"
      };

    case saveRiskCategoryActions.SAVE_RISK_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        riskCategoryStatus: null,
        riskCategoryErrorMessage: payload
      };
    case saveRiskCategoryActions.SAVE_RISK_CATEGORY_STATUS:
      return {
        ...state,
        riskCategoryStatus: 0,
        riskCategoryErrorMessage: null
      };
    ///////////////////////////////////////////////////////
    //delete consent Head By id
    

    case deleteRiskCategoryActions.DELETE_RISK_CATEGORY:
      return {
        ...state,
        isLoading: true,
        riskCategoryErrorMessage: null,
        modalActive: false,
      };
    case deleteRiskCategoryActions.DELETE_RISK_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        riskCategoryStatus: payload.status,
        riskCategoryErrorMessage: null,
        consentSuccessMessage: "Consent Head Deleted successfully!",
      };

    case deleteRiskCategoryActions.DELETE_RISK_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        riskCategoryStatus: null,
        riskCategoryErrorMessage: payload,
      };
    case deleteRiskCategoryActions.DELETE_RISK_CATEGORY_STATUS:
      return {
        ...state,
        riskCategoryStatus: 0,
        riskCategoryErrorMessage: null,
      };

    // GET  BY ID
    case getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS:
      return {
        ...state,
        isLoading: true,
        riskCategoryErrorMessage: null,
        modalActive: false,
        riskCategoryData: null
      };
    case getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        riskCategoryData: payload.data,
        riskCategoryStatus: payload.status,
        riskCategoryErrorMessage: null
      };
    case getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskCategoryErrorMessage: payload,
        riskCategoryData: null
      };
    case getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_STATUS:
      return {
        ...state,
        riskCategoryData: null
      };

      // GET  BY ID
    case GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY:
      return {
        ...state,
        isLoading: true,
        riskCategoryErrorMessage: null,
        modalActive: false,
        ActiveriskCategoryList: null
      };
    case GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveriskCategoryList: payload.data,
        riskCategoryStatus: payload.status,
        riskCategoryErrorMessage: null
      };
    case GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskCategoryErrorMessage: payload,
        ActiveriskCategoryList: null
      };
    case GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_STATUS:
      return {
        ...state,
        riskCategoryData: null
      };
    // clear ConsentPurpose details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRiskCategory: null,
        riskCategoryStatus: null,
        HeadSuccessMessage: null,
        GetRiskCategoryStatus: null,
        riskCategoryData: null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };


//Get Pagination
      case GetRiskCategoryActions.FETCH_RISK_CATEGORY:
    return {
      ...state,
      isLoading: true,
      riskCategoryErrorMessage: null,
      modalActive: false,
      RiskCategoryData: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveRiskCategoryList: payload.data,
      RiskCategoryStatus: payload.status,  
      riskCategoryErrorMessage: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_REJECTED:
    return {
      ...state,
      isLoading: false,
      riskCategoryErrorMessage: payload,
      RiskCategoryData: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_STATUS:
    return {
      ...state,
      RiskCategoryData: null
    };
      
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY:
    return {
      ...state,
      isLoading: true,
      riskCategoryErrorMessage: null,
      modalActive: false,
      RiskCategoryData: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      ActiveRiskCategoryList: payload.data,
      RiskCategoryStatus: payload.status,  
      riskCategoryErrorMessage: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_REJECTED:
    return {
      ...state,
      isLoading: false,
      riskCategoryErrorMessage: payload,
      RiskCategoryData: null
    };
  case GetRiskCategoryActions.FETCH_RISK_CATEGORY_STATUS:
    return {
      ...state,
      RiskCategoryData: null
    };
    /* */

    default:
      return state;


  }
}
