import {
  saveRiskRegisterActions,
  deleteRiskRegisterActions,
  GetRiskRegisterDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveRiskRegisterActions,
  saveDocActions,
  updateStatusRiskRegisterActions,
  saveSurveyRiskRegisterActions,
  saveAutoRiskRegisterActions,
  saveAutoRiskRegisterDropActions
} from "./actions";

const initState = {
  riskRegisterSuccessMessage: null,
  riskRegisterErrorMessage: null,
  RiskRegisterStatus: null,
  RiskRegisterData: [],
  RiskRegisterData: null,
  ActiveRiskRegisterList: null,
  DocStatus: null,
  docErrorMessage: null,
  docSuccessMessage: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveRiskRegisterActions.SAVE_RISK_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
      };
    case saveRiskRegisterActions.SAVE_RISK_REGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterStatus: payload.status,
        riskRegisterErrorMessage: null,
        riskRegisterSuccessMessage: "Risk Register added successfully!",
      };

    case saveRiskRegisterActions.SAVE_RISK_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskRegisterStatus: null,
        riskRegisterErrorMessage: payload,
      };
    case saveRiskRegisterActions.SAVE_RISK_REGISTER_STATUS:
      return {
        ...state,
        RiskRegisterStatus: 0,
        riskRegisterErrorMessage: null,
      };

    // survey

    case saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
      };
    case saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterStatus: payload.status,
        riskRegisterErrorMessage: null,
        riskRegisterSuccessMessage: "Survey Risk Register added successfully!",
      };

    case saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskRegisterStatus: null,
        riskRegisterErrorMessage: payload,
      };
    case saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_STATUS:
      return {
        ...state,
        RiskRegisterStatus: 0,
        riskRegisterErrorMessage: null,
      };

    // auto risk
    // from risk drawer

    case saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
      };
    case saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterStatus: payload.status,
        riskRegisterErrorMessage: null,
        riskRegisterSuccessMessage: "Survey Risk automated successfully!",
      };

    case saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskRegisterStatus: null,
        riskRegisterErrorMessage: payload,
      };
    case saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_STATUS:
      return {
        ...state,
        RiskRegisterStatus: 0,
        riskRegisterErrorMessage: null,
      };

    // auto risk
    // from dropdowns

    case saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_DROP_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
      };
    case saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterStatus: payload.status,
        riskRegisterErrorMessage: null,
        riskRegisterSuccessMessage: "Survey Risk automated successfully!",
      };

    case saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskRegisterStatus: null,
        riskRegisterErrorMessage: payload,
      };
    case saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_STATUS:
      return {
        ...state,
        RiskRegisterStatus: 0,
        riskRegisterErrorMessage: null,
      };

    // Delete RiskRegister Type

    case deleteRiskRegisterActions.DELETE_RISK_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
      };
    case deleteRiskRegisterActions.DELETE_RISK_REGISTER_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterStatus: payload.status,
        riskRegisterErrorMessage: null,
        riskRegisterSuccessMessage: "Risk Register Deleted successfully!",
      };

    case deleteRiskRegisterActions.DELETE_RISK_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        RiskRegisterStatus: null,
        riskRegisterErrorMessage: payload,
      };
    case deleteRiskRegisterActions.DELETE_RISK_REGISTER_STATUS:
      return {
        ...state,
        RiskRegisterStatus: 0,
        riskRegisterErrorMessage: null,
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
        RiskRegisterData: null,
      };
    case GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        RiskRegisterData: payload.data,
        RiskRegisterDataStatus: payload.status,
        riskRegisterErrorMessage: null,
      };
    case GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskRegisterErrorMessage: payload,
        RiskRegisterData: null,
      };
    case GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_STATUS:
      return {
        ...state,
        RiskRegisterData: null,
      };
    //    UPDATE STATUS RISK REGISTER
    case updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
        RiskRegisterData: null,
      };
    case updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ItemUpdateStatus: payload.status,
        riskRegisterErrorMessage: null,
      };
    case updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskRegisterErrorMessage: payload,
      };

    ///Active riskRegisters
    case GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS:
      return {
        ...state,
        isLoading: true,
        riskRegisterErrorMessage: null,
        modalActive: false,
        ActiveRiskRegisterList: null,
      };
    case GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveRiskRegisterList: payload.data,
        riskRegisterErrorMessage: null,
      };
    case GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        riskRegisterErrorMessage: payload,
        ActiveRiskRegisterList: null,
      };
    case GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_STATUS:
      return {
        ...state,
        ActiveRiskRegisterList: null,
      };
    // clear riskRegisterType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetRiskRegister: null,
        RiskRegisterStatus: null,
        riskRegisterSuccessMessage: null,
        GetRiskRegisterStatus: null,
        RiskRegisterData: null,
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status,
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload,
      };
    /* */
    case saveDocActions.SAVE_DOC:
      return {
        ...state,
        isLoading: true,
        docErrorMessage: null,
        modalActive: false,
      };
    case saveDocActions.SAVE_DOC_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DocStatus: payload.status,
        docErrorMessage: null,
        docSuccessMessage: "Doc added successfully!",
      };

    case saveDocActions.SAVE_DOC_FAILURE:
      return {
        ...state,
        isLoading: false,
        DocStatus: null,
        docErrorMessage: payload,
      };
    case saveDocActions.SAVE_DOC_STATUS:
      return {
        ...state,
        DocStatus: 0,
        docErrorMessage: null,
      };
    default:
      return state;
  }
}

