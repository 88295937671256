import axios from "axios";
import { SuperAdmin_Service_URL, GroupManagementService_URL } from "../Url";
import HashMap from "hashmap";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Group

export const saveGroupActions = {
  SAVE_GROUP: "SAVE_GROUP",
  SAVE_GROUP_STATUS: "SAVE_GROUP_STATUS",
  SAVE_GROUP_SUCCESS: "SAVE_GROUP_SUCCESS",
  SAVE_GROUP_FAILURE: "SAVE_GROUP_FAILURE",
};

export function saveGroupRequest() {
  return {
    type: saveGroupActions.SAVE_GROUP,
    isPosting: true,
  };
}

export function saveGroupSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveGroupActions.SAVE_GROUP_SUCCESS,
      payload: props,
    });
  };
}

export function saveGroupFailure(error) {
  return {
    type: saveGroupActions.SAVE_GROUP_FAILURE,
    payload: error,
  };
}

export function saveGroupError(error) {
  return {
    type: saveGroupActions.SAVE_GROUP_FAILURE,
    payload: error,
  };
}

export function saveGroup(props, access_token) {
  // var value = {
  //   GroupTypeId: props.GroupTypeId || null,
  //   GroupTypeName: props.GroupTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${GroupManagementService_URL}/saveGroupMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveGroupRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveGroupSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveGroupError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));

          dispatch(saveGroupError(err));
          // switch (err.response.status) {

          // case 400:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveGroupError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveGroupError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveGroupError(err));
          //   break;
          // case 500:
          //   dispatch(saveGroupError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveGroupError("Unknown Error"));
          //   break;
        } else {
          // //dispatch(logout());
        }
      });
}

// Delete Group

export const deleteGroupActions = {
  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_STATUS: "DELETE_GROUP_STATUS",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_FAILURE: "DELETE_GROUP_FAILURE",
};

export function deleteGroupRequest() {
  return {
    type: deleteGroupActions.DELETE_GROUP,
    isPosting: true,
  };
}

export function deleteGroupSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteGroupActions.DELETE_GROUP_SUCCESS,
      payload: props,
    });
  };
}

export function deleteGroupFailure(error) {
  return {
    type: deleteGroupActions.DELETE_GROUP_FAILURE,
    payload: error,
  };
}

export function deleteGroupError(error) {
  return {
    type: deleteGroupActions.DELETE_GROUP_FAILURE,
    payload: error,
  };
}

export function deleteGroup(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${GroupManagementService_URL}/deleteGroup?groupTypeId=` +
        params.groupTypeID,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteGroupRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteGroupSuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteGroupError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteGroupError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteGroupError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteGroupError(err));
              break;
            case 500:
              dispatch(deleteGroupError("Server Error"));
              break;
            default:
              dispatch(deleteGroupError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetGroupDetailsActions = {
  FETCH_GROUP_DETAILS: "FETCH_GROUP_DETAILS",
  FETCH_GROUP_DETAILS_STATUS: "FETCH_GROUP_DETAILS_STATUS",
  FETCH_GROUP_DETAILS_FULFILLED: "FETCH_GROUP_DETAILS_FULFILLED",
  FETCH_GROUP_DETAILS_REJECTED: "FETCH_GROUP_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getGroupDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${GroupManagementService_URL}/getByIdGroupMaster
?groupId=` + params.groupTypeID,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getGroupDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getGroupDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getGroupDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGroupDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGroupDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getGroupDetailsError(err));
              break;
            case 500:
              dispatch(getGroupDetailsError("Server Error"));
              break;
            default:
              dispatch(getGroupDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
        }
      });
}

export function getGroupDetailsRequest() {
  return {
    type: GetGroupDetailsActions.FETCH_GROUP_DETAILS,
    isPosting: true,
  };
}
export function getGroupDetailsError(error) {
  return {
    type: GetGroupDetailsActions.FETCH_GROUP_DETAILS_REJECTED,
    payload: error,
  };
}

export function getGroupDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetGroupDetailsActions.FETCH_GROUP_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getGroupDetailsStatus() {
  return (dispatch) => dispatch(getGroupDetailsStatusRequest());
}
export function getGroupDetailsStatusRequest() {
  return {
    type: GetGroupDetailsActions.FETCH_GROUP_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getGroupDetailsErrorRequest());
}
export function getGroupDetailsErrorRequest() {
  return {
    type: GetGroupDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("groupTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${GroupManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
        }
      });
}
///ACTIVE GroupS :LIST

export const GetActiveGroupActions = {
  FETCH_ACTIVE_GROUP: "FETCH_ACTIVE_GROUP",
  FETCH_ACTIVE_GROUP_STATUS: "FETCH_ACTIVE_GROUP_STATUS",
  FETCH_ACTIVE_GROUP_FULFILLED: "FETCH_ACTIVE_GROUP_FULFILLED",
  FETCH_ACTIVE_GROUP_REJECTED: "FETCH_ACTIVE_GROUP_REJECTED",
};

export function getActiveGroupList(params) {
  return (dispatch) =>
    axios({
      method: "POST",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${GroupManagementService_URL}/getAllGroupPagination?start=0&sortOrder=desc&booleanfield=true&sortField=groupTypeId&length=0`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        
        dispatch(getActiveGroupListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveGroupListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveGroupListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveGroupListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveGroupListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveGroupListError(err));
              break;
            case 500:
              dispatch(getActiveGroupListError("Server Error"));
              break;
            default:
              dispatch(getActiveGroupListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
        }
      });
}

export function getActiveGroupListRequest() {
  return {
    type: GetActiveGroupActions.FETCH_ACTIVE_GROUP,
    isPosting: true,
  };
}
export function getActiveGroupListError(error) {
  return {
    type: GetActiveGroupActions.FETCH_ACTIVE_GROUP_REJECTED,
    payload: error,
  };
}

export function getActiveGroupListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveGroupActions.FETCH_ACTIVE_GROUP_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveGroupListStatus() {
  return (dispatch) => dispatch(getActiveGroupListStatusRequest());
}
export function getActiveGroupListStatusRequest() {
  return {
    type: GetActiveGroupActions.FETCH_ACTIVE_GROUP_STATUS,
  };
}
