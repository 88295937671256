import axios from "axios";
import {
  AssetManagementService_URL,
  SystemService_URL,
  RopaManagementService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataContactActions = {
  SAVE_DATA_CONTACT: "SAVE_DATA_CONTACT",
  SAVE_DATA_CONTACT_STATUS: "SAVE_DATA_CONTACT_STATUS",
  SAVE_DATA_CONTACT_SUCCESS: "SAVE_DATA_CONTACT_SUCCESS",
  SAVE_DATA_CONTACT_FAILURE: "SAVE_DATA_CONTACT_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveDataContactActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveDataContactRequest() {
  return {
    type: SaveDataContactActions.SAVE_DATA_CONTACT,
    isPosting: true,
  };
}

export function SaveDataContactSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataContactActions.SAVE_DATA_CONTACT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataContactFailure(error) {
  return {
    type: SaveDataContactActions.SAVE_DATA_CONTACT_FAILURE,
    payload: error,
  };
}

export function SaveDataContactError(error) {
  return {
    type: SaveDataContactActions.SAVE_DATA_CONTACT_FAILURE,
    payload: error,
  };
}

export function saveDataContact(props) {
  
  var value = {
    id: props.dataContactId || null,
    name: props.dataContactName,
    email: props.emailAddress,
    mobileNumber: props.mobileNo,
    dataCategoryId:props.dataCategoryId,
  };

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${RopaManagementService_URL}/Apc_Persist/Amc_19/saveRopaContactDetail`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        
        dispatch(SaveDataContactRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataContactSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          // dispatch(SaveDataContactError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          // case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          //     dispatch(SaveDataContactError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataContactError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataContactError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveDataContactError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveDataContactError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetDataContactDetailsActions = {
  FETCH_DATA_CONTACT_DETAILS: "FETCH_DATA_CONTACT_DETAILS",
  FETCH_DATA_CONTACT_DETAILS_STATUS: "FETCH_DATA_CONTACT_DETAILS_STATUS",
  FETCH_DATA_CONTACT_DETAILS_FULFILLED: "FETCH_DATA_CONTACT_DETAILS_FULFILLED",
  FETCH_DATA_CONTACT_DETAILS_REJECTED: "FETCH_DATA_CONTACT_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataContactDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RopaManagementService_URL}/Apc_View/Amc_19/getRopaContactDetailById?id=` +
        params.dataContactId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataContactDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataContactDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          // dispatch(getDataContactDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(getDataContactDetailsError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getDataContactDetailsError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(getDataContactDetailsError(err));
          //     break;
          //   case 500:
          //     dispatch(getDataContactDetailsError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getDataContactDetailsError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataContactDetailsRequest() {
  return {
    type: GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS,
    isPosting: true,
  };
}
export function getDataContactDetailsError(error) {
  return {
    type: GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataContactDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataContactDetailsStatus() {
  return (dispatch) => dispatch(getDataContactDetailsStatusRequest());
}
export function getDataContactDetailsStatusRequest() {
  return {
    type: GetDataContactDetailsActions.FETCH_DATA_CONTACT_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataContactDetailsErrorRequest());
}
export function getDataContactDetailsErrorRequest() {
  return {
    type: GetDataContactDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataContactActions = {
  FETCH_ACTIVE_DATA_CONTACT: "FETCH_ACTIVE_DATA_CONTACT",
  FETCH_ACTIVE_DATA_CONTACT_STATUS: "FETCH_ACTIVE_DATA_CONTACT_STATUS",
  FETCH_ACTIVE_DATA_CONTACT_FULFILLED: "FETCH_ACTIVE_DATA_CONTACT_FULFILLED",
  FETCH_ACTIVE_DATA_CONTACT_REJECTED: "FETCH_ACTIVE_DATA_CONTACT_REJECTED",
};
export function getActiveDataContactList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${RopaManagementService_URL}/Apc_View/Amc_19/findAllRopaContactDetail?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataContactListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataContactListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataContactListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataContactListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataContactListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataContactListError(err));
              break;
            case 500:
              dispatch(getActiveDataContactListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataContactListError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataContactListRequest() {
  return {
    type: GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT,
    isPosting: true,
  };
}
export function getActiveDataContactListError(error) {
  return {
    type: GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_REJECTED,
    payload: error,
  };
}

export function getActiveDataContactListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataContactListStatus() {
  return (dispatch) => dispatch(getActiveDataContactListStatusRequest());
}
export function getActiveDataContactListStatusRequest() {
  return {
    type: GetActiveDataContactActions.FETCH_ACTIVE_DATA_CONTACT_STATUS,
  };
}
