import axios from "axios";
import { SuperAdmin_Service_URL,ConsentManagementService_URL, AssetManagementService_URL,RiskManagementService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save Risk Category

export const saveRiskCategoryActions = {
  SAVE_RISK_CATEGORY: "SAVE_RISK_CATEGORY",
  SAVE_RISK_CATEGORY_STATUS: "SAVE_RISK_CATEGORY_STATUS",
  SAVE_RISK_CATEGORY_SUCCESS: "SAVE_RISK_CATEGORY_SUCCESS",
  SAVE_RISK_CATEGORY_FAILURE: "SAVE_RISK_CATEGORY_FAILURE",
};

export function saveRiskCategoryRequest() {
  return {
    type: saveRiskCategoryActions.SAVE_RISK_CATEGORY,
    isPosting: true,
  };
}

export function saveRiskCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRiskCategoryActions.SAVE_RISK_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function saveRiskCategoryFailure(error) {
  return {
    type: saveRiskCategoryActions.SAVE_RISK_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveRiskCategoryError(error) {
  return {
    type: saveRiskCategoryActions.SAVE_RISK_CATEGORY_FAILURE,
    payload: error,
  };
}

export function saveRiskCategory(props) {
  // var value = {
  //   id: props.id || 0,
  //   RiskCategoryName: props.RiskCategoryName,
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/saveRiskCategory`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(saveRiskCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRiskCategorySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRiskCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // dispatch(saveConsentPurposeError(err));
          // switch (err.response.status) {
          //   case 400:

          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(saveConsentPurposeError(err));
          //   break;
          // case 406:
          //   // map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   // map.forEach(ele => (err = ele));

          //   // dispatch(saveConsentPurposeError(err));
          //   return err.response;
          //   break;
          // case 500:
          //   dispatch(saveConsentPurposeError("Server Error"));
          //   break;
          // default:
          //   dispatch(saveConsentPurposeError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}
//Delete by id


export const deleteRiskCategoryActions = {
  DELETE_RISK_CATEGORY: "DELETE_RISK_CATEGORY",
  DELETE_RISK_CATEGORY_STATUS: "DELETE_RISK_CATEGORY_STATUS",
  DELETE_RISK_CATEGORY_SUCCESS: "DELETE_RISK_CATEGORY_SUCCESS",
  DELETE_RISK_CATEGORY_FAILURE: "DELETE_RISK_CATEGORY_FAILURE",
};

export function deleteRiskCategoryRequest() {
  return {
    type: deleteRiskCategoryActions.DELETE_RISK_CATEGORY,
    isPosting: true,
  };
}

export function deleteRiskCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteRiskCategoryActions.DELETE_RISK_CATEGORY_SUCCESS,
      payload: props,
    });
  };
}

export function deleteRiskCategoryFailure(error) {
  return {
    type: deleteRiskCategoryActions.DELETE_RISK_CATEGORY_FAILURE,
    payload: error,
  };
}

export function deleteRiskCategoryError(error) {
  return {
    type: deleteRiskCategoryActions.DELETE_RISK_CATEGORY_FAILURE,
    payload: error,
  };
}

export function deleteRiskCategory(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${ConsentManagementService_URL}/deleteRiskCategory?id=` +
        params.id,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteRiskCategoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteRiskCategorySuccess(response));
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteRiskCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRiskCategoryError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRiskCategoryError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(deleteRiskCategoryError(err));
              break;
            case 500:
              dispatch(deleteRiskCategoryError("Server Error"));
              break;
            default:
              dispatch(deleteRiskCategoryError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// get  details by id



export const getRiskCategoryDetailsActions = {
  FETCH_RISK_CATEGORY_DETAILS: "FETCH_RISK_CATEGORY_DETAILS",
  FETCH_RISK_CATEGORY_DETAILS_STATUS: "FETCH_RISK_CATEGORY_DETAILS_STATUS",
  FETCH_RISK_CATEGORY_DETAILS_FULFILLED: "FETCH_RISK_CATEGORY_DETAILS_FULFILLED",
  FETCH_RISK_CATEGORY_DETAILS_REJECTED: "FETCH_RISK_CATEGORY_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskCategoryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/findRiskCategoryById?RiskCategoryId=` +
        params.riskCategoryId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRiskCategoryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskCategoryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRiskCategoryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryDetailsError(err));
              break;
            case 500:
              dispatch(getRiskCategoryDetailsError("Server Error"));
              break;
            default:
              dispatch(getRiskCategoryDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskCategoryDetailsRequest() {
  return {
    type: getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS,
    isPosting: true,
  };
}
export function getRiskCategoryDetailsError(error) {
  return {
    type: getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRiskCategoryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskCategoryDetailsStatus() {
  return (dispatch) => dispatch(getRiskCategoryDetailsStatusRequest());
}
export function getRiskCategoryDetailsStatusRequest() {
  return {
    type: getRiskCategoryDetailsActions.FETCH_RISK_CATEGORY_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRiskCategoryDetailsErrorRequest());
}
export function getRiskCategoryDetailsErrorRequest() {
  return {
    type: getRiskCategoryDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("RiskCategorys", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE ASSETS :LIST

export const GetActiveRiskCategoryActions = {
  FETCH_ACTIVE_RISK_CATEGORY: "FETCH_ACTIVE_RISK_CATEGORY",
  FETCH_ACTIVE_RISK_CATEGORY_STATUS: "FETCH_ACTIVE_RISK_CATEGORY_STATUS",
  FETCH_ACTIVE_RISK_CATEGORY_FULFILLED: "FETCH_ACTIVE_RISK_CATEGORY_FULFILLED",
  FETCH_ACTIVE_RISK_CATEGORY_REJECTED: "FETCH_ACTIVE_RISK_CATEGORY_REJECTED",
};

export function getActiveRiskCategoryList(params,access_token) {
  return (dispatch) =>
    axios({
      method: "POST",
      data:params,
      url: `${ConsentManagementService_URL}/listing/RiskCategory`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveRiskCategoryListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskCategoryListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveRiskCategoryListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskCategoryListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskCategoryListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskCategoryListError(err));
              break;
            case 500:
              dispatch(getActiveRiskCategoryListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskCategoryListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskCategoryListRequest() {
  return {
    type: GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY,
    isPosting: true,
  };
}
export function getActiveRiskCategoryListError(error) {
  return {
    type: GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getActiveRiskCategoryListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskCategoryListStatus() {
  return (dispatch) => dispatch(getActiveRiskCategoryListStatusRequest());
}
export function getActiveRiskCategoryListStatusRequest() {
  return {
    type: GetActiveRiskCategoryActions.FETCH_ACTIVE_RISK_CATEGORY_STATUS,
  };
}


// Get all Risk category Pagination

export const GetRiskCategoryActions = {
  FETCH_RISK_CATEGORY: "FETCH_RISK_CATEGORY",
  FETCH_RISK_CATEGORY_STATUS: "FETCH_RISK_CATEGORY_STATUS",
  FETCH_RISK_CATEGORY_FULFILLED:
    "FETCH_RISK_CATEGORY_FULFILLED",
  FETCH_RISK_CATEGORY_REJECTED:
    "FETCH_RISK_CATEGORY_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskCategory(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/getAllRiskCategoryPagination?length=0&sortOrder=desc&sortField=RiskCategoryId&status=true`, 
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAssetDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskCategorySuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskCategoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskCategoryError(err));
              break;
            case 500:
              dispatch(getRiskCategoryError("Server Error"));
              break;
            default:
              dispatch(getRiskCategoryError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskCategoryRequest() {
  return {
    type: GetRiskCategoryActions.FETCH_RISK_CATEGORY,
    isPosting: true,
  };
}
export function getRiskCategoryError(error) {
  return {
    type: GetRiskCategoryActions.FETCH_RISK_CATEGORY_REJECTED,
    payload: error,
  };
}

export function getRiskCategorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetRiskCategoryActions.FETCH_RISK_CATEGORY_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskCategoryStatus() {
  return (dispatch) => dispatch(getRiskCategoryStatusRequest());
}
export function getRiskCategoryStatusRequest() {
  return {
    type: GetRiskCategoryActions.FETCH_RISK_CATEGORY_STATUS,
  };
}
